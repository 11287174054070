import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BarChart } from 'src/modules/app/types';

@Component({
  selector: 'co2-turbogen-power-reserve',
  templateUrl: './turbogen-power-reserve.component.html',
  styleUrls: ['./turbogen-power-reserve.component.scss']
})
export class TurbogenPowerReserveComponent {
  @Input() powerReserveGraph!: BarChart;
  @Input() isLoadingPowerReserve!: boolean | null;
  @Input() hasPowerReserveLoadingError!: boolean | null;
  @Output() reloadPowerReserveData = new EventEmitter();
  @Input() fpsoConfigs!: string[];
  @Input() activeFpsoConfig!: string | null;
  @Output() fpsoConfigChange = new EventEmitter();

  onToggleSwitch(event: any): void {
    this.fpsoConfigChange.emit(event.target.checked ? this.fpsoConfigs[1] : this.fpsoConfigs[0]);
  }
}
