import { Action, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { GetActiveSeaWaterLiftType } from './actions';

@State<string>({
  name: 'activeSeaWaterLiftType',
  defaults: 'overview',
})
@Injectable()
export class ActiveSeaWaterLiftTypeState {
  @Action(GetActiveSeaWaterLiftType)
  getActiveSeaWaterLiftType(context: StateContext<string>, { payload }: GetActiveSeaWaterLiftType): void {
    context.setState(payload);
  }
}
