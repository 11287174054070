<div class="disscussion">
  <div class="card-title">
    Discussion
  </div>
</div>
<div *ngIf="displayEmptyDiscussion()">
  <div class="disscussion__content">
    <div class="disscussion__header">
      <div *ngIf="alertStatus !== AlertStatus.CLOSED" class="disscussion__rightBloc">
          <span class="material-icons disscussion__comment">
            comment
          </span>
        <div class="disscussion__newMessage" (keydown.enter)="openModal(4)" (click)="openModal(4) ">New Message</div>
      </div>
    </div>
    <div class="disscussion__message__static">
      <div *ngIf="alertStatus !== AlertStatus.CLOSED; else elseBlock">Initiate a
        discussion about this new observation
      </div>
      <ng-template #elseBlock>
        It is not possible to initiate a new discussion because alert status is closed.
      </ng-template>
    </div>
    <div class="disscussion__message__footer">
      <mat-icon class="disscussion__message__footer__icon">info</mat-icon> Sorry, there is no message history for this observation.
    </div>
  </div>
</div>

<div *ngIf="lastDiscussion">
  <div class="disscussion__content">
    <div class="disscussion__header">
      <div class="disscussion__rightBloc">
            <span class="material-icons disscussion__comment">
              comment
            </span>
        <div class="disscussion__newMessage" (keydown.enter)="openModal(1)" (click)="openModal(1)">New Message</div>
      </div>
    </div>
    <div class="disscussion__message" *ngFor="let discussionMessage of lastDiscussion.messages">
      <div class="disscussion__message__user">
        <span class="user__name">{{ discussionMessage.name }}</span>
        <div *ngIf="currentUser === discussionMessage.name"
             class="user__actions">
          <span class="material-icons" (keydown)="toggleActiveEdit($event, discussionMessage)" (click)="toggleActiveEdit($event,discussionMessage)">more_horiz</span>
          <ul *ngIf="discussionMessage.activeEdit" class="user__actions__list">
            <li class="user__actions__element" (keydown.enter)="openModal(2, discussionMessage)" (click)="openModal(2, discussionMessage)">
              <span class="material-icons">edit</span>
              Edit
            </li>
            <li class="user__actions__element" (keydown.enter)="openModal(3, discussionMessage)" (click)="openModal(3, discussionMessage)">
              <span class="material-icons">delete</span>
              Delete
            </li>
          </ul>
        </div>
      </div>
      <div class="disscussion__message__date">{{ discussionMessage.updatedAt | date: "dd/MM/yyyy HH'h'mm" }}</div>
      <div class="disscussion__message__content">{{ discussionMessage.content }}</div>
      <div *ngIf="discussionMessage.attachments?.length">
      <button  type="button" class="btn btn-secondary file-btn" (click)="downloadFile(lastDiscussion.id, discussionMessage.id, discussionMessage.attachments[0]?.id)"><span class="material-icons md-24">
        attach_file
        </span> {{discussionMessage.attachments[0]?.fileName}}</button>
      </div>
    </div>
  </div>
</div>

<co2-modal [isOpen]="modalOpen" [newDiscussion]="newDiscussion" [messageData]="messageData"
           [selectedDiscussion]="lastDiscussion"
           [selectedAlert]="selectedAlert" [equipmentName]="equipmentName" [content]="modalContent"
           [principalContent]="modalPrincipalContent"
           [iconTitle]="iconTitle" [title]="modalTitle" [button]="modalButton"
           (closeModalEvent)="closeModal()" (updateStatus)="updateStatus($event)"
           (reloadDiscussions)="reloadDiscussions()">
</co2-modal>
