import { DataType } from './data-type.enum';

export interface TableHeader {
  id: string;
  subId?: string;
  subIdTitle?: string;
  subRefId?: string;
  label: string;
  dataType: DataType;
  tooltip?: string;
  subHeader?: string;
  subHeaderId?: string;
  suffixText?: string;
  dynamicSuffix?: boolean;
  prefixIcon?: string | boolean;
  statusTooltip?: string;
  metadata?: any;
  defaultValue?: string;
}
