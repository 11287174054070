import { Action, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { GetPumpOverviewKpis } from './actions';
import { TrainOverview } from 'src/modules/app/types';
import { KpiOverview } from "../../app/types/kpi-overview.interface";

@State<TrainOverview[]>({
  name: 'pumpOverviewKpis',
  defaults: []
})

@Injectable()
export class PumpOverviewKpisState {
  @Action(GetPumpOverviewKpis)
  getSruFeedOverviewKpis(context: StateContext<KpiOverview | undefined>, { payload }: GetPumpOverviewKpis): void {
    context.setState(payload);
  }
}
