import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { SankeyInput, toSankeyInput } from "../../containers/sankey/sankey-input";
import { SankeyResponse } from "../../types/sankey.interface";

@Injectable({
  providedIn: 'root'
})
export class SankeyService {

  constructor(private http: HttpClient) {
  }

  public getSankey(affiliate: string, fpsoName: string): Observable<SankeyInput> {
    return this.http.get<SankeyResponse>(`${ environment.apiUrl }/${ affiliate }/${ fpsoName }/sankey`)
      .pipe(map(toSankeyInput));
  }
}
