import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { UiGraphModule } from 'src/modules/app/components/ui-graph/ui-graph.module';
import { SidePanelComponent } from './side-panel.component';
import { LoadingSpinnerModule } from 'src/modules/app/components/loading-spinner/loading-spinner.module';
import { SharedModule } from 'src/modules/app/shared.module';
import { DiscussionsModule } from '../discussions/discussions.module';
import { ReplaceDashWithSpacePipe } from 'src/modules/app/pipes/replace-dash-with-space/replace-dash-with-space.pipe';

@NgModule({
  declarations: [SidePanelComponent],
  imports: [
    CommonModule,
    UiGraphModule,
    DiscussionsModule,
    LoadingSpinnerModule,
    SharedModule,
    RouterModule
  ],
  exports: [SidePanelComponent]
})
export class SidePanelModule { }
