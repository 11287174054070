import { Action, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { GetActiveWaterInjectionType } from './actions';

@State<string>({
  name: 'activeWaterInjectionType',
  defaults:'overview',
})
@Injectable()
export class ActiveWaterInjectionTypeState {
  @Action(GetActiveWaterInjectionType)
  getActiveWaterInjectionType(context: StateContext<string>, { payload }: GetActiveWaterInjectionType): void {
    context.setState(payload);
  }
}
