import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { FavoriteAssetContainer } from './favorite-asset.container';
import { FormsModule } from '@angular/forms';



const routes: Routes = [
  { path: '', component: FavoriteAssetContainer }
];

@NgModule({
  declarations: [FavoriteAssetContainer],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule.forChild(routes)
  ]
})
export class FavoriteAssetModule { }
