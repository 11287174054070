import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { GeneralDashboardContainer } from './general-dashboard.container';
import { OverviewGhgEmissionsModule } from './components/overview-ghg-emissions/overview-ghg-emissions.module';
import { AlertTableModule } from './components/alert-table/alert-table.module';
import { SidePanelModule } from './components/side-panel/side-panel.module';




const routes: Routes = [
  { path: '', component: GeneralDashboardContainer }
];

@NgModule({
  declarations: [GeneralDashboardContainer],
  imports: [
    CommonModule,
    OverviewGhgEmissionsModule,
    AlertTableModule,
    SidePanelModule,
    FormsModule,
    RouterModule.forChild(routes)
  ],
  exports: [GeneralDashboardContainer]
})
export class GeneralDashboardContainerModule { }
