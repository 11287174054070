
<div *ngIf="!isModificationBaselineIsOn || isOverview" class="chart">
    <div echarts [id]="chartDomId" [options]="options" >
    </div>
    <div *ngIf="noAvailableData" class="popup">
        <div class="popup__title">Missing Information</div>
        <div class="popup__body">Sorry, no data is displayed because no data has been received in the last 72 hours.
        </div>
    </div>
</div>

<div *ngIf="isModificationBaselineIsOn && options && !isOverview" class="chart">
        <div echarts [id]="chartDomId" style="height: 400px;"></div>
        <div *ngIf="noAvailableData" class="popup">
          <div class="popup__title">Missing Information</div>
          <div class="popup__body">Sorry, no data is displayed because no data has been received in the last 72 hours.</div>
        </div>
  </div>
