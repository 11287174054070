import { SankeyData, SankeyLink, SankeyResponse } from "../../types/sankey.interface";
import { formatDateWithFormat, FRENCH_DATE_TIME_FORMAT } from "../../utils/date-time-picker/date-time-picker";

export interface SankeyInput {
  date: string,
  energyEfficiencyPercent: number;
  currentEmissions: number;
  lowestAchievableEmissions: number;
  sankeyElectric: SankeyLink[];
  sankeyFuelGas: SankeyLink[];
  data: SankeyData[];
}

export function toSankeyInput(dto: SankeyResponse): SankeyInput {
  return {
    sankeyElectric: dto.sankeyElectric,
    sankeyFuelGas: dto.sankeyFuelGas,
    data: dto.data,
    energyEfficiencyPercent: dto.energyEfficiencyPercent,
    currentEmissions: dto.currentEmissions,
    lowestAchievableEmissions: dto.lowestAchievableEmissions,
    date: formatDateWithFormat(dto.date, FRENCH_DATE_TIME_FORMAT)
  } as SankeyInput;
}
