import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MsalService, MsalBroadcastService } from '@azure/msal-angular';
import { AccountInfo, AuthenticationResult, EventMessage, EventType, EventError, InteractionStatus } from '@azure/msal-browser';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { AppInsightsService } from '../app-insights/app-insights.service';
import { EncryptionService } from "../encryption/encryption.service";
import {FAVORITE_ASSET_STORAGE_KEY, ROLES_STORAGE_KEY, SITE_STORAGE_KEY} from "../../containers";

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  authenticated = new BehaviorSubject(false);
  authenticationPending = new BehaviorSubject(true);
  currentUser = new BehaviorSubject<AccountInfo | null>(null);
  error: EventError = null;
  claimRoles: string[] = [];

  constructor(
    private msalBroadcastService: MsalBroadcastService,
    private msalService: MsalService,
    private router: Router,
    private appInsights: AppInsightsService,
    private encryptionService: EncryptionService
  ) {
    // Init Service
    this.activeFirstAccountFromlocalStorage();
    this.loadActiveAccount();

    // Authentication Progress
    this.msalBroadcastService.inProgress$.subscribe({
      next: (status: InteractionStatus) => {
        if (environment.debug.msal) {
        }
        if (status === InteractionStatus.None) {
          this.authenticationPending.next(false);
        } else {
          this.authenticationPending.next(true);
        }
      },
      error: (error) => {
        console.log('error: ', error);
      },
      complete: () => {
        console.log('complete');
      }
    });

    // Launch action on event from MSAL service
    this.msalBroadcastService.msalSubject$.subscribe({
      next: (event: EventMessage) => {
        if (environment.debug.msal) {
        }
        switch (event.eventType) {
          case EventType.LOGIN_SUCCESS: case EventType.ACQUIRE_TOKEN_SUCCESS: {
            let payload = event.payload as AuthenticationResult
            this.onLogin(payload.account);
            break;
          }
          case EventType.LOGOUT_SUCCESS: {
            this.onLogout();
            break;
          }
          case EventType.LOGIN_FAILURE: case EventType.ACQUIRE_TOKEN_FAILURE: {
            this.onLoginFailure(event.error);
            break;
          }
          default: {
            break;
          }
        }
      }
    });
  }

  onLogin(account: AccountInfo | null) {
    if (account) {
      // Active user account
      this.msalService.instance.setActiveAccount(account);
      // Load account
      this.loadActiveAccount();
    }
  }

  onLogout() {
    this.authenticated.next(false);
    this.currentUser.next(null);
    this.router.navigate(['/']);
  }

  onLoginFailure(error: any) {
    this.authenticated.next(false);
    this.currentUser.next(null);
    this.error = error;
    this.login();
  }

  activeFirstAccountFromlocalStorage() {
    let account = this.msalService.instance.getAllAccounts()[0]
    if (account) {
      this.msalService.instance.setActiveAccount(account);
    }
  }

  loadActiveAccount() {
    const activeAccount = this.msalService.instance.getActiveAccount();
    if (activeAccount) {
      if (activeAccount.idTokenClaims && activeAccount.idTokenClaims[ROLES_STORAGE_KEY]) {
        this.claimRoles = activeAccount.idTokenClaims[ROLES_STORAGE_KEY];
        const rolesInLocal = localStorage.getItem(ROLES_STORAGE_KEY);
        const rolesEncrypted = this.encryptionService.encryptData(JSON.stringify(this.claimRoles));
        if (this.claimRoles?.length > 0 && rolesInLocal !== rolesEncrypted) {
          localStorage.setItem(ROLES_STORAGE_KEY, rolesEncrypted);
        }
        this.appInsights.setUserRoles(this.claimRoles);
      }
      this.appInsights.setAuthenticatedUserContext(activeAccount.username, activeAccount.localAccountId);
      this.currentUser.next(activeAccount)
      this.authenticated.next(true);
    }
  }

  login() {
    // Build list of scopes
    const scopes: string[] = environment.aad.backends.reduce<string[]>(
      (prevScopes, item) => [...prevScopes, ...item.scopes],
      []
    );
    this.msalService.loginRedirect({
      redirectUri: environment.aad.redirectUri,
      scopes: scopes,
      prompt: 'select_account'
    });
  }

  logout() {
    // logout without SLO
    this.msalService.instance.logoutRedirect({
      onRedirectNavigate: (url) => { return false; }
    });
  }

  redirectAuthenticatedUserToHome() {
    // Redirect user if is already authenticated
    this.authenticationPending
      .pipe(filter((state: boolean) => !state))
      .subscribe((state) => {
        if (this.authenticated.getValue()) {
          const route = this.getLinkForPage();
          this.router.navigate([route]);
        }
      });
  }

  getLinkForPage(): string {
    const asset = JSON.parse(localStorage.getItem(SITE_STORAGE_KEY) ?? '[]');
    if (asset.length === 0) {
      return '/favorite-asset';
    }

    const favoriteAsset = JSON.parse(localStorage.getItem(FAVORITE_ASSET_STORAGE_KEY) ?? '{}');
    if (!favoriteAsset.affiliate || !favoriteAsset.fpsos) {
      return '/favorite-asset';
    }

    return `/${favoriteAsset.affiliate}/${favoriteAsset.fpsos}/general-dashboard`;
  }

}
