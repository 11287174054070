import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardErrorComponent } from './card-error.component';

@NgModule({
  declarations: [CardErrorComponent],
  imports: [
    CommonModule
  ],
  exports: [CardErrorComponent]
})
export class CardErrorModule { }
