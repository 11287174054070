<div class="card alert-table" [class.no-right-border-radius]="selectedAlert">
  <div class="card-title">
    <div class="alerts" id="alert">
      {{ size === 0 ? 'No Alerts' : size + ' Alerts' }}
      <button type="button" class="btn" data-bs-toggle="modal" data-bs-target="#exampleModal">
        <span class="material-icons-outlined" aria-hidden="true">info</span>
      </button>
    </div>
    <div class="alert-type d-flex">
      <div class="alert-filter d-flex">
        <input class="form-control" type="search" placeholder="Search" aria-label="Search" [(ngModel)]="alertFilter"
               (keydown)="onInputFilterChangeKeyDown($event)" (ngModelChange)="onInputFilterChange()">
        <button class="btn btn-outline-primary btn-circle" (click)="onFilterChange.emit(alertFilter)">
          <span class="material-icons-outlined">search</span>
        </button>
      </div>
      <co2-table-legend [legendList]="legendList" [selectedAlert]="selectedAlert"
                        (legendClick)="legendClick.emit($event)">
      </co2-table-legend>
    </div>
  </div>
  <!-- Modal -->
  <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="observationsInfoModalLabel"
       aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <div class="modal-title" id="observationsInfoModalLabel">
            <span class="material-icons-outlined info-button-right" aria-hidden="true">info</span>Learn more - Alerts
          </div>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <co2-table [headers]="headers" class="observations-metadata-table" [rows]="metadataList"
                     [displayedHeaders]="displayedModalHeaders" [disabled]="false">
          </co2-table>
        </div>
      </div>
    </div>
  </div>
  <div class="card-body">
    <co2-loading-spinner [isLoading]="isLoading">

      <co2-table [headers]="alertHeaders" [rows]="alertsTable" [clickableRows]="true"
                 (rowClick)="alertClick.emit($event)" [displayedHeaders]="displayedHeaders"
                 [selectedRow]="selectedAlert"
                 class="alert-table" [class.no-headers]="selectedAlert">
      </co2-table>
    </co2-loading-spinner>
  </div>
</div>
