import { Action, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { GetActivePumpEquipmentName } from './actions';

@State<string>({
  name: 'activeActiveSruFeedType',
  defaults: 'overview',
})
@Injectable()
export class ActivePumpEquipmentNameState {
  @Action(GetActivePumpEquipmentName)
  getActiveSruFeedType(context: StateContext<string>, { payload }: GetActivePumpEquipmentName): void {
    context.setState(payload);
  }
}
