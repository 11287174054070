import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CrossPlotComponent } from './cross-plot.component';
import { UiGraphModule } from '../ui-graph/ui-graph.module';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { CardErrorModule } from '../card-error/card-error.module';
import { DateSelectionModule } from '../date-selection/date-selection.module';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [CrossPlotComponent],
  imports: [
    CommonModule,
    UiGraphModule,
    NgxSkeletonLoaderModule,
    CardErrorModule,
    RouterModule,
    DateSelectionModule
  ],
  exports: [CrossPlotComponent]
})
export class CrossPlotModule { }
