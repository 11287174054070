import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { UiGraphModule } from 'src/modules/app/components/ui-graph/ui-graph.module';
import { DiscussionsComponent } from './discussions.component';
import { SharedModule } from 'src/modules/app/shared.module';
import { ModalComponent } from '../../modal/modal.component';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [DiscussionsComponent, ModalComponent],
  imports: [
    CommonModule,
    MatIconModule,
    SharedModule,
    UiGraphModule,
    FormsModule,
    RouterModule
  ],
  exports: [DiscussionsComponent, ModalComponent]
})
export class DiscussionsModule { }
