import { DataType, TableHeader } from '../../types';



export const GLOBAL_PUMP_KPI_HEADERS: TableHeader[] = [
  {
    id: 'consumedPower',
    label: 'Energy Consumption',
    dataType: DataType.TEXT,
    suffixText: 'kW',
  },
  {
    id: 'deviationTargetedEnergy',
    label: 'Deviation from targeted Energy consumption',
    dataType: DataType.TEXT,
    suffixText: 'kW'
  },
  {
    id: 'emissionsOverconsumption',
    label: 'Emissions related to overconsumption',
    dataType: DataType.TEXT,
    suffixText: 'ktCO2/yr',
  },
  {
    id: 'auto',
    label: 'Avoidable Pressure Loss after pump',
    dataType: DataType.TEXT,
    suffixText: 'kW',
  },
  {
    id: 'aut',
    label: 'Emissions related to pressure loss after pump',
    dataType: DataType.TEXT,
    suffixText: '%',
  }
];


export const DETAILS_PUMP_KPI_HEADERS: TableHeader[] = [
  {
    id: 'equipmentStatus',
    label: 'Status',
    dataType: DataType.TEXT,
  },
  {
    id: 'equipmentName',
    label: 'Pump',
    dataType: DataType.TEXT
  },
  {
    id: 'consumedPower',
    label: 'Energy Consumption',
    dataType: DataType.TEXT,
    dynamicSuffix: true
  },
  {
    id: 'deviationTargetedEnergy',
    label: 'Deviation from targeted Energy consumption',
    dataType: DataType.TEXT,
    dynamicSuffix: true
  },
  {
    id: 'emissionsOverconsumption',
    label: 'Emissions related to overconsumption',
    dataType: DataType.TEXT,
    suffixText: 'ktCO2/yr',
  },
  {
    id: 'auy',
    label: 'Avoidable Pressure Loss after pump',
    dataType: DataType.TEXT,
    suffixText: '%',
  },
  {
    id: 'ai',
    label: 'Emissions related to pressure loss after pump',
    dataType: DataType.TEXT,
    suffixText: 'kW',
  }
];

