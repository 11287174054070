import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from '@environments/environment';
import {map, Observable} from 'rxjs';
import {EnergyType, KpiOverview} from "../../types/kpi-overview.interface";

@Injectable({
  providedIn: 'root'
})
export class KpiService {

  constructor(private http: HttpClient) {
  }

  getOverviewKpis(affiliate:string, fpsoName: string, equipmentType: string, equipmentNames: string[]): Observable<KpiOverview> {
    let params = new HttpParams().set('system_type', equipmentType)
      .append('equipment_names', equipmentNames.join(','));
    return this.http.get<KpiOverview>(
      `${environment.apiUrl}/${affiliate}/${fpsoName}/overview-kpis`,
      { params: params }
    ).pipe(
      map((kpiOverview: KpiOverview) => {
        kpiOverview.energyType =
          kpiOverview.kpiByEquipments.every(equipment => equipment.energyType === EnergyType.FUEL_GAS)
            ? EnergyType.FUEL_GAS : EnergyType.ELECTRIC;
        return kpiOverview;
      })
    );
  }

}
