import { EChartsOption, TooltipComponentOption, XAXisComponentOption, YAXisComponentOption } from 'echarts';
import { LinearChart } from './linear-chart.interface';
import { tooltipFormatter } from "../utils";

export const DEFAULT_YAXIS_INPUTS: YAXisComponentOption = {
  type: 'value',
  axisLine: {
    show: true,
    lineStyle: {
      color: '#CCCCCC'
    }
  },
  axisLabel: {
    formatter: (value: number) => {
      return formatNumber(value)
    },
    color: '#666666',
  },
  nameTextStyle: {
    color: '#8A8A8A',
    padding: [0, 0, 0, 130]
  },
  alignTicks: true,
};

export const DEFAULT_XAXIS: XAXisComponentOption = {
  type: 'time',
  splitLine: {
    show: true
  },
  axisTick: {
    show: false,
    lineStyle: {
      color: '#CCCCCC'
    },
  },
  axisLabel: {
    color: '#666666'
  },
  nameTextStyle: {
    color: '#8A8A8A'
  }
};

function formatNumber(num: number): string {
  return num.toString().replace(',', '');
}

export const SHARED_LINE_SERIE_CONFIG = {
  type: 'line',
  smooth: true,
  showSymbol: false,
};

const ELECTRICAL_POWER_OPTIMUM_KW = 'electrical power optimum (kW)';
const FUEL_GAS_OPTIMUM_SM3H = 'fuel gas optimum (Sm3/h)';
export const ELECTRICAL_POWER_OPTIMUM_KW_UNIT = 'kW';
export const FUEL_GAS_OPTIMUM_SM3H_UNIT = 'Sm3/h';
export const FUEL_GAS_OPTIMUM_KTCO2_YEAR_UNIT = 'ktCO2/year';

export const TURBOGENERATORS_SYSTEM_GRAPH_NAME = 'Turbogenerators System';
export const DEFAULT_SYSTEM_GRAPH_NAME = 'System';

export const DEFAULT_LINEAR_GRAPH_TOOLTIP_CONFIGURATION: TooltipComponentOption = {
  trigger: 'axis',
  padding: [15, 15, 0, 15],
  borderRadius: 8,
  axisPointer: {
    label: {
      show: false,
    },
    show: true,
    type: 'cross',
    snap: true
  }
};

export const DEFAULT_LINEAR_GRAPH: LinearChart = {
  silent: true,
  tooltip: {
    trigger: 'axis',
    padding: [15, 15, 0, 15],
    borderRadius: 8,
    formatter: tooltipFormatter()
  },
  series: [],
  toolbox: {
    feature: {
      dataZoom: {
        filterMode: 'none'
      },
      restore: {},
      saveAsImage: {}
    }
  },
};

export const LAST_3_YEARS_GRAPH_TITLE: string = '';
export const LAST_3_DAYS_GRAPH_TITLE: string = 'Last 72h';

export const DEFAULT_LEGEND = {
  top: 'bottom',
  backgroundColor: '#F7F9FA',
  padding: [16, 24],
  borderRadius: 8,
  itemGap: 10,
  itemHeight: 8,
};

export const CROSS_PLOT: EChartsOption = {
  ...DEFAULT_LINEAR_GRAPH,
  title: {
  },
  tooltip: {
    trigger: 'item',
    padding: 15,
    borderRadius: 8,
    axisPointer: {
      label: {
        backgroundColor: '#85888A',
        formatter: (params) => {
          return formatNumber(params.value ? Math.round(+params.value * 100) / 100 : 0);
        }
      },
      show: true,
      type: 'cross',
      lineStyle: {
        type: 'dashed',
        width: 1
      }
    }
  },
  silent: false,
  legend: {
    ...DEFAULT_LEGEND,
    z: 1,
    data: ['Operating points', 'Baseline', 'Normal consumption (*)', 'Over consumption (*)', 'Under consumption (*)', 'Last observation (*)'],
    selected: {
      'Baseline Turbogenerator A': false,
      'Baseline Turbogenerator B': false,
      'Baseline Turbogenerator C': false,
      'Baseline Sea Water Lift': false,
      'Baseline Water Injection': false,
      'Baseline Sru Feed Pump': false,
    }
  },
  grid: {
    bottom: '23%',
    left: 70,
    right: 150
  },
  xAxis: {
    ...DEFAULT_XAXIS,
    name: 'Delivered Power (MW)',
    type: 'value',
    scale: true,
    splitLine: {
      show: false
    },
    axisLabel: {
      formatter: (value: number) => {
        return formatNumber(value)
      },
      color: '#666666',
    },
  },
  yAxis: {
    ...DEFAULT_YAXIS_INPUTS,
    name: 'Fuel gas - Nm3/h',
    type: 'value',
    scale: true
  },
  animation: false,
  series: [
    {
      name: 'Operating points',
      type: 'scatter',
      color: '#85888A',
      symbolSize: 2,
      tooltip: {
      },
      z: -1,
      progressiveThreshold: 10000,
    },
    {
      name: 'Baseline',
      type: 'line',
      color: '#2196F3',
      smooth: true,
      z: 0,
      symbol: 'line',
      tooltip: {
      },
      progressiveThreshold: 10000,
    },
    {
      name: 'Baseline max',
      type: 'line',
      color: '#D9D9D9',
      smooth: true,
      areaStyle: {
      },
      z: -1,
      silent: true,
      showSymbol: false,
      progressiveThreshold: 10000,
    },
    {
      name: 'Baseline min',
      type: 'line',
      color: '#D9D9D9',
      smooth: true,
      areaStyle: {
        color: 'white',
        opacity: 1,
      },
      z: -1,
      silent: true,
      showSymbol: false,
      progressiveThreshold: 10000,
    },
    {
      name: 'Normal consumption (*)',
      type: 'scatter',
      color: '#FFCC80',
      tooltip: {
      },
      z: 0,
    },
    {
      name: 'Over consumption (*)',
      type: 'scatter',
      color: '#ED0000',
      tooltip: {
      },
      z: 10,
    },
    {
      name: 'Under consumption (*)',
      type: 'scatter',
      color: '#40A900',
      tooltip: {
      },
      z: 0,
    },
    {
      name: 'Last observation (*)',
      type: 'scatter',
      color: '#285AFF',
      tooltip: {
      },
      z: 11,
    }
  ]
};

export const CROSS_PLOT_MULTIPLE: EChartsOption = {
  ...DEFAULT_LINEAR_GRAPH,
  title: {
  },
  tooltip: {
    trigger: 'item',
    padding: 15,
    borderRadius: 8,
    axisPointer: {
      label: {
        backgroundColor: '#85888A',
        formatter: (params) => {
          return formatNumber(params.value ? Math.round(+params.value * 100) / 100 : 0);
        }
      },
      show: true,
      type: 'cross',
      lineStyle: {
        type: 'dashed',
        width: 1
      }
    }
  },
  silent: false,
  legend: {
    ...DEFAULT_LEGEND,
    z: 1,
    data: []
  },
  grid: {
    bottom: '23%',
    left: 70,
    right: 150
  },
  xAxis: {
    ...DEFAULT_XAXIS,
    name: 'Delivered Power (MW)',
    type: 'value',
    scale: true,
    splitLine: {
      show: false
    },
    axisLabel: {
      formatter: (value: number) => {
        return formatNumber(value)
      },
      color: '#666666',
    },
  },
  yAxis: {
    ...DEFAULT_YAXIS_INPUTS,
    name: 'Fuel gas - Nm3/h',
    type: 'value',
    scale: true
  },
  animation: false,
  series: []
};

export const buildBaselineModel = (name: String, index: number) => {
  return {
    name: name,
    type: 'line',
    color: BASELINES_DEFAULT_COLORS[index],
    smooth: true,
    z: 0,
    symbol: 'line',
    tooltip: {},
    progressiveThreshold: 10000,
  };
}

export const BASELINES_DEFAULT_COLORS = new Array<string>('#285AFF', '#06929d', '#000000', '#b7a917', '#b60430', '#00695C');

export const LONG_LEGEND_BOTTOM: string = '27%';

export function getGraphMetaDataFrom(graphMetadata: { [x: string]: any; }[], equipmentName: string): any{
  const equipmentMetadatas = graphMetadata
    .filter((metadata: { [x: string]: any; }) => metadata.hasOwnProperty(equipmentName))
    .map((metadata: { [x: string]: any; }) => metadata[equipmentName]);
  if (equipmentMetadatas && equipmentMetadatas.length > 0) {
    return equipmentMetadatas[0];
  }
}

export function calculateScale(number: number): number {
  number = Math.abs(number);
  if (number < 10) {
    return 2;
  } else if (number < 1000) {
    return 1;
  }
  return 0;
}
