import {
  DEFAULT_LEGEND,
  DEFAULT_XAXIS,
  DEFAULT_YAXIS_INPUTS,
  LAST_3_DAYS_GRAPH_TITLE, LAST_3_YEARS_GRAPH_TITLE,
  LinearChart
} from "../../types";
import {
  ALERT_TREND_GRAPH
} from "../../containers/general-dashboard/general-dashboard.container.const";
import { formatDateOrDateTime } from "../../utils/date-time/date-time";
import { XAXisOption } from "echarts/types/dist/shared";
import { cloneObject } from "../../utils/object-manipulation/object-manipulation";

export const DEFAULT_GRAPH_DATE_TIME_FORMAT = '{dd}/{MM}/{yyyy}';

export const DEFAULT_PERFORMANCE_DRIFT_GRAPH: LinearChart = {
  ...ALERT_TREND_GRAPH,
  title: {
    textStyle: {
      fontSize: 14
    }
  },
  legend: { ...DEFAULT_LEGEND },
  grid: {
    left: 70,
    bottom: '23%',
  },
  toolbox: {
    orient: 'horizontal',
    itemSize: 13,
    right: 100,
    feature: {
      dataZoom: {},
      restore: {},
      saveAsImage: {}
    }
  },
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'cross',
      label: {
        formatter: (params) => {
          if (params.axisDimension === 'x') {
            return formatDateOrDateTime(params.value as string);
          }
          return formatNumber(params.value ? Math.round(+params.value * 100) / 100 : 0);
        }
      }
    }
  },
  xAxis: {
    ...DEFAULT_XAXIS,
    splitNumber: 2,
    axisLabel: {
      formatter: DEFAULT_GRAPH_DATE_TIME_FORMAT,
      showMaxLabel: true
    }
  },
  series: []
};

export const DEFAULT_YAXIS_PERFORMANCE_DRIFT_INPUTS: XAXisOption = {
  ...DEFAULT_YAXIS_INPUTS,
  axisLabel: {
    formatter: (value: number) => {
      return formatNumber(value)
    },
    color: '#666666',
  },
  name: 'Deviation from Fuel gas optimum (Nm3/h)',
  nameTextStyle: {
    padding: [ 0, 0, 0, 200 ],
    color: '#808080'
  }
} as XAXisOption;

function formatNumber(num: number): string {
  let str = num.toString();
  let result = str.replace(',', '');
  return result;
}

export const DEFAULT_LINE_CHART_GRAPHS_MAP = new Map<string, LinearChart>([
  [ LAST_3_DAYS_GRAPH_TITLE, cloneObject(DEFAULT_PERFORMANCE_DRIFT_GRAPH) ],
  [ LAST_3_YEARS_GRAPH_TITLE, cloneObject(DEFAULT_PERFORMANCE_DRIFT_GRAPH) ] ]);
