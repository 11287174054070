import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { TabItem } from 'src/modules/app/types';
import { FAVORITE_ASSET_STORAGE_KEY } from "../../containers";

@Component({
  selector: 'co2-tab-selector',
  templateUrl: './tab-selector.component.html',
  styleUrls: ['./tab-selector.component.scss']
})
export class TabSelectorComponent {
  @Input() tabsList!: TabItem[];
  @Input() activeTab!: string | null;
  @Output() selectTab: EventEmitter<string> = new EventEmitter();
  showHomeButton = false;

  constructor(
    private router: Router
  ) {}

  @HostListener('window:scroll', [])
  onWindowScroll() {
    const scrollPosition = document.documentElement.scrollTop || document.body.scrollTop;

    this.showHomeButton = scrollPosition > 100;
  }

  goToHomePage() {
    const asset = JSON.parse(localStorage.getItem(FAVORITE_ASSET_STORAGE_KEY) ?? '{}');
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([ `/${ asset.affiliate }/${ asset.fpsos }/general-dashboard` ]);
    });
  }
}
