import { Action, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { GetPumpHourlyPerformanceDrift } from './actions';
import { ValueSeries } from '../../app/types';

@State<ValueSeries[]>({
  name: 'sruFeedHourlyPerformanceDrift',
})
@Injectable()
export class PumpHourlyPerformanceDriftState {
  @Action(GetPumpHourlyPerformanceDrift)
  getPumpHourlyPerformanceDrift(context: StateContext<ValueSeries[] | undefined>, { payload }: GetPumpHourlyPerformanceDrift): void {
    context.setState(payload);
  }
}
