import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { TurbogeneratorsContainer } from './turbogenerators.container';
import { SharedModule } from '../../shared.module';
import { TabSelectorModule } from '../../components/tab-selector/tab-selector.module';
import { OverviewKpisModule } from '../../components/overview-kpis/overview-kpis.module';
import { CrossPlotModule } from '../../components/cross-plot/cross-plot.module';
import { PerformanceDriftModule } from '../../components/performance-drift/performance-drift.module';
import { PowerDataModule } from './components/power-data/power-data.module';
import { TurbogenCo2FactorGraphComponent } from './components';
import { CardErrorModule } from '../../components/card-error/card-error.module';
import { UiGraphModule } from '../../components/ui-graph/ui-graph.module';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';



const routes: Routes = [
  { path: '', component: TurbogeneratorsContainer }
];

@NgModule({
  declarations: [TurbogeneratorsContainer,TurbogenCo2FactorGraphComponent],
  imports: [
    CommonModule,
    FormsModule,
    TabSelectorModule,
    OverviewKpisModule,
    PerformanceDriftModule,
    PowerDataModule,
    CrossPlotModule,
    CardErrorModule,
    UiGraphModule,
    NgxSkeletonLoaderModule,
    SharedModule,
    RouterModule.forChild(routes)
  ],
  exports: [TurbogeneratorsContainer,TurbogenCo2FactorGraphComponent]
})
export class TurbogeneratorsContainerModule { }
