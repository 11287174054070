import { Action, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { PumpFeedPerformanceDrift } from './actions';
import { ValueSeries } from '../../app/types';

@State<ValueSeries[]>({
  name: 'pumpPerformanceDrift',
})
@Injectable()
export class PumpPerformanceDriftState {
  @Action(PumpFeedPerformanceDrift)
  getPumpPerformanceDrift(context: StateContext<ValueSeries[] | undefined>, { payload }: PumpFeedPerformanceDrift): void {
    context.setState(payload);
  }
}
