import { DatePipe } from "@angular/common";

export function getStartDateSince3Days(): string {
  let date: Date = new Date();
  date.setDate(date.getDate() - 3);
  return date.toISOString().substring(0, 10);
}

export function getStartDateSince3Years(): string {
  let date: Date = new Date();
  date.setFullYear(date.getFullYear() - 3);
  return date.toISOString().substring(0, 10);
}

export function formatDateOrDateTime(dateValue: string) {
  const datePipe: DatePipe = new DatePipe('en-US');
  try {
    let formattedDate = datePipe.transform(dateValue, 'dd/MM/yyyy HH:mm:ss') || '';
    return formattedDate?.includes("00:00:00") ? formattedDate?.split(" ")[0] : formattedDate;
  } catch (error) {
    // If parsing is not possible return the original value, because it is already formatted.
    return dateValue;
  }
}

export function isToday(dateTime: Date | string) {
  const today = new Date();
  const date = new Date(dateTime);
  return date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear();
}

