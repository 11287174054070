<div class="card sub-card">
  <div class="card-title">
    Power vs Time (by number of turbines)
  </div>

  <div class="card-body">
    <div class="co2-graph" *ngIf="!isLoading && !hasLoadingError">
      <co2-ui-graph *ngFor="let powerTurbinesGraph of powerTurbinesGraphInputs"
       [options]="powerTurbinesGraph"></co2-ui-graph>
    </div>

    <ngx-skeleton-loader *ngIf="isLoading" count="6" appearance="line"></ngx-skeleton-loader>

    <co2-card-error *ngIf="hasLoadingError && !isLoading" (tryAgain)="reloadData.emit()"></co2-card-error>
  </div>
</div>
