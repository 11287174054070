import { MachineStatus } from './machine-status.enum';

export interface KpiOverview {
  runningEquipmentsCount: number | null;
  consumedPower?: number | null;
  deviationTargetedEnergy?: number | null;
  emissionsASVOpening?: number | null;
  emissionsPolytropicEfficiencyDegradation?: number | null;
  emissionsOverconsumption?: number | null;
  emissionsPerformanceDrift?: number | null;
  consumedPowerKm3d?: number | null;
  avgGhgEmissions?: number | null;
  estimatedMaxPower?: number | null;
  currentLoad?: number | null;
  powerReserve?: number | null;
  loadPercentage?: number | null;
  energyType?: EnergyType | null;
  kpiByEquipments: Array<KpiOverviewByEquipment>
}

export interface KpiOverviewByEquipment {
  equipmentName: string;
  equipmentStatus: MachineStatus;
  date: Date;
  consumedPower?: number | null;
  energyType?: EnergyType | null;
  deviationTargetedEnergy?: number | null;
  emissionsASVOpening?: number | null;
  emissionsPolytropicEfficiencyDegradation?:number | null;
  emissionsOverconsumption?: number | null;
  emissionsPerformanceDrift?: number | null;
  intraSystemEmission?: number | null;
  consumedPowerKm3d?: number | null;
  avgGhgEmissions?: number | null;
  estimatedMaxPower?: number | null;
  currentLoad?: number | null;
  powerReserve?: number | null;
  loadPercentage?: number | null;
  stages?: Array<KpiMetric>
}

export interface KpiMetric {
  stageName: string;
  asvOpening?: number | null;
  pressureLoss?: number | null;
  polytropicEfficiency?: number | null;
  polytropicEfficiencyReference?: number | null;
}

export enum EnergyType {
  FUEL_GAS = "FUEL_GAS", ELECTRIC = "ELECTRIC"
}
