<div class="card">
  <div class="card-body text-center">
    <div class="row">
      <div class="col-8">
        <img src="assets/errors/403.png" alt="Error Image"/>
      </div>
      <div class="col-4 align-self-center">
        <h4>Error 403</h4>
        <p>We are sorry, but you do not have <br> right access to this page or resource.</p>
        <button type="button" class="btn btn-secondary" (click)="redirectToLogin()">Back to home</button>
      </div>
    </div>
  </div>
</div>

<div *ngIf="debug">{{ error }}</div>
