import {
  DEFAULT_LINEAR_GRAPH,
  DEFAULT_XAXIS,
  DEFAULT_YAXIS_INPUTS,
  DataType,
  LinearChart,
  TableHeader, DEFAULT_LINEAR_GRAPH_TOOLTIP_CONFIGURATION, ALL_ALERT_STATUS_METADATA
} from '../../types';

export const INIT_INDEX_ALERT: number = 0;
export const PAGE_SIZE_ALERT: number = 5;

export const ALERT_TREND_GRAPH: LinearChart = {
  ...DEFAULT_LINEAR_GRAPH,
  toolbox: {
    show: false
  },
  tooltip: { ...DEFAULT_LINEAR_GRAPH_TOOLTIP_CONFIGURATION },
  grid: {
    top: '20px',
    bottom: '0px',
    left: '25px',
    containLabel: true
  },
  yAxis: {
    ...DEFAULT_YAXIS_INPUTS,
    splitLine: {
      show: true
    },
    name: 'ktCO2/y',
    nameTextStyle: {
      color: '#8A8A8A',
      fontSize: 8
    },
    axisLabel: {
      color: '#666666',
      fontSize: 12,
      margin: 10,
    },
    nameGap: 7,
    splitNumber: 4,
    scale: true,
    axisTick: {
      show: true,
      length: 8,
      lineStyle: {
        color: '#CCCCCC'
      },
    },
  },
  xAxis: {
    ...DEFAULT_XAXIS,
    axisLabel: {
      color: '#666666',
      fontSize: 12,
      margin: 10,
      hideOverlap: true,
      formatter: '{dd}/{MM}/{yyyy}'
    },
    axisLine: {
      lineStyle: {
        color: '#CCCCCC'
      }
    },
    axisTick: {
      length: 8,
      lineStyle: {
        color: '#CCCCCC'
      },
    },
    splitLine: {
      show: true
    },
    splitNumber: 4,
    scale: true
  },
};

export const defaultColor: { color: string, badgeBackgroundColor: string } = { color: '#625d5d', badgeBackgroundColor: '#625D5D3A' };

export const defaultSankeyColor: { color: string, borderColor: string } = {
  color: '#d1d3d4',
  borderColor: '#625D5D3A'
};

export enum SystemType {
  Compression = 'Compression',
  TurboGenerator = 'Power generation',
  WaterInjectionPumpsGroup = 'Water injection pumps group',
  WaterInjection = 'Water injection',
  SRUFeedPump = 'SRU Feed Pump',
  SeaWaterLift = 'Sea Water Lift'
}


export enum SankeyType {
  Compressor = 'Compressor',
  PowerGeneration = 'Power generation',
  WaterInjection = 'Water injection',
  SRU = 'SRU',
  SWL = 'SWL',
  MPP = 'MPP',
  Performance = 'Performance',
  ProcessInefficiency = 'Process inefficiency',
  WastedEnergy = 'Wasted Energy',
  LowestAchievableEmissions = 'Lowest Achievable emissions'
}

export const systemColorMap = new Map<string, { color: string, badgeBackgroundColor: string }>([
  [SystemType.Compression, { color: '#FFC694', badgeBackgroundColor: '#FFC6942B' }],
  [SystemType.TurboGenerator, { color: '#D68100', badgeBackgroundColor: '#D681002B' }],
  [SystemType.WaterInjectionPumpsGroup, { color: '#9dc9f4', badgeBackgroundColor: '#9dc9f42B' }],
  [SystemType.WaterInjection, { color: '#9dc9f4', badgeBackgroundColor: '#9dc9f42B' }],
  [SystemType.SRUFeedPump, { color: '#9dc9f4', badgeBackgroundColor: '#9dc9f42B' }],
  [SystemType.SeaWaterLift, { color: '#9dc9f4', badgeBackgroundColor: '#9dc9f42B' }]
]);

// Sankey colors must be similar as alert colors for same system type.
export const sankeyColorMap = new Map<string, {
  color?: string,
  borderColor?: string,
  decal?: any
}>([
  [ SankeyType.Compressor, { color: '#FFC694', borderColor: '#FFC6942B' } ],
  [ SankeyType.PowerGeneration, { color: '#D68100', borderColor: '#D681002B' } ],
  [ SankeyType.WaterInjection, { color: '#9dc9f4', borderColor: '#9dc9f42B' } ],
  [ SankeyType.SRU, { color: '#9dc9f4', borderColor: '#9dc9f42B' } ],
  [ SankeyType.SWL, { color: '#9dc9f4', borderColor: '#9dc9f42B' } ],
  [ SankeyType.MPP, { color: '#9dc9f4', borderColor: '#9dc9f42B' } ],
  [ SankeyType.Performance, {
    color: '#ffffff', borderColor: '#646d79', decal: {
      color: '#123456',
      symbol: 'rect',
      symbolSize: 0.8,
      dashArrayX: [1, 1],
      dashArrayY: [1, 1]
    }
  } ],
  [ SankeyType.ProcessInefficiency, { color: '#ed0000', borderColor: '#ed00002B' } ],
  [ SankeyType.WastedEnergy, { color: '#ffffff', borderColor: '#646d79' } ],
  [ SankeyType.LowestAchievableEmissions, {
    color: '#ffffff', borderColor: '#646d79', decal: {
      color: '#e5e5e5',
      dashArrayX: [ 1, 0 ],
      dashArrayY: [ 4, 3 ],
      rotation: -Math.PI / 4
    }
  } ]
]);

export const sankeyDepth = new Map<string, number>([
  ["Wasted Energy", 2]
]);

export const ALERT_HEADERS: TableHeader[] = [
  {
    id: 'nameWithType',
    label: 'Equipment',
    tooltip: "Status",
    dataType: DataType.HTML_TEXT,
    metadata: ALL_ALERT_STATUS_METADATA

  },
  {
    id: 'trendGraph',
    label: 'Trend',
    dataType: DataType.GRAPH
  },
  {
    id: 'hourlyTrendGraph',
    label: 'Last 72h',
    dataType: DataType.GRAPH
  },
  {
    id: 'avoidableEmissions',
    label: 'Avoidable emissions',
    dataType: DataType.BADGE,
    tooltip: '- First value corresponds to the value of “Avoidable emissions” of Day-1 (yesterday).<br/>' +
      '- Second value corresponds to the year to date accumulated avoidable emissions.',
    suffixText: 'ktCO2/yr',
    subHeaderId: 'ytdAvoidableEmissions',
    defaultValue: 'No data',
  },
  {
    id: 'observation',
    label: 'Description',
    dataType: DataType.TEXT,
  }
];
