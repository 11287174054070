import { Component, EventEmitter, Output, Input } from '@angular/core';

@Component({
  selector: 'co2-card-error',
  templateUrl: './card-error.component.html',
  styleUrls: ['./card-error.component.scss']
})
export class CardErrorComponent {
  @Input() dataName: string = 'This';
  @Output() tryAgain = new EventEmitter();
}
