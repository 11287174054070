import { Action, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { GetCompressorsHourlyPerformanceDrift } from './actions';
import { ValueSeries } from '../../app/types';

@State<ValueSeries[]>({
  name: 'compressorsHourlyPerformanceDrift',
})
@Injectable()
export class CompressorsHourlyPerformanceDriftState {
  @Action(GetCompressorsHourlyPerformanceDrift)
  getCompressorsHourlyPerformanceDrift(context: StateContext<ValueSeries[] | undefined>, { payload }: GetCompressorsHourlyPerformanceDrift): void {
    context.setState(payload);
  }
}
