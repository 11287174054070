<co2-tab-selector [tabsList]="fpsoTabs" [activeTab]="activeFpso" (selectTab)="onSelectTab($event)">
</co2-tab-selector>
<div class="display">
<a *ngIf="isAdmin" class="link-primary arrow" [routerLink]="['/configurations']">Access to upload configurations</a>
</div>
<div *ngIf="list">
<div class="title">Parameters</div>
<div *ngIf="listEquipments?.[0]?.equipments?.length ; else insertParams" style="display: flex">
  <div *ngFor="let container of containers" class="container">
    <div *ngFor="let equipment of listEquipments[0].equipments  | slice:(container * equipmentsInContainers) :(container * equipmentsInContainers + equipmentsInContainers); let i = index" class="accordion item" [ngClass]="{'hg-56': isActive((container * equipmentsInContainers + i))}">
      <div class="accordion-item" [ngClass]="{'hg-56': isActive((container * equipmentsInContainers + i))}">
        <h4 class="accordion-header d-flex" [id]="'accordion-btn-header-' + (container * equipmentsInContainers + i)">
          <button class="accordion-button collapsed flex-grow-1" type="button" data-bs-toggle="collapse" [attr.data-bs-target]="'#accordion-btn-collapse-' + (container * equipmentsInContainers + i)" aria-expanded="true" [attr.aria-controls]="'accordion-btn-collapse-' + (container * equipmentsInContainers + i)" (click)="toggleAccordion((container * equipmentsInContainers + i))">
            {{equipment.label}}
          </button>
        </h4>
        <div [id]="'accordion-btn-collapse-' + (container * equipmentsInContainers + i)" class="accordion-collapse collapse" [attr.aria-labelledby]="'accordion-btn-header-' + (container * equipmentsInContainers + i)">
          <div class="accordion-body">
            <div class="actions">
              <div *ngIf="!actionEdit && actionEditIndex !== (container * equipmentsInContainers + i) && isAdmin" class="link" (keydown)="editInput((container * equipmentsInContainers + i))" (click)="editInput((container * equipmentsInContainers + i))"><span class="material-icons-outlined">edit</span> Edit </div>
              <div *ngIf="actionEdit && actionEditIndex === (container * equipmentsInContainers + i)" class="link cancel" (keydown)="cancelEdits((container * equipmentsInContainers + i))" (click)="cancelEdits((container * equipmentsInContainers + i))">Cancel </div>
              <button *ngIf="actionEdit && actionEditIndex === (container * equipmentsInContainers + i)" type="button" class="btn btn-secondary" (click)="saveEdits(equipment, (container * equipmentsInContainers + i))" [disabled]="isSaveDisabled">
                <span class="material-icons" aria-hidden="true">done</span> Save edit
              </button>
            </div>
            <div *ngFor="let item of equipment.params; let j = index">
              <div *ngIf="item.type === 'NUMBER'" class="form-group mb-3">
                <label for="inputForm-{{(container * equipmentsInContainers + i)}}-{{j}}" class="form-label">{{item.name}}</label>
                <input type="text" class="form-control" [id]="'inputForm-' + (container * equipmentsInContainers + i) + '-' + j" [(ngModel)]="item.value" [disabled]="actionEditIndex !== (container * equipmentsInContainers + i)" pattern="^\d*\.?\d+$"
                       (keypress)="validateNumberInput($event)" (input)="checkEmptyInput(item.value)" required>
              </div>
              <div *ngIf="item.type === 'TABLE'" class="form-group mb-3">
                <label for="inputForm-{{(container * equipmentsInContainers + i)}}-{{j}}" class="form-label">{{item.name}}</label>
                <div *ngFor="let key of getObjectKeys(item.value);" class="values">
                  <input type="text" class="form-control key" [id]="'inputForm-' + (container * equipmentsInContainers + i) + '-' + j" [value]="key" disabled required>
                  <input type="text" class="form-control value" [id]="'inputForm-' + (container * equipmentsInContainers + i) + '-' + j" [(ngModel)]="item.value[key]" [disabled]="actionEditIndex !== (container * equipmentsInContainers + i)" pattern="^\d*\.?\d+$"
                         (keypress)="validateNumberInput($event)" (input)="checkEmptyInput(item.value)" required>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #insertParams>
  <div class="form-text">You have to upload the config parameter file to be able to configure all parameters</div>
</ng-template>

<div *ngIf="listEquipments.length > 0" class="card card-min-width">
  <div class="card-body">
    <div *ngIf="listEquipments[0].links.length > 0" class="title__gaz">
      <h4 class="accordion-header d-flex">
        {{listEquipments[0].links[0].title}}
      </h4>
      <a class="link-primary arrow" (click)="downloadFile()">{{listEquipments[0].links[0].label}}</a>
    </div>
  </div>
</div>
</div>
