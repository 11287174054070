import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IndicatorInfos } from 'src/modules/app/types';
import { SankeyInput } from "../../../sankey/sankey-input";

@Component({
  selector: 'co2-overview-ghg-emissions',
  templateUrl: './overview-ghg-emissions.component.html',
  styleUrls: ['./overview-ghg-emissions.component.scss'],
})
export class OverviewGhgEmissionsComponent {
  @Input() activeFpso!: string | null;
  @Input() totalCo2YearlyEmission!: IndicatorInfos | null;
  @Input() totalAvoidedCo2!: IndicatorInfos | null;
  @Input() energyEfficiency!: IndicatorInfos | null;
  @Input() currentEmissions!: IndicatorInfos | null;
  @Input() lowestAchievableEmissions!: IndicatorInfos | null;
  @Input() isLoading!: boolean | null;
  @Input() hasLoadingError!: boolean | null;
  @Output() reloadData = new EventEmitter();
  @Input() sankey!: SankeyInput | null;

}
