import { Component, OnInit } from "@angular/core";
import { ToastService, ToastType } from "../../services/toaster/toaster.service";

@Component({
  selector: 'app-toaster',
  templateUrl: './toaster.component.html',
  styleUrls: ['./toaster.component.scss']
})

export class ToasterComponent implements OnInit {

  message: string = '';
  type: ToastType = 'success';
  show: boolean = false;

  constructor(private toastService: ToastService) {}

  ngOnInit() {
    this.toastService.toastState.subscribe(toast => {
      this.message = toast.message;
      this.type = toast.type;
      this.show = true;
      setTimeout(() => this.show = false, toast.duration);
    });
  }
}
