
export function downloadFileFromHttpResponse(response: any) {
  const filename = getFileNameFromHttpResponse(response);
  // Create a Blob from the response body
  const blob = new Blob([ response.body ], { type: response.body.type });

  // Trigger download
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = filename;
  a.click();

  // Clean up
  window.URL.revokeObjectURL(url);
}

function getFileNameFromHttpResponse(response: any, filename: string = 'downloaded-file') {
  // Extract filename from Content-Disposition header
  const contentDisposition = response.headers.get('Content-Disposition');

  if (contentDisposition) {
    const matches = /filename="([^"]*)"/.exec(contentDisposition);
    if (matches != null && matches[1]) {
      filename = matches[1];
    }
  }
  return filename;
}
