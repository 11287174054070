export enum EquipmentType {
  TURBOGEN = 'TURBOGEN',
  COMPRESSOR = 'COMPRESSOR',
  SRU_FEED_PUMPS = 'SRU_FEED_PUMPS',
  SEAWATER_LIFT_PUMPS = 'SEAWATER_LIFT_PUMPS',
  WATER_INJECTION_PUMPS = 'WATER_INJECTION_PUMPS'
}

export interface Param {
  type: string;
  name: string;
  value: any;
}

export interface Equipment {
  label: string;
  params: Param[];
}

export interface Link {
  title: string;
  link: string;
  label: string;
}

export interface EquipmentCollection {
  id: number;
  label: string;
  params: any;
  equipments: Equipment[];
  links: Link[];
}
