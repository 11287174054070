import { Component, Input, Output, EventEmitter } from '@angular/core';
import { EChartsOption } from 'echarts';

@Component({
  selector: 'co2-power-turbines',
  templateUrl: './power-turbines.component.html',
  styleUrls: ['./power-turbines.component.scss']
})
export class PowerTurbinesComponent {
  @Input() powerTurbinesGraphInputs!: EChartsOption[];
  @Input() isLoading!: boolean | null;
  @Input() hasLoadingError!: boolean | null;
  @Output() reloadData = new EventEmitter();
}
