import { Action, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { GetSeaWaterLiftPerformanceDrift } from './actions';
import { ValueSeries } from '../../app/types';

@State<ValueSeries[]>({
  name: 'seaWaterLiftPerformanceDrift',
})
@Injectable()
export class SeaWaterLiftPerformanceDriftState {
  @Action(GetSeaWaterLiftPerformanceDrift)
  getSeaWaterLiftPerformanceDrift(context: StateContext<ValueSeries[] | undefined>, { payload }: GetSeaWaterLiftPerformanceDrift): void {
    context.setState(payload);
  }
}
