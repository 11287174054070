import { EChartsOption } from "echarts";
import {calculateScale} from "../../types";

// export const SANKEY_COLORS = [
//   '#ED0000',
//   '#D0D3D4',
//   '#FFCC80',
//   '#00838F',
//   '#D68100',
//   'rgba(237,0,0,0.2)'
// ];

export const SANKEY_GRAPH: EChartsOption = {
  height: 200,
  series: {
    type: 'sankey',
    // color: SANKEY_COLORS,
    levels: [
      {
        depth: 0,
        itemStyle: {
          color: '#D68100'
        }
      },
      {
        depth: 1,
        itemStyle: {
          color: '#00838F'
        }
      },
      {
        depth: 2,
        itemStyle: {
          color: '#ED0000'
        }
      }
    ],
    emphasis: {
      focus: 'adjacency'
    },
    nodeAlign: 'left',
    layoutIterations: 0,
    nodeGap: 25,
    draggable: false,
    tooltip: {
      trigger: 'item',
      triggerOn: 'mousemove'
    },
    data: [],
    links: [],
    lineStyle: {
      color: 'source',
      curveness: 0.5
    }
  }
};

export const buildSankeyLabelFormatter = (unit: string) => {
  return (params: any) => {
    let calculatedScale = calculateScale(params.value);
    return `${ params.name }\n${ params.value.toFixed(calculatedScale) } ${unit}`
  }
}
