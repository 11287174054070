import { Action, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { GetCompressorsCrossPlot } from './actions';
import { CrossPlot } from '../../app/types';

@State<CrossPlot[]>({
  name: 'compressorCrossPlot',
})
@Injectable()
export class CompressorCrossPlotState {
  @Action(GetCompressorsCrossPlot)
  getCompressorCrossPlot(context: StateContext<CrossPlot[] | undefined>, { payload }: GetCompressorsCrossPlot): void {
    context.setState(payload);
  }
}
