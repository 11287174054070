import { Action, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { GetCompressorsPerformanceDrift } from './actions';
import { ValueSeries } from '../../app/types';

@State<ValueSeries[]>({
  name: 'compressorsPerformanceDrift',
})
@Injectable()
export class CompressorsPerformanceDriftState {
  @Action(GetCompressorsPerformanceDrift)
  getCompressorsPerformanceDrift(context: StateContext<ValueSeries[] | undefined>, { payload }: GetCompressorsPerformanceDrift): void {
    context.setState(payload);
  }
}
