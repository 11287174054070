import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { ALERT_DATE_FORMAT, formatDateFromTo, FRENCH_DATE_FORMAT } from "../../utils/date-time-picker/date-time-picker";
import { AlertStatus } from "../../types";
import { ResponseMessage } from '../baseline/model/baseline.model';

@Injectable({
    providedIn: 'root'
})
export class DiscussionService {

    constructor(private http: HttpClient) {
    }

    upsertDiscussion(affiliate: string, fpsoName: string, alertId: string, alertDate: string, discussions: any): Observable<any> {
        const params = new HttpParams()
            .set('alert_date', formatDateFromTo(alertDate, FRENCH_DATE_FORMAT, ALERT_DATE_FORMAT));
        return this.http.post<any>(
            `${ environment.apiUrl }/${ affiliate }/${ fpsoName }/alerting/${ alertId }/discussions`,
            discussions,
            { params: params }
        );
    }

    listDiscussions(affiliate: string, fpsoName: string, alertId: string) {
        return this.http.get<any>(
            `${ environment.apiUrl }/${ affiliate }/${ fpsoName }/alerting/${ alertId }/discussions`
        );
    }

  updateAlertStatus(affiliate: string, fpsoName: string, alertId: string, alertDate: string, newStatus: AlertStatus): Observable<any> {
    const params = new HttpParams()
      .set('alert_date', formatDateFromTo(alertDate, FRENCH_DATE_FORMAT, ALERT_DATE_FORMAT))
      .append('new_status', newStatus);
    return this.http.put<any>(
      `${ environment.apiUrl }/${ affiliate }/${ fpsoName }/alerting/${ alertId }/status`,
      {},
      { params: params }
    );
  }

  uploadFile(affiliate:string, fpso:string, alertId:string, discussionId:string, messageId: string, file: File): Observable<any> {
    const url = `${environment.apiUrl}/${affiliate}/${fpso}/alerting/${alertId}/discussions/${discussionId}/${messageId}/attachment`;
    const formData: FormData = new FormData();
    formData.append('file', file);
    const headers = new HttpHeaders({
      enctype: 'multipart/form-data',
    });
    const params = new HttpParams().set('affiliate', affiliate);
    return this.http.post(url, formData, { params, headers: headers, observe: 'response', responseType: 'json' });
  }

  downloadFile(affiliate: string, fpso: string, alertId:string,discussionId:string, messageId: string, fileId:string ): Observable<any> {

    const params = new HttpParams().set('affiliate', affiliate).append('fpso', fpso);
    const url = `${environment.apiUrl}/${affiliate}/${fpso}/alerting/${alertId}/discussions/${discussionId}/${messageId}/attachment/${fileId}`;
    return this.http
      .get(url, {params, responseType: 'blob', observe: 'response'});
  }
}
