import { Injectable } from '@angular/core';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AppInsightsService {
  private userRoles: string[] = [];

  appInsights = new ApplicationInsights({
    config: {
      instrumentationKey: environment.appInsightInstrumentationKey
    }
  });

  constructor() {
    this.appInsights.loadAppInsights();
  }

  logPageView(name?: string, url?: string) {
    this.appInsights.trackPageView({
      name: name,
      uri: url,
      properties: {
        "roles": this.userRoles
      }
    });
  }

  logException(exception: Error, severityLevel?: number) {
    this.appInsights.trackException({ exception: exception, severityLevel: severityLevel });
  }

  setUserRoles(userRoles: string[]) {
    this.userRoles = userRoles;
  }

  setAuthenticatedUserContext(authenticated: string, accountId: string): void {
    this.appInsights.setAuthenticatedUserContext(authenticated, accountId, true);
  }
}
