import { Action, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { GetTabsElements } from './actions';

@State<any>({
  name: 'tabsConfiguration',
  defaults: [],
})
@Injectable()
export class TabsElementsState {
  @Action(GetTabsElements)
  getTabsConfig(context: StateContext<any[]>, { payload }: any): void {
    context.setState(payload);
  }
}