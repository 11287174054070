export type DatePickType = 'startDate' | 'endDate';

export interface DatePick {
  type: DatePickType;
  value: Date | string;
}

export interface DatePicker {
  startDate?: Date | string;
  endDate?: Date | string;
  type?: DatePickType;
  value?: Date | string;
}
