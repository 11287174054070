import { EnvironmentInterface } from './environment-interface';

export const environment: EnvironmentInterface = {
  production: false,
  debug: {
    msal: true,
    router: true
  },
  appInsightInstrumentationKey: '0a930ed7-261c-4026-b259-2c04cbc7d9bf',
  aad: {
    clientId: 'ef98141e-2982-4edb-a71a-b1a03c599f32',
    tenantId: '329e91b0-e21f-48fb-a071-456717ecc28e',
    redirectUri: '/callback',
    backends: [
      {
        uri: 'https://pre-prod.apif.iasp.tgscloud.net',
        scopes: ['api://09ccfa8e-51d0-4fc3-8458-b2d3a5ca81dc/access']
      }
    ]
  },
  apiUrl: 'https://pre-prod.apif.iasp.tgscloud.net/mycfr-api/v1',
  apimSubscriptionKey: '8e257d7b64704d6e8f40eacf521f4856',
  secretEncryptionKey: '6<BJ2~X).jp0wCXp$YZ0'
};
