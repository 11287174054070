<div class="date-selection-container">
  <div class="form-text">Filter on operating points:</div>
  <div class="form-body">
    <div class="form-group">
      <label for="startDateInput" class="form-label">Start Date
        <span class="form-placeholder">(dd/mm/yyyy)</span>
      </label>
      <input type="text"
             class="form-control"
             id="startDateInput"
             placeholder="dd/mm/yyyy"
             [min]="minDate"
             [max]="maxDate"
             [matDatepicker]="startDatePickerElement"
             [formControl]="startDateFormControl"
             (dateChange)="onDateChange('startDate', $event.target.value)"
             (keyup.enter)="onEnter('startDate', $event)">
      <mat-datepicker-toggle matSuffix [for]="startDatePickerElement"></mat-datepicker-toggle>
      <mat-datepicker #startDatePickerElement></mat-datepicker>
    </div>

    <div class="form-group">
      <label for="endDateInput" class="form-label">End Date
        <span class="form-placeholder">(dd/mm/yyyy)</span>
      </label>
      <input type="text"
             class="form-control"
             id="endDateInput"
             placeholder="dd/mm/yyyy"
             [min]="minDate"
             [max]="maxDate"
             [matDatepicker]="endDatePickerElement"
             [formControl]="endDateFormControl"
             (dateChange)="onDateChange('endDate', $event.target.value)"
             (keyup.enter)="onEnter('endDate', $event)">
      <mat-datepicker-toggle matSuffix [for]="endDatePickerElement"></mat-datepicker-toggle>
      <mat-datepicker #endDatePickerElement></mat-datepicker>
    </div>
    <div *ngIf="quickSelection" class="form-group">
      <label class="form-label" for="defaultSelect"> Quick selection</label>
      <select class="form-select" id="defaultSelect" [(ngModel)]="selectedQuickValue" (change)="onQuickSelectionChange($event)">
        <option value="-1">Pick a Period</option>
        <option value="1">1 week</option>
        <option value="2">1 month</option>
        <option value="3">3 months</option>
        <option value="4">6 months</option>
        <option value="" selected="">3 years</option>
      </select>
    </div>
  </div>
  <div *ngIf="formGroup.errors && formGroup.errors['dateInvalid']" class="error-message">
    The start date must be smaller than end date.
  </div>
</div>

