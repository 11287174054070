import { Action, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { GetTurbogenCrossPlot } from './actions';
import { CrossPlot } from '../../app/types';

@State<CrossPlot[]>({
  name: 'turbogenCrossPlot',
})
@Injectable()
export class TurbogenCrossPlotState {
  @Action(GetTurbogenCrossPlot)
  getTurbogenCrossPlot(context: StateContext<CrossPlot[] | undefined>, { payload }: GetTurbogenCrossPlot): void {
    context.setState(payload);
  }
}
