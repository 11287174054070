import { Action, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { ClearAlertsData, GetAlertsData } from './actions';
import { Alert, Observations, PointCouple } from '../../app/types';
import {
  ALERT_TREND_GRAPH,
  defaultColor,
  systemColorMap
} from 'src/modules/app/containers/general-dashboard/general-dashboard.container.const';
import { tooltipFormatter } from "../../app/utils";
import { cloneObject } from "../../app/utils/object-manipulation/object-manipulation";
import { adjustMinMaxYAxisGraphsForAlerting } from "../../app/utils/graph/graph";

@State<Observations>({
  name: 'alertData',
  defaults: { alerts: [], totalSize: 0 },
})
@Injectable()
export class AlertsDataState {

  constructor() {
  }

  @Action(GetAlertsData)
  getAlertsData(context: StateContext<Observations>, { payload, resetState }: GetAlertsData): void {
    const updatedPayload = payload;
    updatedPayload.alerts.forEach((alert: Alert) => {
      alert.nameWithType = alert.systemType === 'Compression'
      ? `<b>${alert.systemType} ${alert.equipmentType}<br>(${alert.equipmentName})</b>`
      : `<b>${alert.systemType} <b> ${alert.equipmentName}</b>`;

      alert.trendGraph = this.buildAlertGraph(alert.trendData, alert.systemType);
      alert.hourlyTrendGraph = this.buildAlertGraph(alert.hourlyTrendData, alert.systemType);

      adjustMinMaxYAxisGraphsForAlerting(alert, alert.trendData, alert.hourlyTrendData);
    });

    context.setState(
      {
        alerts: resetState ? updatedPayload.alerts : context.getState().alerts.concat(updatedPayload.alerts),
        totalSize: updatedPayload.totalSize
      }
    );
  }

  private buildAlertGraph(data: PointCouple[], systemType: string) {
    const graph = cloneObject(ALERT_TREND_GRAPH);
    const values: any[] = data;
    graph.tooltip.formatter = tooltipFormatter();
    graph.xAxis.splitNumber = 2;
    graph.grid = {
      ...graph.grid,
      left: '5px',
      right: '5px'
    }
    graph.series = [
      {
        name: 'Emissions',
        type: 'line',
        areaStyle: {
          color: systemColorMap.get(systemType)?.badgeBackgroundColor || defaultColor.badgeBackgroundColor
        },
        symbol: 'circle',
        showSymbol: false,
        color: systemColorMap.get(systemType)?.color || defaultColor.color,
        data: values
      } ];
    return graph;
  }

  @Action(ClearAlertsData)
  clearAlertsData(context: StateContext<Observations>): void {
    context.setState({ alerts: [], totalSize: 0 } as Observations);
  }
}
