import { DatedValueSeries } from "../../types/dated-value.series";
import { ValueSeries } from "../../types";

export function transformToValueSeriesArray(sourceArray: DatedValueSeries[]): ValueSeries[] {
  return sourceArray.map(item => ({
    name: item.name,
    data: item.data.map(d => [d.date, d.value])
  }));
}

export function filterByName(sourceArray: ValueSeries[], equipmentName: string): ValueSeries[] {
  return sourceArray.filter(item => item.name === equipmentName);
}
