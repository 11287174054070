import { MachineStatus } from './machine-status.enum';
import { EnergyType } from "./kpi-overview.interface";

export interface TrainOverview {
  compressorType: string;
  trainName?: string;
  equipmentName: string;
  energyConsumptionType: EnergyType | null;
  machineStatus: MachineStatus;
  perfIndicator: number;
  equipmentGasPower: EquipmentGasPower[];
}

export interface EquipmentGasPower {
  equipmentName: string;
  value: number;
}
