<h4 class="accordion-title">Potential influential factors</h4>

<div class="accordion">
  <div class="accordion-item">
    <h4 class="accordion-header" id="power-header">
      <button class="accordion-button collapsed" id="power-btn" type="button" data-bs-toggle="collapse"
              data-bs-target="#power-body" aria-expanded="false" aria-controls="power-body"
              (click)="onToggleGraph($event, COMPRESSOR_GRAPH.POWER)">
        <span class="material-icons-round accordion-header-icon">graphic_eq&nbsp;</span>
        Consumed Power
      </button>
    </h4>
    <div id="power-body" class="accordion-collapse collapse" aria-labelledby="power-header">
      <div class="accordion-body">
        <co2-loading-spinner [isLoading]="powerGraphIsLoading">
          <co2-ui-graph *ngIf="powerGraphInputs" [options]="powerGraphInputs"> </co2-ui-graph>
          <co2-card-error *ngIf="hasPowerGraphLoadingError"
                          (tryAgain)="reloadPowerGraphData.emit()">
          </co2-card-error>
        </co2-loading-spinner>
      </div>
    </div>
  </div>

  <div class="accordion-item" id="asv">
    <h4 class="accordion-header" id="asv-header">
      <button id="asv-btn" class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
              data-bs-target="#asv-body" aria-expanded="false" aria-controls="asv-body"
              (click)="onToggleGraph($event, COMPRESSOR_GRAPH.ASV)">
        <span class="material-icons-round accordion-header-icon">graphic_eq&nbsp;</span>
        ASV
      </button>
    </h4>
    <div id="asv-body" class="accordion-collapse collapse" aria-labelledby="asv-header">
      <div class="accordion-body">
        <co2-loading-spinner [isLoading]="asvGraphIsLoading">
          <co2-ui-graph *ngIf="asvGraphInputs"
                        [options]="asvGraphInputs">
          </co2-ui-graph>
          <co2-card-error *ngIf="hasAsvGraphLoadingError"
                          (tryAgain)="reloadAsvGraphData.emit()">
          </co2-card-error>
        </co2-loading-spinner>
      </div>
    </div>
  </div>

  <div class="accordion-item" id="polytropic">
    <h4 class="accordion-header" id="polytropic-header">
      <button class="accordion-button collapsed" type="button" id="polytropic-btn"
              data-bs-toggle="collapse"
              data-bs-target="#polytropic-body" aria-expanded="false" aria-controls="polytropic-body"
              (click)="onToggleGraph($event, COMPRESSOR_GRAPH.POLYTROPIC)">
        <span class="material-icons-round accordion-header-icon">graphic_eq&nbsp;</span>
        Polytropic Efficiency Degradation
      </button>
    </h4>
    <div id="polytropic-body" class="accordion-collapse collapse" aria-labelledby="polytropic-header">
      <div class="accordion-body">
        <co2-loading-spinner [isLoading]="polytropicGraphIsLoading">
          <co2-ui-graph *ngIf="polytropicGraphInputs"
                        [options]="polytropicGraphInputs">
          </co2-ui-graph>
          <co2-card-error *ngIf="hasPolytropicGraphLoadingError"
                          (tryAgain)="reloadPolytropicGraphData.emit()">
          </co2-card-error>
        </co2-loading-spinner>
      </div>
    </div>
  </div>
</div>
