import { Action, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { GetTurbogenPerformanceDrift } from './actions';
import { ValueSeries } from '../../app/types';

@State<ValueSeries[]>({
  name: 'turbogenPerformanceDrift',
})
@Injectable()
export class TurbogenPerformanceDriftState {
  @Action(GetTurbogenPerformanceDrift)
  getTurbogenPerformanceDrift(context: StateContext<ValueSeries[] | undefined>, { payload }: GetTurbogenPerformanceDrift): void {
    context.setState(payload);
  }
}
