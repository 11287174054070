import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '@environments/environment';
import {CrossPlot,} from '../../types';

@Injectable({
  providedIn: 'root'
})
export class CrossplotService {

  constructor(private http: HttpClient) { }

  getCrossPlot(equipmentType: string, affiliate: string, fpsoName: string, equipmentNames: string[], allEquipmentNames: string[]): Observable<CrossPlot[]> {
    const abEquipmentNames = allEquipmentNames.filter(name => !equipmentNames.includes(name));
    let params = new HttpParams()
      .set('equipment_type', equipmentType)
      .append('equipment_names', equipmentNames.join(','))
      .append('ab_equipment_names', abEquipmentNames.join(','));

    return this.http.get<CrossPlot[]>(
      `${environment.apiUrl}/${affiliate}/${fpsoName}/cross-plots`,
      { params: params }
    );
  }
}
