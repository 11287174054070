import * as moment from 'moment';

export const DATE_FORMAT = 'YYYY-MM-DD';
export const FRENCH_DATE_FORMAT = 'DD/MM/YYYY';
export const FRENCH_DATE_TIME_FORMAT = 'DD/MM/YYYY HH:mm';
export const ALERT_DATE_FORMAT = "YYYY-MM-DD 00:00:00";

export function formatDate(actualDate: Date | string) {
  return (moment(actualDate)).format(DATE_FORMAT);
}
export function formatDateWithFormat(actualDate: Date | string, format: string) {
  return (moment(actualDate)).format(format);
}

export function formatDateFromTo(actualDate: Date | string, from: string, to: string) {
  return (moment(actualDate, from)).format(to);
}

export function formatToDate(actualDate: string, actualFormat: string): Date {
  return (moment(actualDate, actualFormat)).toDate();
}

export function differenceInDays(date1: Date, date2: Date): number {
  let differenceInTime = date1.getTime() - date2.getTime();
  let differenceInDays = differenceInTime / (1000 * 3600 * 24);

  return Math.round(Math.abs(differenceInDays));
}


export function getDatePickerProperties(endDatePicker: Date): DateTimePickerProperties {
  const startDatePicker = new Date(endDatePicker.getFullYear() - 3, endDatePicker.getMonth(), endDatePicker.getDate());
  return {
    startDatePicker,
    endDatePicker
  };
}

export interface DateTimePickerProperties {
  startDatePicker: Date | string;
  endDatePicker: Date | string;
}
