import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiGraphComponent } from './ui-graph.component';
import { FormsModule } from '@angular/forms';
import { NgxEchartsModule } from 'ngx-echarts';

@NgModule({
  declarations: [UiGraphComponent],
  imports: [
    CommonModule,
    FormsModule,
    NgxEchartsModule.forRoot({
        echarts: () => import('echarts'),
      }),
  ],
  exports: [UiGraphComponent]
})
export class UiGraphModule { }
