import {
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { Alert, DataType, GraphLegend, TableHeader } from 'src/modules/app/types';
import { ObservationTableMetadata } from "../../../../types/observation-table-metadata.interface";

@Component({
  selector: 'co2-alert-table',
  templateUrl: './alert-table.component.html',
  styleUrls: ['./alert-table.component.scss']
})
export class AlertTableComponent {
  @Input() alertsTable!: Alert[];
  @Input() isLoading!: boolean | null;
  @Input() hasLoadingError!: boolean | null;
  @Output() reloadData = new EventEmitter();
  @Input() legendList!: GraphLegend[];
  @Output() legendClick = new EventEmitter<GraphLegend>();
  @Output() alertClick = new EventEmitter<Alert>();
  @Input() selectedAlert: Alert | undefined;
  @Input() alertHeaders!: TableHeader[];
  @Input() displayedHeaders!: string[];
  @Input() size: number = 0;

  headers: TableHeader[] = [
    {
      id: 'name',
      label: 'Name',
      dataType: DataType.TEXT,
    },
    {
      id: 'explanation',
      label: 'Explanation',
      dataType: DataType.TEXT,
    }];

  metadataList: ObservationTableMetadata[] = [
    { name: 'Trend', explanation: 'Equipment\'s emissions evolution of over the last 3 years'},
    { name: 'Avoidable emissions',
      explanation: 'Emissions due to equipment\'s performance deviation, updated daily and ' +
        'that represents the avoidable emissions since the start of observation. ' +
        'View detail on tags and formula by clicking on each row.'},
  ]

  displayedModalHeaders: string[] = this.headers.map((header) => header.id);
}
