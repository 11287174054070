export interface Stage {
  equipmentName: string;
  status: string;
  antiSurgeValve: number;
  asvEmissions: number;
  polytropicDegradation: number;
  degradationEmissions: number;
  energyPerfInd: number;
  energyPerfDeviation: number;
  prefixIcon?: string;
  styleClass?: string;
}
