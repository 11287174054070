import { Observations } from '../../app/types';

export class GetAlertsData {
  static readonly type = '[Alerts] Get alerts';

  constructor(public payload: Observations, public resetState: boolean = false) {
  }
}

export class ClearAlertsData {
  static readonly type = '[Alerts] Clear alerts';
}
