import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'displayNA'
})
export class DisplayNaPipe implements PipeTransform {

  transform(value: unknown, defaultValue: string = 'N/A'): unknown {
    return (value === undefined || value === null) ? defaultValue : value;
  }

}
