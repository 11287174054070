import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { LoadingSpinnerModule } from 'src/modules/app/components/loading-spinner/loading-spinner.module';
import { UiGraphModule } from 'src/modules/app/components/ui-graph/ui-graph.module';
import { CardErrorModule } from 'src/modules/app/components/card-error/card-error.module';
import { AlertTableComponent } from './alert-table.component';
import { TableComponent } from 'src/modules/app/components';
import { CdkTableModule } from '@angular/cdk/table';
import { SharedModule } from 'src/modules/app/shared.module';
import { TooltipDirective } from 'src/modules/app/components/table/tooltip-status/tooltip.directive';
import { TableLegendComponent } from 'src/modules/app/components/table-legend/table-legend.component';

@NgModule({
  declarations: [AlertTableComponent, TableComponent, TableLegendComponent],
  imports: [
    CommonModule,
    LoadingSpinnerModule,
    UiGraphModule,
    CardErrorModule,
    SharedModule,
    TooltipDirective,
    CdkTableModule,
    RouterModule,
  ],
  exports: [AlertTableComponent, TableComponent, TableLegendComponent]
})
export class AlertTableModule { }
