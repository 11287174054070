import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { SettingPageComponent } from './setting-page.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { NgxsModule } from '@ngxs/store';
import { globalState } from 'src/modules/statemanagement';
import { environment } from '@environments/environment';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { NgxEchartsModule } from 'ngx-echarts';




@NgModule({
  declarations: [SettingPageComponent],
  imports: [
    CommonModule,
    FormsModule,
    PdfViewerModule,
    RouterModule.forChild([
        { path: 'methodology', component: SettingPageComponent },
        { path: 'baselines', component: SettingPageComponent },
        { path: 'tags', component: SettingPageComponent },
      ]),
      NgxsModule.forRoot(globalState, {
        developmentMode: !environment.production,
        compatibility: {
          strictContentSecurityPolicy: true,
        },
      }),
      NgxsReduxDevtoolsPluginModule.forRoot({
        disabled: environment.production,
      }),
      NgxsLoggerPluginModule.forRoot({
        disabled: true,
      }),
      NgxEchartsModule.forRoot({
        echarts: () => import('echarts'),
      }),
  ],
  exports: [
    SettingPageComponent
  ]
})
export class SettingPageModule { }
