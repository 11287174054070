import { Injectable } from '@angular/core';
import * as localforage from 'localforage';

export interface CacheItem {
  data: any;
  expires?: number;
}

@Injectable({
  providedIn: 'root',
})
export class IndexedDbService {
  private readonly dbName = 'cfr_database';

  constructor() {
    localforage.config({
      driver: localforage.INDEXEDDB,
      name: this.dbName,
      version: 1.0,
    });
  }

  async saveData(key: string, data: any, expiresInHours: number = 1): Promise<void> {
    const expirationTime = Date.now() + expiresInHours * 60 * 60 * 1000;
    const cacheItem: CacheItem = {
      data,
      expires: expirationTime,
    };
    await localforage.setItem(key, cacheItem);
  }

  async getData(key: string): Promise<any> {
    const cacheItem: CacheItem | null = await localforage.getItem(key);

    if (cacheItem && cacheItem.expires && cacheItem.expires > Date.now()) {
      return cacheItem.data;
    } else {
      // Data expired or not available
      return null;
    }
  }

  async clearData(): Promise<void> {
    await localforage.clear();
  }
}
