import {Component} from '@angular/core';
import {TooltipPosition, TooltipTheme} from "./tooltip.enums";
import { NgClass, NgIf } from "@angular/common";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'tooltip',
  templateUrl: './tooltip.component.html',
  standalone: true,
  imports: [
    NgClass,
    NgIf
  ],
  styleUrls: [ './tooltip.component.scss' ]
})
export class TooltipComponent  {
  position: TooltipPosition = TooltipPosition.DEFAULT;
  theme: TooltipTheme = TooltipTheme.DEFAULT;
  tooltip = '';
  left = 0;
  top = 0;
  visible = false;
}
