import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { UnauthorizedContainer } from './unauthorized.container';
import { AuthService } from '../../services';



const routes: Routes = [
    { path: '', component: UnauthorizedContainer }
];

@NgModule({
    declarations: [UnauthorizedContainer],
    imports: [
        CommonModule,
        FormsModule,
        RouterModule.forChild(routes)
    ],
    providers: [AuthService]
})
export class UnauthorizedModule { }
