import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import {
  CompressorIndicator,
  ValueSeries,
} from '../../types';

@Injectable({
  providedIn: 'root'
})
export class CompressorsService {

  constructor(private http: HttpClient) {
  }

  getCompressorsEmissionData(compressorType: string, affiliate:string, fpsoName: string, indicator: CompressorIndicator): Observable<ValueSeries[]> {
    const params = new HttpParams()
      .set('compressor_type', compressorType)
      .append('compressor_indicator_type', indicator);

    return this.http.get<ValueSeries[]>(
      `${environment.apiUrl}/${affiliate}/${fpsoName}/compressors/influential-factors`,
      { params: params }
    );
  }
}
