import { Action, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { GetTotalCo2Yearly } from './actions';
import { IndicatorInfos } from 'src/modules/app/types';

@State<IndicatorInfos>({
  name: 'getTotalCo2Yearly',
  defaults: undefined,
})
@Injectable()
export class TotalCo2YearlyState {
  @Action(GetTotalCo2Yearly)
  getTotalCo2Yearly(context: StateContext<IndicatorInfos | undefined>, { payload }: GetTotalCo2Yearly): void {
    context.setState({
      indicatorName: 'Total avoidable CO2 emissions',
      value: payload?.total,
      secondValue: payload?.totalYTD,
      unit: 'ktCO2/yr',
      secondUnit: 'ktCO2 YTD',
    });
  }
}
