import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export type ToastType = 'success' | 'error';

@Injectable({
  providedIn: 'root'
})

export class ToastService {

  toastSubject = new Subject<{ message: string, type: ToastType, duration: number }>();
  toastState = this.toastSubject.asObservable();

  showToast(message: string, type: ToastType = 'success', duration: number = 3000) {
    this.toastSubject.next({ message, type, duration });
  }
}
