import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class EncryptionService {

  encryptData(data: any): any {
    try {
      return CryptoJS.AES.encrypt(JSON.stringify(data), environment.secretEncryptionKey).toString();
    } catch (e) {
      console.error('Error during encryption', e);
      return null;
    }
  }

  decryptData(data: any): any {
    try {
      const bytes = CryptoJS.AES.decrypt(data, environment.secretEncryptionKey);
      if (bytes && bytes.toString()) {
        return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      }
      return data;
    } catch (e) {
      console.error('Error during decryption', e);
      return data;
    }
  }

  hasAdminRole(data: any): boolean {
    let decryptedRoles = this.decryptData(data);
    let roles = JSON.parse(decryptedRoles ?? '[]');
    return roles.some((role: string) => role.startsWith('ADMIN_'));
  }
}
