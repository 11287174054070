import {
  Component,
  Input,
  OnInit,
  SimpleChanges,
  EventEmitter,
  Output,
  AfterViewInit,
  OnDestroy,
  ChangeDetectorRef, OnChanges
} from '@angular/core';
import { Router } from '@angular/router';
import * as echarts from 'echarts';
import { EChartsOption } from 'echarts';
import { Subscription } from 'rxjs';
import { throttle } from 'lodash';

const THROTTLE_DEFAULT_TIMEOUT = 10;

@Component({
  selector: 'co2-ui-graph',
  templateUrl: './ui-graph.component.html',
  styleUrls: [ './ui-graph.component.scss' ]
})
export class UiGraphComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  @Input() options!: EChartsOption;
  @Input() editMode: boolean = false;
  @Input() isModificationBaselineIsOn: boolean = false;
  @Input() noAvailableData: boolean = false;
  @Output() coordinatesChange = new EventEmitter<{ firstPoint: number[], lastPoint: number[] }>();

  myChart?: echarts.ECharts;
  private initialData: any[] = [];
  modifiedData: any[] = [];
  symbolSize: number = 10;
  isOverview: boolean = false;
  routeSubscription!: Subscription;
  chartDomId: string;

  constructor(private route: Router, private cdr: ChangeDetectorRef) {
    const array = new Uint32Array(1);
    window.crypto.getRandomValues(array); // Compliant for security-sensitive use cases
    this.chartDomId = `chartContainer-${ array[0].toString(36).substring(2, 9) }`;
  }

  ngOnInit(): void {

    this.isOverview = this.route.url.includes('overview');
  }

  ngAfterViewInit(): void {
    if (this.isModificationBaselineIsOn) {
      // Trigger change detection to ensure view is updated
      this.cdr.detectChanges();
      this.initChart();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    setTimeout(() => {
      this.throttledChange(changes);
    }, 10);
  }

  private throttledChange = throttle((changes: SimpleChanges) => {
    if (this.myChart) {
      this.cleanGraphAndInit();
    }
    if (changes['editMode']) {
      this.toggleModify();
      this.updateChart();
    }
    if (changes['options'] && !changes['options'].firstChange) {
      this.updateChart();
    }
  }, THROTTLE_DEFAULT_TIMEOUT);

  initChart(): void {
    // Use setTimeout to ensure the DOM is fully updated before initializing the chart
    setTimeout(() => {
      const chartDom = document.getElementById(this.chartDomId) as HTMLElement;

      if (chartDom) {
        if (this.myChart) {
          this.cleanGraphAndInit()  // Dispose of the previous chart instance if it exists
        }
        this.myChart = echarts.init(chartDom);
        this.configureNewBaseline();
      }
    }, 0); // Timeout to ensure DOM is fully loaded
  }

  private configureNewBaseline() {
    if (this.myChart && this.options) {
      const series = this.options.series as any[];
      const newBaselineSeriesIndex = series.findIndex(s => s.name === 'New Baseline');
      if (newBaselineSeriesIndex !== -1) {
        this.initialData = series[newBaselineSeriesIndex].data;
        this.modifiedData = [ ...this.initialData ];
        this.options.series = series.map((s, idx) => {
          if (idx === newBaselineSeriesIndex) {
            return {
              ...s,
              data: this.modifiedData,
              lineStyle: {
                color: 'green',
                width: 2,
              }
            };
          }
          return s;
        });

        this.myChart.setOption(this.options);
        setTimeout(() => {
          this.updateGraphicElements();
        }, 0);
        window.addEventListener('resize', () => this.updatePosition());
        this.myChart.on('dataZoom', () => this.updatePosition());
      }
    }
  }

  updateChart(): void {
    if (this.myChart && this.options) {

      const chartDom = document.getElementById(this.chartDomId) as HTMLElement;
      if (!chartDom) {
        this.myChart = echarts.init(chartDom);
        this.myChart.setOption(this.options);
      }

      this.updateGraphicElements();
      this.configureNewBaseline();
    }
  }

  toggleModify(): void {
    if (this.myChart && this.options) {
      if (this.editMode) {
        this.resetChart();
        const series = this.options.series as any[];
        const newBaselineSeriesIndex = series.findIndex(s => s.name === 'New Baseline');
        if (newBaselineSeriesIndex !== -1) {
          series[newBaselineSeriesIndex].color = 'red'; // Change line color to red
        }
        this.myChart.setOption(this.options);
      } else {
        this.resetChart();
      }
      this.updateGraphicElements();
    } else {
      console.error('Cannot toggle modify because chart instance is undefined');
    }
  }

  resetChart(): void {
    // Dispose of the current chart instance
    if (this.myChart) {
      this.cleanGraphAndInit();
    }

    // Emit changes if needed
    this.updateChart();
    this.updateGraphicElements();
  }


  updateGraphicElements(): void {
    if (this.myChart) {
      const series = this.options.series as any[];
      const newBaselineSeriesIndex = series.findIndex(s => s.name === 'New Baseline');
      if (newBaselineSeriesIndex !== -1) {
        const graphicElements = this.modifiedData.map((item, dataIndex) => {
          const position = this.myChart?.convertToPixel('grid', item);
          return {
            type: 'circle',
            position: position,
            shape: {
              cx: 0,
              cy: 0,
              r: this.symbolSize / 2,
            },
            invisible: false, // Ensure the elements are visible
            style: {
              fill: this.editMode ? '' : 'none', // Fill circles only when modifiable
            },
            draggable: this.editMode, // Make draggable only in edit mode
            ondrag: (event: any) => {
              const pos = [ event.offsetX, event.offsetY ] as [ number, number ];
              this.onPointDragging(dataIndex, pos);
            },
            onmousemove: () => {
              this.showTooltip(dataIndex);
            },
            onmouseout: () => {
              this.hideTooltip(dataIndex);
            },
            z: 10,
          };
        });

        this.myChart.setOption({
          graphic: graphicElements,
        });
      }
    }
  }


  updatePosition(): void {
    if (this.myChart) {
      const graphicElements = this.modifiedData.map((item) => {
        const position = this.myChart?.convertToPixel('grid', item);
        return {
          position: position,
        };
      });

      this.myChart.setOption({
        graphic: graphicElements,
      });
    }
  }

  showTooltip(dataIndex: number): void {
    if (this.myChart) {
      this.myChart.dispatchAction({
        type: 'showTip',
        seriesIndex: 0,
        dataIndex: dataIndex,
      });
    }
  }

  hideTooltip(dataIndex: number): void {
    if (this.myChart) {
      this.myChart.dispatchAction({
        type: 'hideTip',
      });
    }
  }

  onPointDragging(dataIndex: number, pos: [ number, number ]): void {
    this.throttledDrag(dataIndex, pos);
  }

  throttledDrag = throttle((dataIndex: number, pos: [ number, number ]) => {
    if (this.editMode && this.myChart) {
      this.modifiedData[dataIndex] = this.myChart.convertFromPixel('grid', pos);
      const series = this.options.series as any[];
      const newBaselineSeriesIndex = series.findIndex(s => s.name === 'New Baseline');
      if (newBaselineSeriesIndex !== -1) {
        series[newBaselineSeriesIndex].data = this.modifiedData;
      }
      this.myChart.setOption(this.options, false, true);

      const firstPoint = this.modifiedData[0];
      const lastPoint = this.modifiedData[1];
      this.coordinatesChange.emit({ firstPoint, lastPoint });
    }
  }, THROTTLE_DEFAULT_TIMEOUT);

  cleanGraphAndInit() {
    if (this.myChart) {
      this.myChart.dispose();

      // Reinitialize the chart with the original options
      const chartDom = document.getElementById(this.chartDomId) as HTMLElement;
      if (chartDom) {
        this.myChart = echarts.init(chartDom);
        this.myChart.setOption(this.options);
      }
    }
  }

  downloadImage(): void {
    const chartDom = document.getElementById(this.chartDomId) as HTMLElement;
    this.myChart = echarts.init(chartDom);
    if (this.myChart) {
      const img = this.myChart.getDataURL({
        type: 'png',
        pixelRatio: 2,
        backgroundColor: 'transparent',
      });

      const link = document.createElement('a');
      link.href = img;
      link.download = 'sankey.png';
      link.click();
    }
  }

  ngOnDestroy(): void {
    if (this.myChart) {
      this.myChart.dispose();
    }
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }
  }
}
