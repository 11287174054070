import { Component, OnDestroy, OnInit, HostListener } from '@angular/core';
import { Observable, BehaviorSubject, Subscription, skip } from 'rxjs';
import { AppSandbox } from '../../app.sandbox';
import {
  IndicatorInfos,
  Alert,
  AlertRules,
  GraphLegend,
  LinearChart,
  PointCouple,
  TableHeader,
  Observations
} from '../../types';
import { AppInsightsService } from '../../services/';
import {
  ALERT_HEADERS,
  ALERT_TREND_GRAPH,
  defaultColor,
  INIT_INDEX_ALERT,
  systemColorMap
} from './general-dashboard.container.const';
import { Router } from '@angular/router';
import { DEFAULT_GRAPH_DATE_TIME_FORMAT } from "../../services/common-graphs/common-graphs.const";
import { TooltipComponentOption } from "echarts";
import { tooltipFormatter } from "../../utils";
import { cloneObject } from "../../utils/object-manipulation/object-manipulation";
import { adjustMinMaxYAxisGraphsForAlerting } from "../../utils/graph/graph";
import { SankeyInput } from "../sankey/sankey-input";

const PUMPS_TYPE_SUFFIX = 'PUMPS';

@Component({
  selector: 'co2-general-dashboard',
  templateUrl: './general-dashboard.container.html',
  styleUrls: [ './general-dashboard.container.scss' ]
})
export class GeneralDashboardContainer implements OnInit, OnDestroy {
  activeFpso$: Observable<string> = this.sb.activeFpso$;

  totalCo2YearlyEmission$: Observable<IndicatorInfos> = this.sb.totalCo2YearlyEmission$;
  totalAvoidedCo2$: Observable<IndicatorInfos> = this.sb.totalAvoidedCo2$;
  // TODO add api call when api exists

  energyEfficiency: IndicatorInfos = {
    indicatorName: "Energy Efficiency",
    value: null,
    unit: "%"
  };
  currentEmissions: IndicatorInfos = {
    indicatorName: "Current Emissions",
    value: null,
    unit: "ktCO2/yr"
  };
  lowestAchievableEmissions: IndicatorInfos = {
    indicatorName: "Lowest Achievable Emissions",
    value: null,
    unit: "ktCO2/yr"
  };
  totalCo2YearlyEmissionLoader$: Observable<boolean> = this.sb.totalCo2YearlyEmissionLoader$;

  totalCo2YearlyEmissionError$: Observable<boolean> = this.sb.totalCo2YearlyEmissionError$;
  alertTable$: Observable<Observations> = this.sb.alertTable$.pipe(skip(1));

  alertTableLoader$: Observable<boolean> = this.sb.alertTableLoader$;
  alertTableError$: Observable<boolean> = this.sb.alertTableError$;
  allAlertsLoaded$: BehaviorSubject<boolean> = this.sb.allAlertsLoaded$;
  alertTableLegends: GraphLegend[] = [];
  alertTypeSelected: string[] = [];
  allAlerts!: Alert[];
  alertsSubscription!: Subscription;
  currentIndex: number = INIT_INDEX_ALERT;
  totalSize: number = 0;
  typeChecked: boolean = false;
  noAvailableData: boolean = false;
  selectedAlert: Alert | undefined;
  alertHeaders: TableHeader[] = ALERT_HEADERS;
  displayedHeaders: string[] = ALERT_HEADERS.map((header) => header.id);
  constructor(public sb: AppSandbox,
              private appInsightsService: AppInsightsService,
              private router: Router) {
  }

  sankey!: SankeyInput;

  ngOnInit(): void {
    this.appInsightsService.logPageView('general-dashboard');
    Object.values(AlertRules).forEach(rule => this.alertTableLegends.push({ name: rule, checked: false }));
    this.loadPageData();
    this.subscribeToAlertTable();
  }

  private subscribeToAlertTable(): void {
    this.alertsSubscription = this.alertTable$.subscribe((observations: Observations) => {
      if (!observations?.alerts?.length) return;
      this.allAlerts = observations.alerts;
      this.totalSize = observations.totalSize;
    });
  }

  getAlertTypeSelected(): void {

    const allUnchecked = this.alertTableLegends.every(legend => legend.checked === false);
    const allCheckedSame = this.alertTableLegends.every(legend => legend.checked === true);

    this.alertTypeSelected = this.alertTableLegends
      .filter(legend => allCheckedSame || allUnchecked || legend.checked)
      .map(legend => {
        const keyValue = Object.keys(AlertRules).find(key => AlertRules[key as keyof typeof AlertRules] === legend.name);
        return keyValue?.toLowerCase() ?? '';
      });
  }

  private loadPageData(): void {
    this.getAlertTypeSelected();
    this.sb.loadTotalCo2YearlyEmission();
    this.sb.loadAlerts(INIT_INDEX_ALERT, this.alertTypeSelected);
    this.sb.loadSankey().subscribe(data => {
      if (data) {
        this.sankey = data;
        this.energyEfficiency.value = this.sankey.energyEfficiencyPercent;
        this.currentEmissions.value = this.sankey.currentEmissions;
        this.lowestAchievableEmissions.value = this.sankey.lowestAchievableEmissions;
      }
    });
  }

  loadNewPage(): void {
    if (this.allAlertsLoaded$.getValue()) return;
    this.currentIndex++;
    this.sb.loadAlerts(this.currentIndex, this.alertTypeSelected);
  }

  onLegendClick(legend: GraphLegend): void {

    const clickedLegend = this.alertTableLegends.find(alertLegend => alertLegend.name === legend.name);
    if (!clickedLegend) return;
    clickedLegend.checked = !clickedLegend.checked;

    this.sb.clearAlerts();
    this.getAlertTypeSelected();

      this.allAlertsLoaded$.next(false);
      this.currentIndex = 0;
      this.sb.loadAlerts(this.currentIndex, this.alertTypeSelected, true);
  }

  @HostListener("window:scroll", [ "$event" ])
  onWindowScroll() {
    const scrollPosition = Math.ceil(document.documentElement.scrollTop || document.body.scrollTop) + document.documentElement.offsetHeight;
    const scrollHeight = document.documentElement.scrollHeight;

    if (scrollPosition >= scrollHeight) {
      this.loadNewPage();
    }
  }

  updateSelectedAlert(alert: Alert | undefined): void {
    if (alert) {
      this.adjustTrendGraphForDetailsView(alert);
      const element = document.getElementById("sidePanel");
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "start" });
      }
      this.updateAlertDetails(alert.hourlyTrendData);
    } else {
      this.selectedAlert = alert;
    }
    this.adjustTableDisplayedData(alert);
  }

  private adjustTableDisplayedData(alert: Alert | undefined): void {
    if (alert) {
      this.displayedHeaders = this.displayedHeaders.filter(header => [ 'nameWithType', 'alertStatus', 'trendGraph', 'avoidableEmissions' ].includes(header));
    } else {
      this.displayedHeaders = ALERT_HEADERS.map(header => header.id);
    }
  }

  private adjustTrendGraphForDetailsView(alert: Alert): void {
    this.selectedAlert = {
      ...alert,
      trendGraph: {
        ...alert.trendGraph,
        title: {
          text: '',
          textStyle: {
            fontSize: 14
          }
        },
        grid: {
          top: '40px',
          bottom: '0px',
          left: '25px',
          containLabel: true
        },
        toolbox: {
          feature: {
            dataZoom: {
              filterMode: 'none'
            },
            restore: {},
            saveAsImage: {}
          }
        }
      } as LinearChart
    };
  }

  getAlertStyle(systemType: string): any {
    return systemColorMap.get(systemType) || defaultColor;
  }

  private updateAlertDetails(hourlyData: PointCouple[]): void {
    const graph = cloneObject(ALERT_TREND_GRAPH);
    this.noAvailableData = !hourlyData.length;
    graph.xAxis.axisLabel.formatter = DEFAULT_GRAPH_DATE_TIME_FORMAT;
    graph.xAxis.splitNumber = 2;
    graph.toolbox = {
      feature: {
        dataZoom: {
          filterMode: 'none'
        },
        restore: {},
        saveAsImage: {}
      }
    };
    graph.title = {
      text: 'Last 72h',
      textStyle: {
        fontSize: 14
      }
    };
    graph.grid = {
      top: '40px',
      bottom: '0px',
      left: '25px',
      containLabel: true
    };
    (graph.tooltip as TooltipComponentOption).formatter = tooltipFormatter();
    graph.series = [ {
      name: 'Emissions',
      type: 'line',
      smooth: true,
      areaStyle: {
        color: this.selectedAlert ? this.getAlertStyle(this.selectedAlert.systemType).badgeBackgroundColor : ''
      },
      symbol: 'circle',
      showSymbol: false,
      color: this.selectedAlert ? this.getAlertStyle(this.selectedAlert.systemType).color : '',
      data: hourlyData,
    } ];
    if (this.selectedAlert) {
      this.selectedAlert.hourlyTrendGraph = graph;
      adjustMinMaxYAxisGraphsForAlerting(this.selectedAlert, this.selectedAlert.trendData, hourlyData);
    }
  }

  navigateToDetails(): void {
    const fpso = this.sb.getActiveFpso();
    const affiliate = this.sb.getActiveAffiliate();
    switch (this.selectedAlert?.alertType) {
      case AlertRules.ASV_ALERT:
        this.router.navigate(
          [`/${affiliate}/${fpso}/compressors`], {
            queryParams: {
              'compressor-type': this.selectedAlert.equipmentType
            },
            fragment: 'asv'
          });
        break;

      case AlertRules.POLYTROPIC_EFFICIENCY:
        this.router.navigate(
          [`/${affiliate}/${fpso}/compressors`], {
            queryParams: { 'compressor-type': this.selectedAlert.equipmentType },
            fragment: 'polytropic'
          });
        break;

      case AlertRules.DEVIATION_BASELINE:
        if (this.selectedAlert.systemType === 'COMPRESSOR' || this.selectedAlert.systemType === 'COMPRESSION') {
          this.router.navigate(
            [`/${affiliate}/${fpso}/compressors`], {
              queryParams: { 'compressor-type': this.selectedAlert.equipmentType },
              fragment: 'crossplot'
            });
        }
        else if (this.selectedAlert.systemType === 'TURBOGEN' || this.selectedAlert.equipmentType === 'TURBOGEN') {
          this.router.navigate([`/${affiliate}/${fpso}/power-generation/${this.selectedAlert.equipmentName}`]);
        }
        else if (this.selectedAlert.systemType.indexOf(PUMPS_TYPE_SUFFIX) > 0) {
          this.router.navigate([`/${affiliate}/${fpso}/pumps/${this.selectedAlert.systemType.replace(/\-/gi, "_")}/${this.selectedAlert.equipmentName}`]);
        } else {
          console.error("Error to redirect for DEVIATION_FROM_BASELINE", this.selectedAlert);
        }
        break;

      default:
        console.error("Error to redirect", this.selectedAlert?.alertType, this.selectedAlert);
        break;
    }
  }

  ngOnDestroy(): void {
    this.sb.clearAlerts();
    this.alertsSubscription.unsubscribe();
    this.sb.allAlertsLoaded$.next(false);
  }

  updateSelectedAlertStatus() {
    this.sb.clearAlerts();
    this.currentIndex = 0;
    this.sb.loadAlerts(INIT_INDEX_ALERT, this.alertTypeSelected);
  }
}
