import { Action, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { GetActiveFpso } from './actions';

@State<string>({
  name: 'activeFpso',
  defaults: 'DALIA',
})
@Injectable()
export class ActiveFpsoState {
  @Action(GetActiveFpso)
  getActiveFpso(context: StateContext<string>, { payload }: GetActiveFpso): void {
    context.setState(payload);
  }
}
