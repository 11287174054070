import { Action, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { GetCompressorsAsvData } from './actions';
import { ValueSeries } from '../../app/types';

@State<ValueSeries[]>({
  name: 'compressorsAsvData',
  defaults: [],
})
@Injectable()
export class CompressorsAsvDataState {
  @Action(GetCompressorsAsvData)
  getCompressorsAsvData(context: StateContext<ValueSeries[] | undefined>, { payload }: GetCompressorsAsvData): void {
    context.setState(payload);
  }
}
