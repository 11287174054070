import { Action, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { GetTurbogenPowerTurbines } from './actions';
import { ValueSeries } from '../../app/types';

@State<ValueSeries>({
  name: 'turbogenPowerTurbines',
})
@Injectable()
export class TurbogenPowerTurbinesState {
  @Action(GetTurbogenPowerTurbines)
  getTurbogenPowerTurbines(context: StateContext<ValueSeries | undefined>, { payload }: GetTurbogenPowerTurbines): void {
    context.setState(payload);
  }
}
