import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, skip, Subscription } from 'rxjs';
import { AppSandbox } from '../../app.sandbox';
import { AppInsightsService, PendingRequestsService } from '../../services';
import {
  CompressorIndicator,
  CrossPlot,
  CrossPlotMetadata,
  DatePick,
  DatePicker,
  DEFAULT_SYSTEM_GRAPH_NAME,
  getGraphMetaDataFrom,
  GraphInputs,
  LAST_3_DAYS_GRAPH_TITLE,
  LAST_3_YEARS_GRAPH_TITLE,
  LinearGraph,
  LONG_LEGEND_BOTTOM,
  SHARED_LINE_SERIE_CONFIG,
  TabItem,
  TableHeader,
  ValueSeries,
} from '../../types';
import {
  ASV_GRAPH,
  DETAILS_COMPRESSOR_HEADERS,
  GLOBAL_COMPRESSOR_HEADERS,
  POLYTROPIC_GRAPH,
  POWER_GRAPH,
} from './compressors.const';
import { tooltipFormatter } from '../../utils';
import {
  DateTimePickerProperties,
  getDatePickerProperties
} from "../../utils/date-time-picker/date-time-picker";
import { getStartDateSince3Years } from "../../utils/date-time/date-time";
import { EChartsOption } from "echarts";
import { CommonGraphsService } from "../../services/common-graphs/common-graphs.service";
import { EnergyType, KpiOverview, KpiOverviewByEquipment } from "../../types/kpi-overview.interface";
import { EquipmentResponse } from "../../types/front-config.interface";
import { adjustMinMaxYAxisGraphsForPerformanceDrift, getRandomColor } from "../../utils/graph/graph";
import { formatLastDateInKpiEquipments } from "../../utils/overview-kpis/overview-kpis.utils";

@Component({
  selector: 'co2-compressors',
  templateUrl: './compressors.container.html',
})
export class CompressorsContainer implements OnInit, OnDestroy, AfterViewInit {
  readonly OVERVIEW = 'overview';
  readonly COMPRESSOR_TYPE = "COMPRESSOR";
  subTabs!: TabItem[];
  selectedTab !: string;
  allEquipmentNames!: string[];
  equipmentNamesByType!: Map<string, string[]>;

  crossPlotTitle!: string;
  kpiTitle!: string | null;

  subscriptions: Subscription[] = [];
  getSelectedEquipmentSubscribe!: Subscription;
  getSubTabsSubscribe!: Subscription;
  activatedRouteSubscription!: Subscription;

  selectedEquipment!: EquipmentResponse;

  activeCompressorsType$: Observable<string> = this.sb.activeCompressorsType$;

  COMPRESSOR_INDICATOR = CompressorIndicator;
  compressorsPowerData$: Observable<ValueSeries[]> = this.sb.compressorsPowerData$;
  compressorsPowerDataLoader$: Observable<boolean> = this.sb.compressorsPowerDataLoader$;

  compressorsPowerDataError$: Observable<boolean> = this.sb.compressorsPowerDataError$;
  compressorsAsvData$: Observable<ValueSeries[]> = this.sb.compressorsAsvData$;
  compressorsAsvDataLoader$: Observable<boolean> = this.sb.compressorsAsvDataLoader$;

  compressorsAsvDataError$: Observable<boolean> = this.sb.compressorsAsvDataError$;
  compressorsPolytropicData$: Observable<ValueSeries[]> = this.sb.compressorsPolytropicData$;
  compressorsPolytropicDataLoader$: Observable<boolean> = this.sb.compressorsPolytropicDataLoader$;

  compressorsPolytropicDataError$: Observable<boolean> = this.sb.compressorsPolytropicDataError$;

  compressorsOverviewKpis$: Observable<KpiOverview> = this.sb.compressorsOverviewKpis$;
  compressorCrossPlotData$: Observable<CrossPlot[]> = this.sb.compressorCrossPlot$;
  compressorCrossPlotLoader$: Observable<boolean> = this.sb.compressorCrossPlotLoader$;

  compressorCrossPlotError$: Observable<boolean> = this.sb.compressorCrossPlotError$;
  crossPlots: CrossPlot[] = [];
  crossPlotGraphs: any[] = [];
  crossPlotTitles: string[] = [];
  crossPlotBaselineParams: any[] = [];

  crossPlotMetadata !: CrossPlotMetadata;

  energyType!: EnergyType | string;
  powerGraphInputs!: GraphInputs;
  asvGraphInputs!: GraphInputs;
  polytropicGraphInputs!: GraphInputs;

  datePickerProperties!: DateTimePickerProperties;
  lastDatePicks: DatePicker[] = [{}, {}];
  actualDate = new Date();
  fullOperatingPoints: any[][] = [];
  filteredOperatingPoints: any[][] = [];

  performanceDriftData$: Observable<ValueSeries[]> = this.sb.compressorsPerformanceDrift$.pipe(skip(1));
  performanceDriftCompGraph: EChartsOption[] = [];

  hourlyPerformanceDriftData$: Observable<ValueSeries[]> = this.sb.compressorsHourlyPerformanceDrift$.pipe(skip(1));

  compressorsCombinedPerformanceDriftLoader$: Observable<boolean> = this.sb.combinedCompressorsPerformanceDriftLoader$;
  compressorsCombinedPerformanceDriftError$: Observable<boolean> = this.sb.combinedCompressorsPerformanceDriftError$;

  fullDriftPoints: any;

  globalCompressorHeaders: TableHeader[] = [ ...GLOBAL_COMPRESSOR_HEADERS ];
  globalCompressorDisplayedHeaders: string[] = this.globalCompressorHeaders.map((header) => header.id);

  detailsCompressorHeaders: TableHeader[] = [ ...DETAILS_COMPRESSOR_HEADERS ];
  detailsCompressorDisplayedHeaders: string[] = this.detailsCompressorHeaders.map((header) => header.id);

  globalKPIValues!: KpiOverview;
  detailsKPIValues!: Array<KpiOverviewByEquipment>;
  lastDate: string | undefined;

  protected readonly getStartDateSince3Years = getStartDateSince3Years;

  constructor(
    public sb: AppSandbox,
    private appInsightsService: AppInsightsService,
    private activatedRoute: ActivatedRoute,
    public router: Router,
    private pendingRequestsService: PendingRequestsService,
    private graphsService: CommonGraphsService) {
  }

  ngOnInit(): void {
    this.datePickerProperties = getDatePickerProperties(this.actualDate);
    this.appInsightsService.logPageView("compressors");
    this.activatedRouteSubscription = this.activatedRoute.queryParams.subscribe(params => {

      this.selectedTab = params['compressor-type'];
      this.sb.setActiveCompressorsType(this.selectedTab);

      this.cleanGraphs();

      this.sb.getSelectedEquipment(this.COMPRESSOR_TYPE).subscribe(equipment => this.onSelectEquipment(equipment));
      this.sb.getSubTabs(this.COMPRESSOR_TYPE).subscribe(tabs => this.onGetTabs(tabs));
    });
  }

  private onGetTabs(tabs: TabItem[]) {
    this.equipmentNamesByType = new Map(tabs.map(tab => {
      return [ tab.label, (tab?.subItemName?.length) ? tab.subItemName : [ tab.id ] ];
    }));
    this.allEquipmentNames = tabs.flatMap(i => i.subItemName && i.subItemName.length > 0 ? i.subItemName : [ i.id ]);

    this.subTabs = [ {
      id: 'overview',
      label: this.selectedEquipment.label,
    }, ...tabs.map(tab => {
      tab.id = tab.label;
      return tab;
    }) ];

    this.loadData();
  }

  private onSelectEquipment(equipment: EquipmentResponse) {
    this.selectedEquipment = equipment;
    this.crossPlotTitle = `${ equipment.label } Equipments Baselines`;
    this.kpiTitle = `${ equipment.label } Overview`;
    this.energyType = this.selectedEquipment.subEquipments.filter(sub => sub.label === this.selectedTab)[0]?.energyConsumptionType ?? EnergyType.ELECTRIC;

    this.datePickerProperties = getDatePickerProperties(this.actualDate);

    this.subscriptions = [
      this.compressorsPowerData$
        .subscribe((data: ValueSeries[]) => {
          if (!data) return;

          let energyType = data?.length ? getGraphMetaDataFrom(this.selectedEquipment.graphMetadata, data[0].name)?.yAxisUnit : 'kW';

          let yAxisName: string = energyType === 'kW' ? 'Consumed Power (kW)' : 'Consumed Fuel Gas (Sm3/h)';

          this.powerGraphInputs = POWER_GRAPH;

          if ((POWER_GRAPH['yAxis'] as any)?.name !== yAxisName) {
            (POWER_GRAPH['yAxis'] as any).name = yAxisName;
          }

          this.powerGraphInputs = { ...this.updateGraph(this.powerGraphInputs, data) };
        }),
      this.compressorsAsvData$
        .subscribe((data: ValueSeries[]) => {
          if (!data) return;

          this.asvGraphInputs = ASV_GRAPH;
          this.asvGraphInputs = { ...this.updateGraph(this.asvGraphInputs, data) };
        }),
      this.compressorsPolytropicData$
        .subscribe((data: ValueSeries[]) => {
          if (!data) return;
          this.polytropicGraphInputs = POLYTROPIC_GRAPH;
          this.polytropicGraphInputs = { ...this.updateGraph(this.polytropicGraphInputs, data) };
        }),
      this.compressorCrossPlotData$
        .subscribe((data: CrossPlot[]) => {
          if (!data || !data.length) return;
          this.crossPlotGraphs = [];
          this.crossPlotBaselineParams = [];
          this.crossPlotMetadata = data[0].crossplotMetadata;
          this.crossPlots = [ ...data ].sort(this.sortCrossPlots());

          this.crossPlots.forEach((crossplot, crossplotIndex) => {
            let graphData = this.sb.updateCrossPlotGraph(crossplot,
              { type: 'startDate', value: this.datePickerProperties.startDatePicker },
              this.datePickerProperties.startDatePicker, this.datePickerProperties.endDatePicker,
              this.filteredOperatingPoints, 'Baseline Compressor ')
            if (crossplot.train) {
              this.crossPlotTitles[crossplotIndex] = `Train ${ crossplot.train } Cross-plot`;
            } else {
              this.crossPlotTitles[crossplotIndex] = 'Cross-plot';
            }
            if (graphData) {
              this.crossPlotGraphs[crossplotIndex] = graphData.crossPlotGraph;
              this.crossPlotBaselineParams[crossplotIndex] = graphData.crossPlotBaselineParams;
              this.fullOperatingPoints[crossplotIndex] = graphData.fullOperatingPoints;
            }
          });
        }),
      this.performanceDriftData$.subscribe(
        (data: ValueSeries[]) => {
          if (data) {
            this.performanceDriftCompGraph =
              this.graphsService.updateManyEquipmentsPerformanceDriftGraphs(data, LAST_3_YEARS_GRAPH_TITLE, this.performanceDriftCompGraph, DEFAULT_SYSTEM_GRAPH_NAME, this.selectedEquipment.graphMetadata);

            // Now TypeScript knows that this.performanceDriftGraph is an array
            // @ts-ignore
            this.fullDriftPoints = this.performanceDriftCompGraph[0].series ? this.performanceDriftCompGraph[0].series.map((s => s.data)) : [];
            this.performanceDriftCompGraph[0] = this.sb.filterOperatingPointsDrift({},
              this.datePickerProperties.startDatePicker, this.datePickerProperties.endDatePicker, this.fullDriftPoints, this.performanceDriftCompGraph[0]);
            this.performanceDriftCompGraph = adjustMinMaxYAxisGraphsForPerformanceDrift(this.performanceDriftCompGraph);
          }
        }
      ),
      this.callDriftSubscription(this.hourlyPerformanceDriftData$, LAST_3_DAYS_GRAPH_TITLE),
      this.compressorsOverviewKpis$.subscribe(data => {
        if (!data?.kpiByEquipments || data.kpiByEquipments.length === 0) return;
        this.globalKPIValues = data;
        this.detailsKPIValues = data.kpiByEquipments;
        this.lastDate = formatLastDateInKpiEquipments(data.kpiByEquipments);
      })
    ];
  }

  private sortCrossPlots() {
    return (c1: CrossPlot, c2: CrossPlot) => {
      if (c1.train && c2.train) {
        return c1.train.localeCompare(c2.train);
      }
      return -1;
    }
  }

  private callDriftSubscription(observable: Observable<ValueSeries[]>, title: string) {
    return observable.subscribe(
      (data: ValueSeries[]) => {
        if (data) {
          this.performanceDriftCompGraph =
            this.graphsService.updateManyEquipmentsPerformanceDriftGraphs(data, title, this.performanceDriftCompGraph, DEFAULT_SYSTEM_GRAPH_NAME, this.selectedEquipment.graphMetadata);
          this.performanceDriftCompGraph = adjustMinMaxYAxisGraphsForPerformanceDrift(this.performanceDriftCompGraph);
        }
      }
    );
  }

  public loadData() {
    this.performanceDriftCompGraph = [];
    this.crossPlotGraphs = [];

    this.pendingRequestsService.cancelAllPendingRequests();
    if (this.selectedTab !== this.OVERVIEW) {
      this.sb.loadCompressorCrossPlot(this.allEquipmentNames, this.equipmentNamesByType);
    } else {
      this.sb.loadCompressorsOverviewKpis(this.allEquipmentNames);
    }
    this.sb.loadCompressorsPerformanceDrift(this.COMPRESSOR_TYPE, getStartDateSince3Years(), this.allEquipmentNames, this.equipmentNamesByType);
    this.sb.loadCompressorsHourlyPerformanceDrift(this.COMPRESSOR_TYPE, this.allEquipmentNames, this.equipmentNamesByType);

    this.closeOpenAccordions();

  }

  ngAfterViewInit(): void {
    const fragment = this.activatedRoute.snapshot.fragment;
    if (!fragment) return;
    const element = document.getElementById(fragment);
    if (!element) return;
    element.scrollIntoView();

    if (fragment === 'polytropic' || fragment === 'asv') {
      const btn = document.getElementById(`${ fragment }-btn`);
      btn?.click();
    }
  }

  private getEquipmentConfig(graphValues: ValueSeries): any {
    const isCO2Emission = graphValues.name.startsWith('CO2_Emission');
    const isNormalConsumption = graphValues.name.startsWith('Normal_consumption');

    return {
      color: getRandomColor(),
      lineStyle: { type: isCO2Emission ? 'dashed' : (isNormalConsumption ? 'dotted' : 'solid') },
      yAxisIndex: isCO2Emission ? 1 : 0
    };
  }

  private updateGraph(graph: GraphInputs, data: ValueSeries[]): GraphInputs {
    graph.tooltip.formatter = tooltipFormatter();
    graph.series = [];
    if (data.length > 1 && graph.grid) graph.grid.bottom = LONG_LEGEND_BOTTOM;
    data.forEach((graphValues: ValueSeries) => {
      const graphName = graphValues.name || 'Stage without name';
      const equipmentConfig = getGraphMetaDataFrom(this.selectedEquipment.graphMetadata, graphName) || this.getEquipmentConfig(graphValues);
      const serieOption = {
        name: graphName,
        ...SHARED_LINE_SERIE_CONFIG,
        ...equipmentConfig,
        data: graphValues.data,
        symbol: 'line'
      };
      graph.series.push(serieOption);
    });
    return graph;
  }

  trackByFn(index: number, item: any) {
    return item.id;
  }

  filterDatePick(datePick: DatePick, crossPlotIndex: number): void {
    this.lastDatePicks[crossPlotIndex] = datePick;
    this.crossPlotGraphs[crossPlotIndex] = this.sb.filterOperatingPoints(datePick,
      this.datePickerProperties.startDatePicker, this.datePickerProperties.endDatePicker, this.fullOperatingPoints[crossPlotIndex],
      this.crossPlotGraphs[crossPlotIndex], this.filteredOperatingPoints[crossPlotIndex]);
  }

  onSelectTab(compressorType: string): void {
    this.cleanGraphs();
    const fpso = this.sb.getActiveFpso();
    const affiliate = this.sb.getActiveAffiliate();
    this.router.navigate([`/${affiliate}/${fpso}/compressors`], { queryParams: { 'compressor-type': compressorType } });
  }

  onToggleGraph(graph: LinearGraph): void {
    switch (graph) {
      case LinearGraph.POWER:
        this.sb.loadCompressorsEmissionData(CompressorIndicator.POWER, this.getSelectedSubEquipmentType());
        break;

      case LinearGraph.ASV:
        this.sb.loadCompressorsEmissionData(CompressorIndicator.ASV, this.getSelectedSubEquipmentType());
        break;

      case LinearGraph.POLYTROPIC:
        this.sb.loadCompressorsEmissionData(CompressorIndicator.POLYTROPIC_EFFICIENCY, this.getSelectedSubEquipmentType());
        break;

      default:
        break;
    }
  }

  public getSelectedSubEquipmentType(): string {
    const equipmentType = this.selectedEquipment.subEquipments.filter(e => e.label === this.selectedTab)[0]?.equipmentType;
    return equipmentType || this.selectedTab;
  }

  onChartClick(event: any, index: number): void {
    this.crossPlotGraphs[index] = this.sb.onChartClick(event, this.crossPlotBaselineParams, this.crossPlotGraphs[index], this.crossPlotMetadata) ||
      this.crossPlotGraphs[index];
  }

  private closeOpenAccordions(): void {
    const graphBtnIds = [ 'polytropic-btn', 'power-btn', 'asv-btn' ];
    graphBtnIds.forEach((btnId: string) => {
      const btnElement = document.getElementById(btnId);
      if (btnElement && btnElement.ariaExpanded === 'true') {
        btnElement.click();
      }
    });
  }

  filterDatePickDrift(datePick: DatePick): void {

    this.performanceDriftCompGraph[0] = this.sb.filterOperatingPointsDrift(datePick,
      this.datePickerProperties.startDatePicker, this.datePickerProperties.endDatePicker, this.fullDriftPoints, this.performanceDriftCompGraph[0]);
    this.performanceDriftCompGraph = adjustMinMaxYAxisGraphsForPerformanceDrift(this.performanceDriftCompGraph);
  }

  private cleanGraphs() {
    this.crossPlotGraphs = [];
    this.crossPlotBaselineParams = [];
    this.fullOperatingPoints = [];
    this.performanceDriftCompGraph = [];

    this.sb.resetAllGraphsStates();
    this.cleanSubscriptions();
  }

  private cleanSubscriptions() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
    if (this.getSelectedEquipmentSubscribe) {
      this.getSelectedEquipmentSubscribe.unsubscribe();
    }
    if (this.getSubTabsSubscribe) {
      this.getSubTabsSubscribe.unsubscribe();
    }
  }

  ngOnDestroy(): void {
    this.activatedRouteSubscription.unsubscribe();
    this.cleanSubscriptions();
  }

  showAllDataForCrossplot(show: boolean, i: number) {
    const largePeriod = {startDate: new Date(1999, 1, 1), endDate: new Date() };
    this.crossPlotGraphs[i] = this.sb.filterOperatingPoints(show ? largePeriod : this.lastDatePicks[i],
      this.datePickerProperties.startDatePicker, this.datePickerProperties.endDatePicker, this.fullOperatingPoints[i],
      this.crossPlotGraphs[i], this.filteredOperatingPoints[i]);
  }
}
