import {HttpClient, HttpParams} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IndexedDbService } from '../storage/indexed-db.service';
import { from, lastValueFrom, map, Observable } from "rxjs";
import { environment } from '@environments/environment';
import { EquipmentResponse, FrontConfig } from "../../types/front-config.interface";
import { ROLES_STORAGE_KEY } from "../../containers";
import { Tab } from "../../types/tab.interface";
import { TabItem } from "../../types";
import {EncryptionService} from "../encryption/encryption.service";


@Injectable({
  providedIn: 'root'
})
export class FrontConfigsService {

  decryptedRoles: any;
  roles: string[] = [];

  constructor(public http: HttpClient,
              public indexedDbService: IndexedDbService,
              private encryptionService: EncryptionService) {
    this.decryptedRoles = this.encryptionService.decryptData(localStorage.getItem(ROLES_STORAGE_KEY));
    this.roles = JSON.parse(this.decryptedRoles ?? '[]');
  }

  async getFrontConfigs(roles: string[]): Promise<any> {
    const cacheKey: string = 'front_configs';
    try {
      const cachedData = await this.indexedDbService.getData(cacheKey);

      if (cachedData) {
        return cachedData;
      }
      const params = new HttpParams().set('roles', roles.join(',') || '');
      const apiDataObservable = this.http.get(`${environment.apiUrl}/front-configs`, { params });
      const apiData = await lastValueFrom(apiDataObservable);
      await this.indexedDbService.saveData(cacheKey, apiData, 24);
      return apiData;

    } catch (error) {
      console.error('Error fetching front-config data:', error);
      return null;
    }
  }

  getFpsosByAffiliate(affiliate: string): Observable<string[]> {
    return from(this.getFrontConfigs(this.roles)).pipe(
      map((responses: FrontConfig[]) =>
        responses.filter(response => response.affiliate === affiliate)
          .map(response => response.fpso)
      )
    );
  }

  getSubTabs(affiliate: string, fpso: string, systemType: string): Observable<TabItem[]> {
    return this.getSelectedEquipment(affiliate, fpso, systemType).pipe(
      map(equipment => equipment.subEquipments.map(subEquipment => {
        return {
          id: subEquipment.equipmentName,
          label: subEquipment.label,
          equipmentType: subEquipment.equipmentType,
          subItemName: subEquipment.trains.map(train => train.equipmentName)
        }
      }))
    );
  }

  getSelectedEquipment(affiliate: string, fpso: string, systemType: string): Observable<EquipmentResponse> {
    return from(this.getFrontConfigs(this.roles)).pipe(
      map((responses: FrontConfig[]) => {
        const filteredResponses = responses.filter(response => response.affiliate === affiliate && response.fpso === fpso);
        return filteredResponses.flatMap(response => response.equipments)
          .filter(equipment => (equipment.systemType === systemType))[0];
      })
    );
  }

  getFrontConfig(affiliate: string, fpso: string): Observable<FrontConfig> {
    return from(this.getFrontConfigs(this.roles)).pipe(
      map((responses: FrontConfig[]) => {
        return responses.filter(response => response.affiliate === affiliate && response.fpso === fpso)[0];
      })
    );
  }

  getTabs(affiliate: string, fpso: string): Observable<Tab[]> {
    return from(this.getFrontConfigs(this.roles)).pipe(
      map((responses: FrontConfig[]) => {
        const filteredResponses = responses.filter(response => response.affiliate === affiliate && response.fpso === fpso);
        return [{
          affiliate: affiliate,
          fpso: fpso,
          systems: filteredResponses[0].equipments.map(equipment => {
            return {
              systemName: equipment.label,
              systemType: equipment.systemType,
              equipments: equipment.subEquipments.map(subEquipment => {
                return {
                  equipmentName: subEquipment.equipmentName,
                  equipmentType: subEquipment.equipmentType
                };
              })
            }
          })
        }];
      })
    );
  }
}
