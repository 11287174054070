<div class="card">
  <div class="card-title">
    <span class="material-icons-round card-header-icon">graphic_eq&nbsp;</span>
    CO2 Factor (gCO2/kW.h)
  </div>

  <div class="card-body">
    <co2-ui-graph *ngIf="!isLoading && !hasLoadingError" [options]="co2FactorGraphInputs"></co2-ui-graph>

    <ngx-skeleton-loader *ngIf="isLoading" count="12" appearance="line"></ngx-skeleton-loader>

    <co2-card-error *ngIf="hasLoadingError && !isLoading" (tryAgain)="reloadData.emit()"></co2-card-error>
  </div>
</div>
