import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'replaceDashWithSpace'
})
export class ReplaceDashWithSpacePipe implements PipeTransform {

  transform(value: string): string {
    if(value) return value.replace(/-/g, ' ');
    return value;
  }

}