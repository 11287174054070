import { Component, EventEmitter, Input, Output } from '@angular/core';
import { EChartsOption } from 'echarts';

@Component({
  selector: 'co2-turbogen-co2-factor-graph',
  templateUrl: './turbogen-co2-factor-graph.component.html',
})
export class TurbogenCo2FactorGraphComponent {
  @Input() co2FactorGraphInputs!: EChartsOption;
  @Input() isLoading!: boolean | null;
  @Input() hasLoadingError!: boolean | null;
  @Output() reloadData = new EventEmitter();
}
