<div class="card">
  <div class="card-title">
    <span class="material-icons-round card-header-icon">graphic_eq&nbsp;</span>
    Performance Drift
  </div>

  <div class="card-body">
    <div class="co2-graph-body" *ngIf="!isLoading && !hasLoadingError">
      <co2-date-selection
      [startDate]="startDatePicker"
      [endDate]="endDatePicker"
      [quickSelection]=true
      [enableLimitationMinDate]="true"
      (datePick)="datePick.emit($event)"
></co2-date-selection>
<div class="co2-graph">
      <co2-ui-graph *ngFor="let performanceDriftGraph of performanceDriftGraphInputs; let i = index"
        [options]="performanceDriftGraph" [noAvailableData]="isNoDataAvailable(performanceDriftGraph)"></co2-ui-graph>
      </div>
    </div>

    <ngx-skeleton-loader *ngIf="isLoading" count="6" appearance="line"></ngx-skeleton-loader>

    <co2-card-error *ngIf="hasLoadingError && !isLoading" (tryAgain)="reloadData.emit()"></co2-card-error>
  </div>
</div>
