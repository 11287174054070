import { Action, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { GetTotalAvoidedCo2Yearly } from './actions';
import { IndicatorInfos } from 'src/modules/app/types';

@State<IndicatorInfos>({
  name: 'getTotalAvoidedCo2',
  defaults: undefined,
})
@Injectable()
export class TotalAvoidedCo2State {
  @Action(GetTotalAvoidedCo2Yearly)
  getTotalCo2Yearly(context: StateContext<IndicatorInfos>, { payload }: GetTotalAvoidedCo2Yearly): void {
    context.setState({
      indicatorName: 'Total Avoided CO2',
      value: payload?.totalAvoidedYTD,
      unit: 'ktCO2 YTD',
    });
  }
}
