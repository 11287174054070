import { Component, Input, Output, EventEmitter } from '@angular/core';
import { DataType, MachineStatus, TableHeader } from '../../types';
import {
  MAX_CO2_FACTOR_ROW_VALUE,
  MAX_POLYTROPIC_AVERAGE_ROW_VALUE,
  MAX_POLYTROPIC_GOOD_ROW_VALUE,
  MIN_CO2_FACTOR_ROW_VALUE,
} from '../../containers';
import { defaultColor, systemColorMap} from '../../containers/general-dashboard/general-dashboard.container.const';
import {
  differenceInDays,
  formatToDate, FRENCH_DATE_FORMAT
} from "../../utils/date-time-picker/date-time-picker";
import { TooltipPosition, TooltipTheme } from "./tooltip-status/tooltip.enums";

@Component({
  selector: 'co2-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent {
  @Input() headers!: TableHeader[];
  @Input() rows!: any[] | null;
  @Input() displayedHeaders!: string[];
  @Input() disabled?: boolean | null;
  @Input() clickableRows?: boolean;
  @Input() selectedRow?: any;
  @Output() rowClick = new EventEmitter();

  protected readonly TooltipPosition = TooltipPosition;
  protected readonly TooltipTheme = TooltipTheme;

  DATA_TYPE = DataType;
  readonly DEFAULT_BADGE_BACKGROUND_COLOR = '#EBEFF0';
  show = false;

  onRowClick(row: any): void {
    if (!this.clickableRows || row === this.selectedRow) return;
    this.rowClick.emit(row);
  }

  getDataClass(headerId: string, data: any): string {
    let dataClass = '';
    switch (headerId) {
      case 'polytropicDegradation':
        if (data > MAX_POLYTROPIC_AVERAGE_ROW_VALUE) {
          dataClass = 'red bold';
        }
        if (data > MAX_POLYTROPIC_GOOD_ROW_VALUE && data <= MAX_POLYTROPIC_AVERAGE_ROW_VALUE) {
          dataClass = 'orange bold';
        }
        break;

      case 'co2Factor':
        if (data < MIN_CO2_FACTOR_ROW_VALUE || data > MAX_CO2_FACTOR_ROW_VALUE) {
          dataClass = 'red bold';
        }
        break;

      case 'machineStatus':
        if (data === MachineStatus.ON) {
          dataClass = 'green bold';
        } else {
          dataClass = 'red bold';
        }
        break;

      case 'equipmentStatus':
        if (data === MachineStatus.ON) {
          dataClass = 'green bold';
        } else {
          dataClass = 'red bold';
        }
        break;

      case 'date':
        dataClass = 'orange';
        break;

      case 'trendGraph':
        dataClass = 'large-height';
        break;

      case 'hourlyTrendGraph':
        dataClass = 'large-height';
        break;

      default:
        break;
    }
    return dataClass;
  }

  getBadgeColors(row: any): any {
    if (row.systemType !== undefined) {
      return systemColorMap.get(row.systemType) || defaultColor;
    } else {
      return { badgeBackgroundColor: this.DEFAULT_BADGE_BACKGROUND_COLOR };
    }
  }

  rowIsSelected(row: any): boolean {
    if (this.selectedRow && row.alertType) {
      return (row.alertType === this.selectedRow.alertType && row.equipmentName === this.selectedRow.equipmentName);
    }

    return this.selectedRow === row;
  }

  daysFromNow(dateString: string) {
    const date = formatToDate(dateString, FRENCH_DATE_FORMAT);
    const now = new Date();
    return differenceInDays(date, now);
  }
}

