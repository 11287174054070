import { Action, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { Affiliate } from 'src/modules/app/types';
import { GetActiveAffiliate } from './actions';

@State<Affiliate>({
  name: 'activeAffiliate',
  defaults: Affiliate.ANGOLA,
})
@Injectable()
export class ActiveAffiliateState {
  @Action(GetActiveAffiliate)
  getActiveAffiliate(context: StateContext<Affiliate>, { payload }: GetActiveAffiliate): void {
    context.setState(payload);
  }
}
