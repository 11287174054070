import { Component, Input, Output, EventEmitter } from '@angular/core';
import { EChartsOption } from 'echarts';
import { BarChart } from 'src/modules/app/types';

@Component({
  selector: 'co2-power-data',
  templateUrl: './power-data.component.html'
})
export class PowerDataComponent {
  @Input() powerTurbinesGraphInputs!: EChartsOption[];
  @Input() isLoadingPowerTurbines!: boolean | null;
  @Input() hasPowerTurbinesLoadingError!: boolean | null;
  @Output() reloadPowerTurbinesData = new EventEmitter();

  @Input() powerReserveGraph!: BarChart;
  @Input() isLoadingPowerReserve!: boolean | null;
  @Input() hasPowerReserveLoadingError!: boolean | null;
  @Output() reloadPowerReserveData = new EventEmitter();

  @Input() fpsoConfigs!: string[];
  @Input() activeFpsoConfig!: string | null;
  @Output() fpsoConfigChange = new EventEmitter();
}
