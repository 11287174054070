import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { map, Observable } from "rxjs";
import { ResponseMessage } from "../../types/response-message.interface";


@Injectable({
  providedIn: 'root'
})
export class FileUploadService {

  constructor(private http: HttpClient) {}

  uploadFile(affiliate: string, file: File, apiUrl: string): Observable<ResponseMessage> {
    const formData: FormData = new FormData();
    formData.append('file', file);
    const headers = new HttpHeaders({
      enctype: 'multipart/form-data',
    });
    const params = new HttpParams().set('affiliate', affiliate);
    return this.http
      .post(apiUrl, formData, { params, headers: headers, observe: 'response', responseType: 'json' })
      .pipe(map((response) => response.body as ResponseMessage));
  }

  downloadFile(affiliate: string, fpso: string, apiUrl: string): Observable<any> {
    const params = new HttpParams().set('affiliate', affiliate).append('fpso', fpso);
    return this.http
      .get(apiUrl, {params, responseType: 'blob'});
  }
}
