import { HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';
import { SubscriptionUrl } from '../../types';

@Injectable({
  providedIn: 'root'
})
export class PendingRequestsService {
  private pendingRequests: SubscriptionUrl[] = [];

  cancelAllPendingRequests(): void {
    this.pendingRequests.forEach((subscription: SubscriptionUrl) => subscription.subscription.unsubscribe());
    this.pendingRequests = [];
  }

  handleNewRequest(request: HttpRequest<unknown>, subscription: Subscription): void {
    this.pendingRequests.push({ subscription: subscription, url: request.url });
  }
}
