import { Action, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { GetTurbogenCo2Factor } from './actions';
import { ValueSeries } from '../../app/types';

@State<ValueSeries[]>({
  name: 'turbogenCo2Factor',
  defaults: [],
})
@Injectable()
export class TurbogenCo2FactorState {
  @Action(GetTurbogenCo2Factor)
  getTurbogenCo2Factor(context: StateContext<ValueSeries[]>, { payload }: GetTurbogenCo2Factor): void {
    context.setState(payload);
  }
}
