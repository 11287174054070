import { Action, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { GetTurbogenHourlyPowerTurbines } from './actions';
import { ValueSeries } from '../../app/types';

@State<ValueSeries>({
  name: 'turbogenHourlyPowerTurbines',
})
@Injectable()
export class TurbogenHourlyPowerTurbinesState {
  @Action(GetTurbogenHourlyPowerTurbines)
  getTurbogenHourlyPowerTurbines(context: StateContext<ValueSeries | undefined>, { payload }: GetTurbogenHourlyPowerTurbines): void {
    context.setState(payload);
  }
}
