import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EquipmentsService {

  constructor(private http: HttpClient) { }

  listEquipments(affiliate: string, fpso: string): Observable<any> {
    const params = new HttpParams().set('affiliate', affiliate).append('fpso', fpso);
    return this.http.get(`${environment.apiUrl}/admin-configs`, { params });
  }

  downloadFile(fpso: string): Observable<any> {
    const params = new HttpParams().set('fpso', fpso);
    return this.http.get(`${environment.apiUrl}/admin-configs/kz/download`, {params, responseType: 'blob', observe: 'response'})
  }

  updateConfig(config:any): Observable<any>  {
    return this.http.put(`${environment.apiUrl}/admin-configs/update`, config);
  }
}
