import { Action, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { GetCompressorsPolytropicData } from './actions';
import {TrainSeries, ValueSeries} from '../../app/types';

@State<TrainSeries[]>({
  name: 'compressorsPolytropicData',
  defaults: [],
})
@Injectable()
export class CompressorsPolytropicDataState {
  @Action(GetCompressorsPolytropicData)
  getCompressorsPolytropicData(context: StateContext<ValueSeries[] | undefined>, { payload }: GetCompressorsPolytropicData): void {
    context.setState(payload);
  }
}
