<div class="card">
  <div class="card-title">
    <span class="material-icons-round card-header-icon">graphic_eq&nbsp;</span>
    Power data
  </div>

  <div class="card-body">
    <co2-power-turbines [powerTurbinesGraphInputs]="powerTurbinesGraphInputs"
                        [isLoading]="isLoadingPowerTurbines"
                        [hasLoadingError]="hasPowerTurbinesLoadingError"
                        (reloadData)="reloadPowerTurbinesData.emit()">
    </co2-power-turbines>

    <co2-turbogen-power-reserve *ngIf="fpsoConfigs && powerReserveGraph"
                                [powerReserveGraph]="powerReserveGraph"
                                [isLoadingPowerReserve]="isLoadingPowerReserve"
                                [hasPowerReserveLoadingError]="hasPowerReserveLoadingError"
                                (reloadPowerReserveData)="reloadPowerReserveData.emit()"
                                [fpsoConfigs]="fpsoConfigs"
                                [activeFpsoConfig]="activeFpsoConfig"
                                (fpsoConfigChange)="fpsoConfigChange.emit($event)">
    </co2-turbogen-power-reserve>
  </div>
</div>
