import { TooltipComponentFormatterCallbackParams } from 'echarts';
import { CrossPlotMetadata, getGraphMetaDataFrom } from "../../types";
import { formatDateOrDateTime } from "../date-time/date-time";
import { TopLevelFormatterParams } from "echarts/types/dist/shared";

export function tooltipFormatter(): (params: TooltipComponentFormatterCallbackParams) => string {
  return (params) => {
    let tooltipHtml = '';
    if (Array.isArray(params)) {
      //distinct params.name
      const distinctParams = params.filter((param, index, self) =>
        index === self.findIndex((t) => (
          t.seriesName === param.seriesName
        ))
      );
      distinctParams.forEach(param => {
        if (Array.isArray(param.value)) {
          const numValue = param.value[1] ? Math.round(+param.value[1] * 100) / 100 : "No Data";
          tooltipHtml += `<p style="display: flex; justify-content: space-between; align-items: center"><span style="margin-right: 1rem">${ param.marker } ${ param.seriesName }</span> <span style="font-weight: bold">${ numValue }</span></p>`;
        }
      });

      if (Array.isArray(params[0].data)) {
        let dateValue = params[0].data[0] as string;
        let formattedDate = formatDateOrDateTime(dateValue);
        tooltipHtml += `<p style="text-align: center">${ formattedDate }</p>`;
      }
    }
    return tooltipHtml;
  };
}

export function tooltipDatesFormatter(dates: string[]): (params: any) => string {
  return (params) => {
    const index = params.dataIndex;
    return `Date: ${ formatDateOrDateTime(dates[index]) }`;
  }
}

export function crossplotTooltipFormatter(metadata: CrossPlotMetadata): (params: any) => string {
  return (params) => {
    let tooltipHtml = '';
    const xUnit = metadata?.xAxisProperties?.unit || 'Default xAxisUnit';
    const xAxisLabel = metadata?.xAxisProperties?.label || 'Default xAxisLabel';
    const yUnit = metadata?.yAxisProperties?.unit || 'Default yAxisUnit';
    const yAxisLabel = metadata?.yAxisProperties?.label || 'Default yAxisLabel';

    tooltipHtml += `<p><span style="margin-right: 1rem">${ xAxisLabel }</span> <span style="margin-right: 4px"><b>${ params.data[0].toFixed(2) }</b></span>${ xUnit }</p>`;
    tooltipHtml += `<p><span style="margin-right: 1rem">${ yAxisLabel }</span> <span style="margin-right: 4px"><b>${ params.data[1].toFixed(2) }</b></span>${ yUnit }</p>`;
    return tooltipHtml;
  }
}

export function crossPlotSpanFormatter(gapValue: string, optimumValue: number, metadata: CrossPlotMetadata): (params: any) => string {
  return (params) => {
    let tooltipHtml = '';
    const xUnit = metadata?.xAxisProperties?.unit || 'Default xAxisUnit';
    const yUnit = metadata?.yAxisProperties?.unit || 'Default yAxisUnit';

    tooltipHtml += ` Delivered power      {values|${ params.data.xAxis } ${ xUnit }}`;

    if (yUnit === 'Sm3/h') {
      tooltipHtml += `\n\n\ Fuel gas span           {gapValue|${ gapValue } %}`;
      tooltipHtml += `\n\n\ Fuel gas optimum   {values|${ optimumValue.toFixed(2) } ${ yUnit }}`;
    }

    return tooltipHtml;
  }
}

export function performanceDriftFormatter(graphMetadata: { [x: string]: any; }[]): (params: TopLevelFormatterParams) => string {
  return (params) => {
    let tooltipHtml = '';
    if (Array.isArray(params)) {
      params.forEach(param => {
        if (Array.isArray(param.value)) {
          const unit = param.seriesName ? getGraphMetaDataFrom(graphMetadata, param.seriesName).yAxisUnit : "";
          const numValue = param.value[1] ? Math.round(+param.value[1] * 100) / 100 : "No Data";
          tooltipHtml += `<p style="display: flex; justify-content: space-between; align-items: center"><span style="margin-right: 1rem">${ param.marker } ${ param.seriesName }</span> <span style="font-weight: bold">${ numValue }</span>${ unit }</p>`;
        }
      });

      if (Array.isArray(params[0].data)) {
        let dateValue = params[0].data[0] as string;
        let formattedDate = formatDateOrDateTime(dateValue);
        tooltipHtml += `<p style="text-align: center">${ formattedDate }</p>`;
      }
    }
    return tooltipHtml;
  };
}
