import { Action, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { GetCompressorsOverviewKpis } from './actions';
import { KpiOverview } from "../../app/types/kpi-overview.interface";

@State<any>({
  name: 'compressorsOverviewKpis'
})

@Injectable()
export class CompressorsOverviewKpisState {
  @Action(GetCompressorsOverviewKpis)
  getCompressorsOverviewKpis(context: StateContext<KpiOverview | undefined>, { payload }: GetCompressorsOverviewKpis): void {
    context.setState(payload);
  }
}
