<div class="card sub-card w-50">
  <div class="card-title">
    Turbines Power Reserves

    <span *ngIf="fpsoConfigs" class="form-switch-labels form-check form-switch form-switch-mini">
      <label class="form-check-label" for="fpsoConfigSwitch"
             [ngClass]="{'selected': fpsoConfigs && activeFpsoConfig === fpsoConfigs[0]}">{{fpsoConfigs[0]}}</label>
      <input class="form-check-input switch-mini" type="checkbox" id="fpsoConfigSwitch"
             (click)="onToggleSwitch($event)">
      <label class="form-check-label" for="fpsoConfigSwitch"
             [ngClass]="{'selected': fpsoConfigs && activeFpsoConfig === fpsoConfigs[1]}">{{fpsoConfigs[1]}}</label>
    </span>
  </div>

  <div class="card-body">
    <div *ngIf="!isLoadingPowerReserve && !hasPowerReserveLoadingError && powerReserveGraph">
      <co2-ui-graph [options]="powerReserveGraph"></co2-ui-graph>
    </div>

    <ngx-skeleton-loader *ngIf="isLoadingPowerReserve" count="8" appearance="line"></ngx-skeleton-loader>

    <co2-card-error *ngIf="hasPowerReserveLoadingError && !isLoadingPowerReserve"
                    (tryAgain)="reloadPowerReserveData.emit()">
    </co2-card-error>
  </div>
</div>
