import { Alert, LinearChart, PointCouple } from "../../types";
import { cloneObject } from "../object-manipulation/object-manipulation";
import { EChartsOption, LineSeriesOption } from "echarts";
import { YAXisOption } from "echarts/types/dist/shared";

export interface MinMaxByAxis {
  index: number,
  minMax: {
    min: number,
    max: number
  }
}

export function adjustMinMaxYAxisGraphsForAlerting(alert: Alert, trendData: PointCouple[], hourlyTrendData: PointCouple[]) {
  if (alert && alert.trendGraph && alert.hourlyTrendGraph) {
    const calculatedMinMax = [{
      index: 0, minMax: calculateMinMax(trendData, hourlyTrendData)
    }];
    const adjustedGraphs = adjustMinMaxYAxisGraphs(alert.trendGraph, alert.hourlyTrendGraph, calculatedMinMax);
    alert.trendGraph = adjustedGraphs.trendGraph as LinearChart;
    alert.hourlyTrendGraph = adjustedGraphs.hourlyTrendGraph as LinearChart;
  }
}

export function adjustMinMaxYAxisGraphsForPerformanceDrift(performanceDriftGraphList: EChartsOption[]): EChartsOption[] {
  if (performanceDriftGraphList?.length > 1 && isSeriesExists(performanceDriftGraphList)) {
    const trendGraph = performanceDriftGraphList[0];
    const hourlyTrendGraph = performanceDriftGraphList[1];
    const trendData = getDataFromGraph(trendGraph.series as Array<LineSeriesOption>);
    const hourlyTrendData = getDataFromGraph(hourlyTrendGraph.series as Array<LineSeriesOption>);
    if (Array.isArray(trendGraph.yAxis) && Array.isArray(hourlyTrendGraph.yAxis)) {
      const calculatedMinMax: MinMaxByAxis[] = calculateMinMaxForMultipleSeries(trendGraph, hourlyTrendGraph);
      const adjustedGraphs = adjustMinMaxYAxisGraphs(trendGraph, hourlyTrendGraph, calculatedMinMax);
      return [ adjustedGraphs.trendGraph, adjustedGraphs.hourlyTrendGraph ];
    } else {
      const calculatedMinMax = [ {
        index: 0, minMax: calculateMinMax(trendData, hourlyTrendData)
      } ];
      const adjustedGraphs = adjustMinMaxYAxisGraphs(trendGraph, hourlyTrendGraph, calculatedMinMax);
      return [ adjustedGraphs.trendGraph, adjustedGraphs.hourlyTrendGraph ];
    }
  }
  return performanceDriftGraphList;
}

function calculateMinMaxForMultipleSeries(trendGraph: EChartsOption, hourlyTrendGraph: EChartsOption): MinMaxByAxis[] {
  return (trendGraph.yAxis as Array<YAXisOption>).map((y: YAXisOption, i: number) => i).map(yAxisIndex => {
    const hourlySeries = (trendGraph.series as Array<LineSeriesOption>).filter(s => s.yAxisIndex === yAxisIndex);
    const dailySeries = (hourlyTrendGraph.series as Array<LineSeriesOption>).filter(s => s.yAxisIndex === yAxisIndex);
    const trendData = getDataFromGraph(hourlySeries);
    const hourlyTrendData = getDataFromGraph(dailySeries);
    return {
      index: yAxisIndex, minMax: calculateMinMax(trendData, hourlyTrendData)
    };
  });
}

function calculateMinMax(trendData: PointCouple[], hourlyTrendData: PointCouple[]) {
  const data = [ ...trendData, ...hourlyTrendData ].map(item => item[1]).filter(item => item || item === 0);

  return {
    min: Math.floor(Math.min(...data)),
    max: Math.ceil(Math.max(...data))
  };
}


function adjustMinMaxYAxisGraphs(trendGraph: EChartsOption, hourlyTrendGraph: EChartsOption, calculatedMinMax: MinMaxByAxis[]) {
  const adjustYAxis = (graph: EChartsOption) => {
    if (Array.isArray(graph.yAxis)) {
      graph.yAxis = graph.yAxis.map((y: YAXisOption, index: number) => {
        const minMax = calculatedMinMax.filter(c => c.index === index)[0].minMax;
        return { ...y, min: minMax.min, max: minMax.max, alignTicks: false }
      });
    } else {
      const minMax = calculatedMinMax[0].minMax;
      graph.yAxis = { ...graph.yAxis, min: minMax.min, max: minMax.max };
    }
    return graph;
  }

  const trendGraphClone = adjustYAxis(cloneObject(trendGraph));
  const hourlyTrendGraphClone = adjustYAxis(cloneObject(hourlyTrendGraph));

  return {
    trendGraph: trendGraphClone,
    hourlyTrendGraph: hourlyTrendGraphClone
  }
}

function isSeriesExists(graphList: EChartsOption[]) {
  return graphList.map(g => !!g.series).reduce((a, b) => a && b, true);
}

function getDataFromGraph(series: Array<LineSeriesOption>): PointCouple[] {
  return series.map(s => s.data as PointCouple[]).reduce((a, b) => a.concat(b), []);
}

export function getRandomColor(): string {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    const randomValue = window.crypto.getRandomValues(new Uint8Array(1))[0];
    color += letters[randomValue % 16];
  }
  return color;
}
