import { Component } from '@angular/core';
import { MsalBroadcastService } from '@azure/msal-angular';

@Component({
  selector: 'co2-root',
  templateUrl: './app.container.html'
})
export class AppContainer {
  // msalBroadcastService is needed to fix issue: https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/4176
  constructor(private msalbroadcastservice: MsalBroadcastService) {
  }
}
