<nav class="navbar navbar-expand-sm flex-column">
  <div class="container-fluid d-flex w-100 header-with-tabs-for-desktop">
    <!-- TotalEnergies Logo -->
    <a class="navbar-brand text-primary" [routerLink]="getRouterLink('DASHBOARD')">
      <img src="../../../../../../assets/mycfr_logo.svg" alt="My CFR logo">
    </a>
    <div class="w-100">
      <div class="container-fluid d-flex pt-2 pe-0">
        <!-- Application Name -->
        <span class="navbar-app-name d-flex align-items-center" [routerLink]="getRouterLink('DASHBOARD')">MyCFR</span>
        <!-- Navbar content -->
        <ul class="navbar-nav ms-auto d-flex flex-row">
          <li class="nav-item dropdown d-flex">
            <span class="navbar-text site-name">ANGOLA-B17</span>
            <button class="btn btn-secondary dropdown-toggle" type="button" id="activeFpso" data-bs-toggle="dropdown"
              aria-expanded="false">
              {{activeFpso$ | async}}
            </button>
            <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="activeFpso">
              <li *ngFor="let fpso of FPSO_LIST" (click)="onSelectFpso(fpso)" class="dropdown-item fpso"
                [ngClass]="{'is-active': isActive(fpso)}">{{fpso}}
              </li>
            </ul>
          </li>
          <li class="nav-item ms-md-4 dropdown">
            <button class="btn btn-tertiary" type="button" id="settings" data-bs-toggle="dropdown"
              aria-expanded="false">
              <span class="material-icons" aria-hidden="true">menu_book</span>
            </button>
            <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="settings">
              <li class="dropdown-item fpso" [routerLink]="getSettingPage('methodology')">Methodology
              </li>
              <li class="dropdown-item fpso" [routerLink]="getSettingPage('baselines')">Description baseline
              </li>
              <li class="dropdown-item fpso" [routerLink]="getSettingPage('tags')">PI tags
              </li>
            </ul>
          </li>
          <li class="nav-item">
            <div title="Page config" class="btn btn-tertiary" type="button" id="settings"  [routerLink]="getadminPage()"
            >
            <span class="material-icons" aria-hidden="true">settings</span>
          </div>
          </li>
          <li class="nav-item ms-md-4">
            <span class="navbar-text text-uppercase">{{userName$ | async}}</span>
            <button class="btn btn-outline-primary btn-circle" title="Logout" (click)="logout()">
              <span class="material-icons-outlined">logout</span>
            </button>
          </li>
        </ul>
      </div>
      <hr class="separator">
      <div class="container-fluid" *ngIf="headerTabs">
        <!-- Tab menu -->

        <ul class="nav nav-tabs">
          <ng-container *ngFor="let system of headerTabs">
            <ng-container *ngIf="system.systemType === 'PUMPS'">
              <li class="nav-item dropdown d-flex" (mouseenter)="showDropdown = true"
                (mouseleave)="showDropdown = false" >
                <button class="nav-link dropdown-toggle" [ngClass]="{'active': showDropdown || (getRouterLink(system?.systemType) === '' && router.url.includes('/pumps'))}"
                aria-expanded="false">
                  PUMPS {{getRouterLink(system?.systemType)}}
                </button>
                <ul class="dropdown-menu dropdown-menu-end menu-item" [class.show]="showDropdown"
                  aria-labelledby="pumpsDropdown" (mouseenter)="showDropdown = true"
                  (mouseleave)="showDropdown = false">
                  <li *ngFor="let system of pumpsSystems" class="dropdown-item fpso">
                    <button class="nav-link" [routerLink]="getRouterLink(system?.systemType)">
                      {{ getSystemTypeValue(system) }}
                    </button>
                  </li>
                </ul>
              </li>
            </ng-container>
            <ng-container *ngIf="system.systemType !== 'PUMPS'">
              <li class="nav-item" role="presentation" [routerLink]="getRouterLink(system?.systemType)"
                [queryParams]="getQueryParams(system?.systemType)">
                <button class="nav-link" data-bs-toggle="tab" data-bs-target="#navbarTab" type="button" role="tab"
                  [co2StartsWithRouterLink]="getRouterLink(system?.systemType)" aria-controls="power-generation">
                  {{ getSystemTypeValue(system) }}
                </button>
              </li>
            </ng-container>
          </ng-container>
        </ul>

      </div>
    </div>
    <a class="navbar-brand text-primary">
      <img src="../../../../../../assets/total_energies_logo.png" alt="Total Energies logo">
    </a>
  </div>
</nav>
