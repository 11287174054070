import { Action, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { CrossPlot } from '../../app/types';
import {PumpFeedCrossPlot} from "./actions";

@State<CrossPlot[]>({
  name: 'pumpCrossPlot',
})
@Injectable()
export class PumpCrossPlotState {
  @Action(PumpFeedCrossPlot)
  getPumpCrossPlot(context: StateContext<CrossPlot[] | undefined>, { payload }: PumpFeedCrossPlot): void {
    context.setState(payload);
  }
}
