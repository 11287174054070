import { Action, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { GetTurbogenPowerReserve } from './actions';
import { TurbogenPowerReserve } from '../../app/types';

@State<TurbogenPowerReserve>({
  name: 'turbogenPowerReserve',
  defaults: {} as TurbogenPowerReserve,
})
@Injectable()
export class TurbogenPowerReserveState {
  @Action(GetTurbogenPowerReserve)
  getTurbogenPowerReserve(context: StateContext<TurbogenPowerReserve | undefined>, { payload }: GetTurbogenPowerReserve): void {
    context.setState(payload);
  }
}
