import { EChartsOption } from 'echarts';
import {
  DEFAULT_YAXIS_INPUTS,
  DEFAULT_LINEAR_GRAPH,
  DEFAULT_XAXIS,
  BarChart,
  LinearChart,
  TableHeader,
  DataType,
  DEFAULT_LEGEND
} from '../../types';
import { tooltipFormatter } from '../../utils';
import { getRandomColor } from "../../utils/graph/graph";

export const POWER_RESERVE_GRAPH: BarChart = {
  silent: true,
  xAxis: {
    data: [''],
    axisLine: { show: false },
  },
  yAxis: {
    axisLine: { show: true },
    axisTick: { show: true }
  },
  series: [
    {
      name: 'Power Gen Load',
      type: 'bar',
      stack: 'one',
      barWidth: 200,
      itemStyle: {
        color: 'rgba(138, 100, 188, 0.7)',
        borderRadius: 4
      },
      label: {
        show: true,
        position: 'inside',
        rich: {
          subInfo: {
            fontWeight: 400,
            fontSize: 12,
            padding: [10, 0, 0, 0]
          }
        },
        fontWeight: 700,
        fontSize: 14,
        color: '#050505'
      },
    },
    {
      name: 'Power Reserve',
      type: 'bar',
      stack: 'one',
      barWidth: 200,
      itemStyle: {
        color: 'rgba(223, 2, 161, 0.5)',
        borderRadius: 4
      },
      label: {
        show: true,
        position: 'inside',
        rich: {
          subInfo: {
            fontWeight: 400,
            fontSize: 12,
            padding: [10, 0, 0, 0]
          }
        },
        fontWeight: 700,
        fontSize: 14,
        color: '#050505'
      },
    },
  ]
};

export const CO2_FACTOR_GRAPH: LinearChart = {
  ...DEFAULT_LINEAR_GRAPH,
  yAxis: { ...DEFAULT_YAXIS_INPUTS, name: 'gCO2/kW.h' },
  xAxis: { ...DEFAULT_XAXIS,axisLabel:{formatter: '{dd}/{MM}/{yyyy}'}, name: 'Time' },
  legend: { ...DEFAULT_LEGEND },
  grid: {
    left: 70,
    bottom: '23%',
  },
  tooltip: {
    trigger: 'axis',
    padding: [15, 15, 0, 15],
    borderRadius: 8,
    formatter: tooltipFormatter()
  },
};

export const GLOBAL_POWER_HEADERS: TableHeader[] = [
  {
    id: 'runningTurbines',
    label: 'Running turbines',
    dataType: DataType.TEXT,
  },
  {
    id: 'energyConsumption',
    label: 'Fuel Gas Consumption',
    dataType: DataType.TEXT,
    suffixText: 'kSm3/d',
  },
  {
    id: 'avgGhgEmissions',
    label: 'Average GHG Emissions',
    dataType: DataType.TEXT,
    suffixText: 'ktCO2/year',
    subHeader: '(Fuel gas only)'
  },
  {
    id: 'estimatedMaxPower',
    label: 'Estimated Max Power',
    dataType: DataType.TEXT,
    suffixText: 'kW',
  },
  {
    id: 'currentLoad',
    label: 'Current Load',
    dataType: DataType.TEXT,
    suffixText: 'kW',
  },
  {
    id: 'loadPercentage',
    label: 'Load %',
    dataType: DataType.TEXT,
    suffixText: '%',
  },
  {
    id: 'powerReserve',
    label: 'Power Reserve',
    dataType: DataType.TEXT,
    suffixText: 'kW',
  },
];

export const DETAILS_POWER_HEADERS: TableHeader[] = [
  {
    id: 'machineStatus',
    label: 'Status',
    dataType: DataType.TEXT,
  },
  {
    id: 'turbineName',
    label: 'Turbine',
    dataType: DataType.TEXT,
  },
  {
    id: 'energyConsumption',
    label: 'Fuel Gas Consumption',
    dataType: DataType.TEXT,
    suffixText: 'kSm3/d',
  },
  {
    id: 'avgGhgEmissions',
    label: 'Average GHG Emissions',
    dataType: DataType.TEXT,
    suffixText: 'ktCO2/year',
    subHeader: '(Fuel gas only)'
  },
  {
    id: 'estimatedMaxPower',
    label: 'Estimated Max Power',
    dataType: DataType.TEXT,
    suffixText: 'kW',
  },
  {
    id: 'currentLoad',
    label: 'Current Load',
    dataType: DataType.TEXT,
    suffixText: 'kW',
  },
  {
    id: 'loadPercentage',
    label: 'Load %',
    dataType: DataType.TEXT,
    suffixText: '%',
  },
  {
    id: 'powerReserve',
    label: 'Power Reserve',
    dataType: DataType.TEXT,
    suffixText: 'kW',
  },
];

export type TurbogenSeries = { name: string, color: string, type: string };

export function generateColors(numSeries: number): string[] {
  return Array.from({length: numSeries}, () => getRandomColor());
}

export function generateTurbinesSeries(colors: string[]): TurbogenSeries[] {
  return colors.map((color, i) => ({
    name: `${i} TG ON`,
    color: color,
    type: 'line'
  }));
}

export const POWER_TURBINES_GRAPH: EChartsOption = {
  ...DEFAULT_LINEAR_GRAPH,
  title: {
    textStyle: {
      fontSize: 14
    }
  },
  toolbox: {
    right: '10%',
    feature: {
      dataZoom: {},
      restore: {},
      saveAsImage: {}
    }
  },
  legend: {
    ...DEFAULT_LEGEND,
    show: true,
    selectedMode: false,
    itemHeight: 2,
    left: 'right',
    icon: 'rect',
  },
  grid: {
    left: 70,
    bottom: '23%',
  },
  visualMap: {
    show: false,
    dimension: 0,
    pieces: []
  },
  yAxis: { ...DEFAULT_YAXIS_INPUTS, name: 'kW' },
  xAxis: { ...DEFAULT_XAXIS, type: 'category' }
};

export const RunningTurbinesColorMap = new Map<number, string>([
  [0, 'black'],
  [1, '#8C9294'],
  [2, '#009EB3'],
  [3, '#AD1457'],
]);

export const GLOBAL_TURBOGEN_KPI_HEADERS: TableHeader[] = [
  {
    id: 'consumedPowerKm3d',
    label: 'Fuel Gas Consumption',
    dataType: DataType.TEXT,
    suffixText: 'kSm3/d',
  },
  {
    id: 'avgGhgEmissions',
    label: 'Average GHG Emissions',
    dataType: DataType.TEXT,
    suffixText: 'ktCO2/year',
    subHeader: '(Fuel gas only)'
  },
  {
    id: 'estimatedMaxPower',
    label: 'Estimated Max Power',
    dataType: DataType.TEXT,
    suffixText: 'kW',
  },
  {
    id: 'currentLoad',
    label: 'Current Load',
    dataType: DataType.TEXT,
    suffixText: 'kW',
  },
  {
    id: 'loadPercentage',
    label: 'Load %',
    dataType: DataType.TEXT,
    suffixText: '%',
  },
  {
    id: 'powerReserve',
    label: 'Power Reserve',
    dataType: DataType.TEXT,
    suffixText: 'kW',
  }
];


export const DETAILS_TURBOGEN_KPI_HEADERS: TableHeader[] = [
  {
    id: 'equipmentStatus',
    label: 'Status',
    dataType: DataType.TEXT,
  },
  {
    id: 'equipmentName',
    label: 'Turbine',
    dataType: DataType.TEXT
  },
  {
    id: 'consumedPowerKm3d',
    label: 'Fuel Gas Consumption',
    dataType: DataType.TEXT,
    suffixText: 'kSm3/d'
  },
  {
    id: 'avgGhgEmissions',
    label: 'Average GHG Emissions',
    dataType: DataType.TEXT,
    suffixText: 'ktCO2/year',
    subHeader: '(Fuel gas only)'
  },
  {
    id: 'estimatedMaxPower',
    label: 'Estimated Max Power',
    dataType: DataType.TEXT,
    suffixText: 'kW',
  },
  {
    id: 'currentLoad',
    label: 'Current Load',
    dataType: DataType.TEXT,
    suffixText: 'kW',
  },
  {
    id: 'loadPercentage',
    label: 'Load %',
    dataType: DataType.TEXT,
    suffixText: '%',
  },
  {
    id: 'powerReserve',
    label: 'Power Reserve',
    dataType: DataType.TEXT,
    suffixText: 'kW',
  }
];
