import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DisplayNaPipe } from './pipes';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { ReplaceDashWithSpacePipe } from './pipes/replace-dash-with-space/replace-dash-with-space.pipe';

@NgModule({
  declarations: [DisplayNaPipe,ReplaceDashWithSpacePipe],
  imports: [
    CommonModule,
    MatIconModule,
    MatSelectModule,
    MatButtonModule,
    MatExpansionModule,
  ],
  exports: [DisplayNaPipe,ReplaceDashWithSpacePipe]
})
export class SharedModule { }
