import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { EChartsOption } from "echarts";
import { buildSankeyLabelFormatter, SANKEY_GRAPH } from "./sankey.component.const";
import { cloneObject } from "../../utils/object-manipulation/object-manipulation";
import { SankeyInput } from "./sankey-input";
import {
  ELECTRICAL_POWER_OPTIMUM_KW_UNIT,
  FUEL_GAS_OPTIMUM_KTCO2_YEAR_UNIT,
} from "../../types";
import { UiGraphComponent } from '../../components';

@Component({
  selector: 'co2-sankey',
  templateUrl: './sankey.component.html',
  styleUrls: [ './sankey.component.scss' ]
})
export class SankeyComponent {
  public options!: EChartsOption;
  public kwSelected = false;

  _sankey!: SankeyInput;

  @ViewChild(UiGraphComponent) UiGraphComponent!: UiGraphComponent;

  @Input()
  set sankey(value: SankeyInput) {
    if (value) {
      this._sankey = value;
      this.updateSankey();
    }
  }

  get sankey() {
    return this._sankey;
  }

  handleSankeyExport() {
    if (this.UiGraphComponent) {
      this.UiGraphComponent.downloadImage(); // Call method in Co2GraphComponent
    }
  }

  onChange(event: Event) {
    const target = event.target as HTMLInputElement;
    this.kwSelected = target?.checked;
    this.updateSankey();
  }

  updateSankey() {
    const graph = cloneObject(SANKEY_GRAPH);
    graph.series.links = this.kwSelected ? this.sankey.sankeyElectric : this.sankey.sankeyFuelGas;
    graph.series.data = this.sankey.data;
    graph.series.label = {
      formatter: buildSankeyLabelFormatter(this.kwSelected ? ELECTRICAL_POWER_OPTIMUM_KW_UNIT : FUEL_GAS_OPTIMUM_KTCO2_YEAR_UNIT)
    }
    this.options = graph;
    console.log("------------>");
    console.log(JSON.stringify(this.options));
  }
}
