import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'co2-configuration',
  templateUrl: './configuration.component.html',
  styleUrls: ['./configuration.component.scss']
})
export class ConfigurationComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {
  }
}
