import { Action, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { GetActiveCompressorsType } from './actions';

@State<string>({
  name: 'activeCompressorsType',
  defaults: undefined,
})
@Injectable()
export class ActiveCompressorsTypeState {
  @Action(GetActiveCompressorsType)
  getActiveCompressorsType(context: StateContext<string>, { payload }: GetActiveCompressorsType): void {
    context.setState(payload);
  }
}
