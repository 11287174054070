import { Action, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { GetActiveFpsoConfig } from './actions';

@State<string>({
  name: 'activeFpsoConfig',
  defaults: ''
})
@Injectable()
export class ActiveFpsoConfigState {
  @Action(GetActiveFpsoConfig)
  getActiveFpsoConfig(context: StateContext<string>, { payload }: GetActiveFpsoConfig): void {
    context.setState(payload);
  }
}
