<div *ngIf="isOpen" (keydown)="closeModal($event)" (click)="closeModal($event)" class="popup">
    <div class="popup-content" (keydown)="stopPropagation($event)" (click)="stopPropagation($event)">
        <div class="popup__header">
            <div class="popup__bloc">
                <div class="popup__icon">
                    <span class="material-icons">{{iconTitle}}</span>
                </div>
                <div class="popup__title">{{title}}</div>
            </div>
            <div class="popup__close">
                <span class="material-icons" (keydown)="closeModal($event)" (click)="closeModal($event)">close</span>
            </div>
        </div>
        <div class="popup__body">
            <div *ngIf="title !== 'Delete message' && title !== 'Closing discussion'" class="form-group">
                <label for="inputForm1" class="form-label"><span class="form-mandatory">*</span> Message</label>
                <textarea *ngIf="messageData && title === 'Edit message'; else create" class="form-control popup__textarea"
                    [(ngModel)]="messageData.content" maxlength="500" id="textareaInput" rows="3"></textarea>
                <ng-template #create>
                    <textarea class="form-control popup__textarea" [(ngModel)]="newMessageContent" id="textareaInput"
                    maxlength="500" rows="3"></textarea>
                </ng-template>
                <p *ngIf="messageData.content && title === 'Edit message' " class="popup__caracters"> {{messageData?.content?.length}} / 500</p>
                <p *ngIf="newMessageContent && title === 'New message' " class="popup__caracters"> {{newMessageContent.length}} / 500</p>
                <p *ngIf="!messageData.content && title === 'Edit message'" class="popup__caracters"> 0 / 500</p>

                <p *ngIf="newMessageContent.length < 1 && title === 'New message'" class="popup__caracters"> 0 / 500</p>
                  
                  <form *ngIf="!fileToUpload" class="upload-file">
                    <input id="upload-input2" type="file" name="fileUpload" (change)="onFileSelected($event)">
                  
                    <label for="upload-input2" class="upload-label-bg">
                      <span class="material-icons-outlined me-1">insert_drive_file</span>
                      <div>
                        <span class="d-block lead mb-2">Click here to upload a file <br> or drag’n drop it here.</span>
                        <span>Handled formats: .xls, .xlsx, .pdf</span>
                      </div>
                    </label>
                  </form>
                  <button  *ngIf="fileToUpload" type="button" class="btn btn-secondary"><span class="material-icons md-24">
                    attach_file
                    </span> {{fileName}} <span class="material-icons md-24"   tabindex="0" 
                    role="button" 
                    aria-label="Delete file" 
                    (click)="deleteFile()" 
                    (keydown)="onKeyDown($event)" >
                    close
                    </span></button>
            </div>
        </div>
        <h4>{{principalContent}}</h4>
        <p>{{content}}</p>
        <div class="popup__buttons">
            <button type="button" class="btn btn-secondary" (click)="closeModal($event)">Cancel</button>
            <button *ngIf="title === 'Delete message'" type="button" class="btn btn-primary"
                (click)="editMessage(messageData.content,false)">{{button}}</button>
            <button *ngIf="messageData && title === 'Edit message'; else add" type="button" class="btn btn-primary"
                (click)="editMessage(messageData.content,true)">{{button}}</button>
            <ng-template #add>
                <button *ngIf="title !== 'Delete message' && title !== 'Closing discussion' && !newDiscussion" type="button" class="btn btn-primary"
                    (click)="addMessage( currentUser)">{{button}}</button>
              <button *ngIf="title === 'Closing discussion'" type="button" class="btn btn-primary"
                    (click)="closeDiscussion()">{{button}}</button>
              <button *ngIf="title !== 'Delete message' && newDiscussion" type="button" class="btn btn-primary"
                    (click)="addNewDiscussion(currentUser)">{{button}}</button>
            </ng-template>
        </div>
    </div>
</div>
