import { Action, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { GetSeaWaterLiftHourlyPerformanceDrift } from './actions';
import { ValueSeries } from '../../app/types';

@State<ValueSeries[]>({
  name: 'seaWaterLiftHourlyPerformanceDrift',
})
@Injectable()
export class SeaWaterLiftHourlyPerformanceDriftState {
  @Action(GetSeaWaterLiftHourlyPerformanceDrift)
  getSeaWaterLiftHourlyPerformanceDrift(context: StateContext<ValueSeries[] | undefined>, { payload }: GetSeaWaterLiftHourlyPerformanceDrift): void {
    context.setState(payload);
  }
}
