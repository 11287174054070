import { Action, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { GetWaterInjectionPerformanceDrift } from './actions';
import { ValueSeries } from '../../app/types';

@State<ValueSeries[]>({
  name: 'waterInjectionPerformanceDrift',
})
@Injectable()
export class WaterInjectionPerformanceDriftState {
  @Action(GetWaterInjectionPerformanceDrift)
  getWaterInjectionPerformanceDrift(context: StateContext<ValueSeries[] | undefined>, { payload }: GetWaterInjectionPerformanceDrift): void {
    context.setState(payload);
  }
}
