export enum AlertRules {
  POLYTROPIC_EFFICIENCY = 'Polytropic efficiency degradation',
  DEVIATION_BASELINE = 'Deviation in performance',
  ASV_ALERT = 'Anti surge valve position',
  PRESSURE_LOSS = 'Pressure loss',
  WASTED_ENERGY = 'Wasted Energy'
}

export enum AlertStatus {
  NEW = "NEW",
  COMMENTED = "COMMENTED",
  CLOSED = "CLOSED"
}

export const ALL_ALERT_STATUS_METADATA = {
  [AlertStatus.NEW]: {
    statusClass: "status__open",
    icon: "circle",
    iconClass: "status__open__icon",
    label: "New",
    labelClass: "status__open__label",
    dateColumn: "date",
  },
  [AlertStatus.COMMENTED]: {
    statusClass: "status__commented",
    icon: "circle",
    iconClass: "status__commented__icon",
    label: "Commented",
    labelClass: "status__commented__label"
  },
  [AlertStatus.CLOSED]: {
    statusClass: "status__closed",
    icon: "check_circle",
    iconClass: "status__closed__icon",
    label: "Closed",
    labelClass: "status__closed__label"
  }
};

export const ALL_SYSTEM_TYPES_GROUPS: { [key: string]: string } = {
  ['WATER_INJECTION_PUMPS_GROUP']: 'Water injection pumps group'
}
