export type PointCouple = [string, number, number?];

export interface ValueSeries {
  name: string;
  data: PointCouple[];
}

export interface DatedValue {
  date: string;
  value: number;
}

export function toPointCouple(datedValue: DatedValue): PointCouple {
  return [datedValue.date,datedValue.value];
}
