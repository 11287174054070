import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Renderer2,
  ViewEncapsulation
} from '@angular/core';
import { Observable } from 'rxjs';
import { AppSandbox } from '../../../../app.sandbox';
import { Alert, AlertStatus } from '../../../../types';
import { DiscussionService } from '../../../../services/discussions/discussion.service';
import { Store } from '@ngxs/store';
import { ActiveAffiliateState, ActiveFpsoState } from 'src/modules/statemanagement';
import { downloadFileFromHttpResponse } from "../../../../utils/api/api";

@Component({
  selector: 'co2-discussions',
  templateUrl: './discussions.component.html',
  styleUrls: [ './discussions.component.scss' ],
  encapsulation: ViewEncapsulation.Emulated
})
export class DiscussionsComponent implements OnInit {
  _selectedAlert!: Alert;

  @Input()
  set selectedAlert(alert: Alert) {
    if (alert) {
      this._selectedAlert = alert;
      this.alertStatus = alert.alertStatus ? alert.alertStatus : AlertStatus.NEW;
      this.listDiscussions();
    }
  }

  get selectedAlert() {
    return this._selectedAlert;
  }

  @Output() updateSelectedAlert = new EventEmitter<void>();
  modalOpen: boolean = false;

  deleteTitle: string = "Delete message";
  closingDiscussionTitle: string = "Closing discussion";
  addTitle: string = "New message";
  editTitle: string = "Edit message";
  deleteContent: string = "You are about to delete the message. After this action, it will be permanently deleted.Are you sure you want to continue?"
  deletButton = "Delete";
  saveButton = "Save";
  validateButton = "Validate";
  saveModificationButton = "Save Modification";
  modalTitle: string = "";
  modalContent: string = "";
  modalPrincipalContent: string = "";
  modalButton: string = "";
  iconTitle: string = "";
  lastDiscussion: any = null;
  closedDiscussions: any = [];
  alert: any | undefined;
  currentUser: any;
  user: any;
  userName$: Observable<string> = this.sb.userName$;
  equipmentName: string = '';
  messageData: any = {};
  lastUpdatedDate: any;
  newDiscussion: boolean = false;

  alertStatus: AlertStatus = AlertStatus.NEW;
  protected readonly AlertStatus = AlertStatus;

  constructor(public sb: AppSandbox, private discussionService: DiscussionService, private elementRef: ElementRef, private renderer: Renderer2, private store: Store) {
  }

  ngOnInit(): void {
    this.currentUser = this.sb.loadUserName();
    this.user = this.userName$.subscribe((data) => {
      this.currentUser = data;
    });
    this.listenClickDocument();
  }

  private listDiscussions() {
    this.clearDiscussion();
    this.discussionService.listDiscussions(this.getAffiliate(), this.getFpso(), this.selectedAlert.id)
      .subscribe((data) => {
        data.forEach((discussion: any) => {
          discussion.messages.sort((a: any, b: any) => {
            // Sort in descending order based on updatedAt date
            return new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime();
          });
        });
        this.setDiscussion(data);
      });
    this.listenClickDocument();
  }


  private listenClickDocument() {
    this.renderer.listen('document', 'click', (event: MouseEvent) => {
      if (!this.isClickInside(event) && this.lastDiscussion) {
        this.closeAllActiveEdits(this.lastDiscussion.messages);
      }
    });
  }

  reloadDiscussions() {
    this.listDiscussions();
  }


  openModal(value: number, messageId?: string) {
    switch (value) {
      case 1:
        this.modalTitle = this.addTitle;
        this.modalButton = this.saveButton;
        this.modalContent = "";
        this.modalOpen = true;
        this.iconTitle = "comment"
        this.messageData = {};
        this.newDiscussion = false;

        break;

      case 2:
        this.modalTitle = this.editTitle;
        this.modalButton = this.saveModificationButton;
        this.modalContent = "";
        this.modalOpen = true;
        this.iconTitle = "comment";
        this.messageData = messageId;
        this.newDiscussion = false;
        break;

      case 3:
        this.modalButton = this.deletButton;
        this.modalTitle = this.deleteTitle;
        this.modalContent = this.deleteContent;
        this.modalOpen = true;
        this.iconTitle = "";
        this.messageData = messageId;
        this.newDiscussion = false;
        break;

      case 4:
        this.modalTitle = this.addTitle;
        this.modalButton = this.saveButton;
        this.modalContent = "";
        this.modalOpen = true;
        this.iconTitle = "comment"
        this.messageData = {};
        this.newDiscussion = true;
        break;

      case 5:
        this.modalButton = this.validateButton;
        this.modalTitle = this.closingDiscussionTitle;
        this.modalPrincipalContent = "Are you sure you want to validate this choice ?";
        this.modalContent = "It won’t be possible to add any comments on the current discussion";
        this.modalOpen = true;
        this.iconTitle = "";
        this.messageData = {};
        this.newDiscussion = false;
        break;

      default:
        break;
    }
  }

  isClickInside(event: MouseEvent): boolean {
    const element = document.querySelector('.user__actions__list');
    if (element) {
      return element.contains(event.target as Node);
    }
    return false;
  }

  closeModal() {
    this.modalOpen = false;
    this.listDiscussions();
  }
  toggleActiveEdit(event: MouseEvent|KeyboardEvent, discussionMessage: any) {
    discussionMessage.activeEdit = !discussionMessage.activeEdit;

    event.stopPropagation();
  }

  closeAllActiveEdits(messages: any) {
    for (const message of messages) {
      message.activeEdit = false;
    }
  }

  updateStatus(status: AlertStatus) {
    if (status && this.alertStatus !== status) {
      this.alertStatus = status;
      this.selectedAlert.alertStatus = status;
      this.updateSelectedAlert.emit();
    }
  }

  displayEmptyDiscussion(): boolean {
    return this.isEmptyDiscussion() || (!this.lastDiscussion && this.closedDiscussions.length > 0 && this.alertStatus === AlertStatus.NEW);
  }

  getAffiliate() {
    return this.store.selectSnapshot(ActiveAffiliateState);
  }

  getFpso() {
    return this.store.selectSnapshot(ActiveFpsoState);
  }

  downloadFile (discussionId: string, messageId: string, fileId: string) {
    this.discussionService.downloadFile(this.getAffiliate(), this.getFpso(),this.selectedAlert.id, discussionId, messageId, fileId).subscribe({
      next: (response) => downloadFileFromHttpResponse(response),
      error: (err) => console.error('File download failed:', err)
    });
}

  private setDiscussion(data: any) {
    const dataNotClosed = data.filter((d: { status: AlertStatus; }) => d.status !== AlertStatus.CLOSED);
    if (dataNotClosed.length > 0) {
      this.lastDiscussion = dataNotClosed[0];
      if (this.lastDiscussion && this.lastDiscussion.messages.length) {
        this.lastUpdatedDate = this.lastDiscussion.messages[0].updatedAt;
      }
    }
    this.closedDiscussions = data.filter((d: { status: AlertStatus; }) => d.status === AlertStatus.CLOSED);
  }

  private clearDiscussion() {
    this.lastDiscussion = null;
    this.closedDiscussions = [];
  }

  private isEmptyDiscussion() {
    return this.lastDiscussion === null && this.closedDiscussions.length === 0;
  }
}
