import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { catchError, lastValueFrom, of } from 'rxjs';
import { TotalYearlyCo2Emission } from '../../types/total-yearly-co2-emission';
import { IndexedDbService } from '../storage/indexed-db.service';

@Injectable({
  providedIn: 'root'
})
export class GeneralDashboardService {

  constructor(private http: HttpClient, private indexedDbService: IndexedDbService) { }

  async getTotalYearlyCo2Emission(affiliate: string, fpsoName: string) {

    const cacheKey = 'total-co2-emission';
    try {
      const cachedData = await this.indexedDbService.getData(cacheKey);

      if (cachedData) {
        return cachedData;
      } else {
        const apiData = await lastValueFrom(
          this.http.get<TotalYearlyCo2Emission>(`${environment.apiUrl}/${affiliate}/${fpsoName}/general-dashboard/overview/total-co2`).pipe(
            catchError((error:any) => {
              console.error('Error fetching data from API', error);
              return of(null);
            })
          )
        );
        await this.indexedDbService.saveData(cacheKey, apiData);

        return apiData;
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      return null;
    }
  }
}
