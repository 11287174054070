import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from '@environments/environment';
import { map, Observable, throwError, of} from 'rxjs';
import { catchError } from 'rxjs/operators';
import {ValueSeries} from '../../types';
import {DatedValueSeries} from "../../types/dated-value.series";
import {transformToValueSeriesArray} from "../../utils/transformers/response-transformers";
import {getUserTimezone} from "../../utils";

@Injectable({
  providedIn: 'root'
})
export class PerformanceDriftService {

  constructor(private http: HttpClient) {
  }

  getDailyPerformanceDrift(affiliate: string, fpsoName: string, startDate: string, systemType: string,
                           equipmentName: string | null)
    : Observable<ValueSeries[]> {
    let params = new HttpParams();
    params = params.append('start_date', startDate);
    let url = `${environment.apiUrl}/${affiliate}/${fpsoName}/performance-drift/daily/${systemType}/${equipmentName}`;

    return this.http.get<DatedValueSeries[]>(url, { params: params }).pipe(map(transformToValueSeriesArray));
  }

  getHourlyPerformanceDrift(affiliate: string, fpsoName: string, systemType: string, equipmentName: string | null) {
    let url = `${environment.apiUrl}/${affiliate}/${fpsoName}/performance-drift/hourly/${systemType}/${equipmentName}`;

    let params = new HttpParams().set('timezone', getUserTimezone());

    return this.http.get<DatedValueSeries[]>(url, { params: params }).pipe(
      map(transformToValueSeriesArray),
      catchError((error): Observable<ValueSeries[]> => {
        if (error.status === 417) {
          console.warn('Expectation Failed:', error);
          return of([] as ValueSeries[]); // Return an empty array if the error status is 417
        } else {
          return throwError(error); // Propagate other errors
        }
      })
    );
  }
}
