import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import {
  TurbogenPowerReserve,
  ValueSeries,
} from '../../types';

@Injectable({
  providedIn: 'root'
})
export class TurbogeneratorsService {

  constructor(private http: HttpClient) { }

  getTurbogenPowerReserve(affiliate: string, fpsoName: string, fpsoConfig: string): Observable<TurbogenPowerReserve> {
    const params = new HttpParams().append('fpso_config', fpsoConfig);

    return this.http.get<TurbogenPowerReserve>(
      `${environment.apiUrl}/${affiliate}/${fpsoName}/turbogenerators/power-reserve`,
      { params: params }
    );
  }

  getTurbogenCo2FactorData(affiliate: string, fpsoName: string): Observable<ValueSeries[]> {

    return this.http.get<ValueSeries[]>(
      `${environment.apiUrl}/${affiliate}/${fpsoName}/turbogenerators/co2-factor-data`
    );
  }

  getTurbogenPowerTurbines(affiliate: string, fpsoName: string, startDate: string): Observable<ValueSeries> {
    let params = new HttpParams().set('start_date', startDate)

    return this.http.get<ValueSeries>(
      `${environment.apiUrl}/${affiliate}/${fpsoName}/turbogenerators/power-running-turbines`,
      { params: params }
    );
  }

  getTurbogenHourlyPowerTurbines(affiliate: string, fpsoName: string, startDate: string): Observable<ValueSeries> {
    let params = new HttpParams().set('start_date', startDate)

    return this.http.get<ValueSeries>(
      `${environment.apiUrl}/${affiliate}/${fpsoName}/turbogenerators/hourly-power-running-turbines`,
      { params: params }
    );
  }
}
