import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TabItem } from 'src/modules/app/types';

@Component({
  selector: 'co2-tab-selector',
  templateUrl: './tab-selector.component.html',
  styleUrls: ['./tab-selector.component.scss']
})
export class TabSelectorComponent {
  @Input() tabsList!: TabItem[];
  @Input() activeTab!: string | null;
  @Output() selectTab: EventEmitter<string> = new EventEmitter();
}
