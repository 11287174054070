import { LastObservations } from './last-observations';

export interface CrossPlot {
  title?: string;
  crossplotMetadata: CrossPlotMetadata;
  train?: string;
  equipmentName: string;
  baseline: Baseline;
  lastObservations: {
    latestOperatingPoint: LastObservations;
    overConsumption: LastObservations;
    underConsumption: LastObservations;
    normalConsumption: LastObservations;
  };
  additionalBaselines: AdditionalBaseline[]
}

export interface Baseline {
  xobserved: number[];
  yobserved: number[];
  yupper: number[];
  ymean: number[];
  ylower: number[];
  dates: string[];
  xmin: number;
  xmax: number;
  ymin: number;
  ymax: number;
  slope: number;
  intercept: number;
}

export interface AdditionalBaseline {
  train: string;
  equipmentName: string;
  xobserved: number[];
  ymean: number[];
  xmin: number;
  xmax: number;
}

export interface CrossPlotMetadata {
  xAxisProperties: AxisProperties;
  yAxisProperties: AxisProperties;
}

export interface AxisProperties {
  label: string;
  unit: string;
}
