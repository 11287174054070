<div id="dummyDiv" #dummyDiv></div>
<co2-header-nav></co2-header-nav>

<main>
  <app-toaster></app-toaster>
  <router-outlet></router-outlet>
  <button id="scrollToTopButton" (click)="scrollToTop()">
    <img [src]="scrollToTopButtonImage" alt="Scroll to top" height="80" width="80">
  </button>
</main>
