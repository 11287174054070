<co2-tab-selector [tabsList]="subTabs" [activeTab]="activePumpTab$ | async"
  (selectTab)="onSelectTab($event)">
</co2-tab-selector>

<div *ngIf="(activePumpTab$ | async) === OVERVIEW">
  <co2-overview-kpis *ngIf="globalKPIValues"
  [equipmentOverview] = "kpiTitle"
  [globalheaders] ="globalHeaders"
  [globalRows] ="globalKPIValues"
  [globalDisplayedHeaders] ="globalDisplayedHeaders"
  [datePumps]="lastDate"
  equipment="Pump"
  [detailsHeaders]="detailsHeaders"
  [detailsRows]="detailsKPIValues"
  [detailsDisplayedHeaders]="detailsDisplayedHeaders">
 </co2-overview-kpis>

  <co2-cross-plot *ngIf="showOverviewCrossplot()"
                  [crossPlotGraphInput]="crossPlotGraph"
                  [crossPlotTitle]="crossPlotTitle"
                  [isLoading]="pumpCrossPlotLoader$ | async"
                  [hasLoadingError]="pumpCrossPlotError$ | async"
                  (reloadData)="sb.loadPumpCrossPlot(pumpType, allEquipmentNames)"
                  (chartClick)="onChartClick($event)"
                  [displayDatePicker]="false"
                  [startDatePicker]="this.datePickerProperties.startDatePicker"
                  [endDatePicker]="this.datePickerProperties.endDatePicker"
                  (datePick)="filterDatePick($event)">
  </co2-cross-plot>
  <co2-performance-drift [performanceDriftGraphInputs]="performanceDriftGraph"
                         [isLoading]="pumpCombinedPerformanceDriftLoader$ | async"
                         [hasLoadingError]="pumpCombinedPerformanceDriftError$ | async" (reloadData)="sb.loadPumpPerformanceDrift(pumpType, getStartDateSince3Years(), allEquipmentNames);
                         sb.loadPumpHourlyPerformanceDrift(pumpType, allEquipmentNames)" [displayDatePicker]="true"
                         [startDatePicker]="this.datePickerProperties.startDatePicker"
                         [endDatePicker]="this.datePickerProperties.endDatePicker" (datePick)="filterDatePickDrift($event)">
  </co2-performance-drift>
</div>

<div *ngIf="(activePumpTab$ | async) !== OVERVIEW">
  <div *ngIf="shouldShowCrossPlotComponent">
    <co2-cross-plot *ngIf="crossPlotGraph" [crossPlotGraphInput]="crossPlotGraph"
                    [isLoading]="pumpCrossPlotLoader$ | async" [hasLoadingError]="pumpCrossPlotError$ | async"
                    (reloadData)="sb.loadPumpCrossPlot(pumpType, allEquipmentNames)" (chartClick)="onChartClick($event)" [displayDatePicker]="true"
                    [startDatePicker]="this.datePickerProperties.startDatePicker" [showLegendInfos]="true"
                    [equipmentName]="this.sb.getPumpEquipmentName()" (showAllData)="showAllDataForCrossplot($event)"
                    [endDatePicker]="this.datePickerProperties.endDatePicker" (datePick)="filterDatePick($event)">
    </co2-cross-plot>
  </div>
  <co2-performance-drift [performanceDriftGraphInputs]="performanceDriftGraph"
                         [isLoading]="pumpCombinedPerformanceDriftLoader$ | async"
                         [hasLoadingError]="pumpCombinedPerformanceDriftError$ | async" (reloadData)="sb.loadPumpPerformanceDrift(pumpType, getStartDateSince3Years(), allEquipmentNames);
                         sb.loadPumpHourlyPerformanceDrift(pumpType, allEquipmentNames)" [displayDatePicker]="true"
                         [startDatePicker]="this.datePickerProperties.startDatePicker"
                         [endDatePicker]="this.datePickerProperties.endDatePicker" (datePick)="filterDatePickDrift($event)">
  </co2-performance-drift>
</div>
