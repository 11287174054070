import { Component, OnInit } from '@angular/core';
import { environment } from '@environments/environment';
import { AuthService } from '../../services';
import {Router} from "@angular/router";

@Component({
  selector: 'co2-unauthorized',
  templateUrl: './unauthorized.container.html'
})
export class UnauthorizedContainer implements OnInit {
  error!: string;
  debug: boolean = environment.debug.msal;

  constructor(private router: Router, private authService: AuthService) {}

  ngOnInit(): void {
    if (this.authService.error) this.error = this.authService.error.message;
  }

  redirectToLogin(): void {
    this.router.navigate(['login']);
  }
}
