import { Component, Input, Output, EventEmitter } from '@angular/core';
import { EChartsOption } from 'echarts';
import { DatePick } from '../../types';

@Component({
  selector: 'co2-performance-drift',
  templateUrl: './performance-drift.component.html',
  styleUrls: ['./performance-drift.component.scss']
})
export class PerformanceDriftComponent {
  @Input() performanceDriftGraphInputs!: EChartsOption[];
  @Input() isLoading!: boolean | null;
  @Input() hasLoadingError!: boolean | null;
  @Output() reloadData = new EventEmitter();
  @Input() displayDatePicker?: boolean;
  @Input() startDatePicker!: Date | string;
  @Input() endDatePicker!: Date | string;
  @Input() actualDate!: Date;
  @Input() noAvailableData?: boolean | null;
  @Output() datePick = new EventEmitter<DatePick>();
  @Output() quickSelectionChange = new EventEmitter<any>();

  noAvailableDataList: boolean[] = [];


  ngOnInit() {
    this.noAvailableDataList = this.performanceDriftGraphInputs.map(graph => this.isNoDataAvailable(graph));
  }

  isNoDataAvailable(graph: any): boolean {
    return !graph.series || graph.series.length === 0 || graph.series.flatMap((s: {data: any}) => s.data).length === 0;
  }
}
