import { Component, Input } from '@angular/core';
import { IndicatorInfos } from '../../types';

@Component({
  selector: 'co2-indicator-card',
  templateUrl: './indicator-card.component.html',
  styleUrls: ['./indicator-card.component.scss']
})
export class IndicatorCardComponent {
  @Input() indicator!: IndicatorInfos;
  @Input() isInDevelopment = false;
  protected readonly parseFloat = parseFloat;
}
