<div *ngIf="sites.length" class="login-background">
  <div class="card">
    <div class="header">
      <img src="./../../../../assets/overview-icon.png" alt="Fpso icon">
      <div class="title">Choose your Asset</div>
    </div>
    <div class="content">
      <div class="assets">
        <div class="form-check" *ngFor="let fpso of listFpsos(); index as i">
          <input class="form-check-input me-1" type="radio" name="fpso" [(ngModel)]="selectedFpso" [value]="fpso" [id]="'nested-radio' + i">
          <label class="form-check-label" [for]="'nested-radio' + i">
            {{ fpso }}
          </label>
        </div>
      </div>
    </div>
      <button type="button" class="btn btn-primary" [disabled]="!selectedFpso" (click)="chooseFpso()">
        <span class="material-icons">check</span>Validate
      </button>
  </div>
</div>
