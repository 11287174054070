import { Action, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { GetWaterInjectionHourlyPerformanceDrift } from './actions';
import { ValueSeries } from '../../app/types';

@State<ValueSeries[]>({
  name: 'waterInjectionHourlyPerformanceDrift',
})
@Injectable()
export class WaterInjectionHourlyPerformanceDriftState {
  @Action(GetWaterInjectionHourlyPerformanceDrift)
  getWaterInjectionHourlyPerformanceDrift(context: StateContext<ValueSeries[] | undefined>, { payload }: GetWaterInjectionHourlyPerformanceDrift): void {
    context.setState(payload);
  }
}
