import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpParams,
} from '@angular/common/http';
import { AppSandbox } from '../../app.sandbox';
import { environment } from '@environments/environment';
import { Affiliate, CompressorIndicator } from '../../types';
import { AppInsightsService, PendingRequestsService } from '../../services';
import { RequestBehaviorSubjects } from "./loader.interface";
import { Observable } from "rxjs";

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  fpso: string;
  affiliate: Affiliate

  constructor(private sb: AppSandbox,
    private appInsightService: AppInsightsService,
    private pendingRequestsService: PendingRequestsService
  ) {
    this.fpso = this.sb.getActiveFpso();
    this.affiliate = this.sb.getActiveAffiliate();
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    this.toggleLoaderOrError(request.url, request.params, true);

    return new Observable(observer => {
      const subscription = next.handle(request).subscribe({
        next: event => {
          if (event instanceof HttpResponse) {
            this.toggleLoaderOrError(request.url, request.params, false, false);
            observer.next(event);
          }
        },

        error: error => {
          this.toggleLoaderOrError(request.url, request.params, false, true);
          this.appInsightService.logException(error);
          observer.error(error);
        },

        complete: () => {
          this.toggleLoaderOrError(request.url, request.params, false);
          observer.complete();
        }
      });

      this.pendingRequestsService.handleNewRequest(request, subscription);

      // Case when request is cancelled
      return () => {
        this.toggleLoaderOrError(request.url, request.params, false);
        subscription.unsubscribe();
      };
    });
  }

  private toggleLoaderOrError(requestUrl: string, requestParams: HttpParams, showLoader: boolean, showError?: boolean): void {
    let dataLoadersList : RequestBehaviorSubjects[] = [
    { requestUrl:`${environment.apiUrl}/${this.affiliate}/${this.fpso}/general-dashboard/overview/total-co2`, responseBehaviorSubjects: { loader: this.sb.totalCo2YearlyEmissionLoader$, error: this.sb.totalCo2YearlyEmissionError$ }},
    { requestUrl: `${environment.apiUrl}/${this.affiliate}/${this.fpso}/compressors/influential-factors`, responseBehaviorSubjects: { loader: this.sb.compressorsAsvDataLoader$, error: this.sb.compressorsAsvDataError$ }},
    { requestUrl: `${environment.apiUrl}/compressors/overview-kpis`, responseBehaviorSubjects: { loader: this.sb.compressorsOverviewKpisLoader$, error: this.sb.compressorsOverviewKpisError$ }},
    { requestUrl: `${environment.apiUrl}/${this.affiliate}/${this.fpso}/compressors/cross-plots`, responseBehaviorSubjects: { loader: this.sb.compressorCrossPlotLoader$, error: this.sb.compressorCrossPlotError$ }},
    { requestUrl: `${environment.apiUrl}/${this.affiliate}/${this.fpso}/turbogenerators/power-reserve`, responseBehaviorSubjects: { loader: this.sb.turbogenPowerReserveLoader$, error: this.sb.turbogenPowerReserveError$ }},
    { requestUrl: `${environment.apiUrl}/${this.affiliate}/${this.fpso}/turbogenerators/co2-factor-data`, responseBehaviorSubjects: { loader: this.sb.turbogenCo2FactorDataLoader$, error: this.sb.turbogenCo2FactorDataError$ }},
    { requestUrl: `${environment.apiUrl}/${this.affiliate}/${this.fpso}/cross-plots`, responseBehaviorSubjects: { loader: this.sb.turbogenCrossPlotLoader$, error: this.sb.turbogenCrossPlotError$ }},
    { requestUrl: `${environment.apiUrl}/${this.affiliate}/${this.fpso}/turbogenerators/power-running-turbines`, responseBehaviorSubjects: { loader: this.sb.turbogenPowerTurbinesLoader$, error: this.sb.turbogenPowerTurbinesError$ }},
    { requestUrl: `${environment.apiUrl}/${this.affiliate}/${this.fpso}/turbogenerators/performance-drift`, responseBehaviorSubjects:
        { loader: this.sb.turbogenPerformanceDriftLoader$, error: this.sb.turbogenPerformanceDriftError$ }},
    { requestUrl: `${environment.apiUrl}/${this.affiliate}/${this.fpso}/turbogenerators/hourly-performance-drift`, responseBehaviorSubjects:
        { loader: this.sb.turbogenHourlyPerformanceDriftLoader$, error: this.sb.turbogenHourlyPerformanceDriftError$ }},
    { requestUrl: `${environment.apiUrl}/${this.affiliate}/${this.fpso}/pumps/cross-plots`, responseBehaviorSubjects:
        { loader: this.sb.turbogenPerformanceDriftLoader$, error: this.sb.turbogenPerformanceDriftError$ }},
    { requestUrl: `${environment.apiUrl}/${this.affiliate}/${this.fpso}/performance-drift/hourly`,
        responseBehaviorSubjects: { loader: this.sb.pumpsPerformanceDriftLoader$, error: this.sb.pumpsPerformanceDriftError$ }},
    { requestUrl: `${environment.apiUrl}/${this.affiliate}/${this.fpso}/performance-drift/hourly`,
        responseBehaviorSubjects: { loader: this.sb.pumpsHourlyPerformanceDriftLoader$, error: this.sb.pumpsHourlyPerformanceDriftError$ }},
    { requestUrl: `${environment.apiUrl}/${this.affiliate}/${this.fpso}/alerting/latest`,
        responseBehaviorSubjects: { loader: this.sb.alertTableLoader$, error: this.sb.alertTableError$ }}];

    let loader, error;

    // Handle the case Asv/polytropic data that share the same endpoint with different requestParams
    if (requestUrl === `${environment.apiUrl}/${this.affiliate}/${this.fpso}/compressors/influential-factors` &&
      requestParams.get('compressor_indicator_type') === CompressorIndicator.POLYTROPIC_EFFICIENCY) {
      loader = this.sb.compressorsPolytropicDataLoader$;
      error = this.sb.compressorsPolytropicDataError$;
    } else if (requestUrl === `${environment.apiUrl}/${this.affiliate}/${this.fpso}/compressors/influential-factors` &&
      requestParams.get('compressor_indicator_type') === CompressorIndicator.POWER) {
      loader = this.sb.compressorsPowerDataLoader$;
      error = this.sb.compressorsPowerDataError$;
    }
    else {
      loader = this.getValueByKey(requestUrl, dataLoadersList)?.loader;
      error = this.getValueByKey(requestUrl, dataLoadersList)?.error;
    }

    if (loader) loader.next(showLoader);
    if (error && showError !== undefined) error.next(showError)
  }

  private getValueByKey(requestUrl: string, list: RequestBehaviorSubjects[]): any {
    const filteredElements =
      list.filter(entry => requestUrl.includes(entry.requestUrl));
    if (filteredElements && filteredElements.length > 0)
      return filteredElements[0].responseBehaviorSubjects;
    return {};
  }
}
