<div class="sankey_content">
  <co2-ui-graph [options]="options" style="height: 250px"></co2-ui-graph>
  <div class="sankey__form">
    <div class="sankey_content__switch form-switch-labels form-check form-switch">
      <label class="form-check-label" [ngClass]="kwSelected ? 'sankey_content__switch__not_selected' : 'sankey_content__switch__selected'" for="switchEnergyFuelGas">CO2 Emissions ktCO2/year</label>
      <input class="form-check-input" type="checkbox" id="switchEnergyFuelGas" (change)="onChange($event)" [checked]="false">
      <label class="form-check-label" [ngClass]="kwSelected ? 'sankey_content__switch__selected' : 'sankey_content__switch__not_selected'"  for="switchEnergyFuelGas">Energy balance kW</label>
    </div>
    <div>
      <button type="button" class="btn btn-tertiary" (click)="handleSankeyExport()">
        <span class="material-icons" aria-hidden="true">
          system_update_alt</span> Download Sankey Diagram
      </button>
    </div>
  </div>
</div>
