import {
  DEFAULT_LINEAR_GRAPH,
  DEFAULT_XAXIS,
  DEFAULT_YAXIS_INPUTS,
  DataType,
  LinearChart,
  TableHeader, DEFAULT_LINEAR_GRAPH_TOOLTIP_CONFIGURATION, ALL_ALERT_STATUS_METADATA
} from '../../types';
export const INIT_INDEX_ALERT: number = 0;
export const PAGE_SIZE_ALERT: number = 5;

export const ALERT_TREND_GRAPH: LinearChart = {
  ...DEFAULT_LINEAR_GRAPH,
  toolbox: {
    show: false
  },
  tooltip: {...DEFAULT_LINEAR_GRAPH_TOOLTIP_CONFIGURATION},
  grid: {
    top: '20px',
    bottom: '0px',
    left: '25px',
    containLabel: true
  },
  yAxis: {
    ...DEFAULT_YAXIS_INPUTS,
    splitLine: {
      show: true
    },
    name: 'ktCO2/y',
    nameTextStyle: {
      color: '#8A8A8A',
      fontSize: 8
    },
    axisLabel: {
      color: '#666666',
      fontSize: 8,
      margin: 10,
    },
    nameGap: 7,
    splitNumber: 4,
    scale: true,
    axisTick: {
      show: true,
      length: 8,
      lineStyle: {
        color: '#CCCCCC'
      },
    },
  },
  xAxis: {
    ...DEFAULT_XAXIS,
    axisLabel: {
      color: '#666666',
      fontSize: 8,
      margin: 10,
      hideOverlap: true,
      formatter: '{dd}/{MM}/{yyyy}'
    },
    axisLine: {
      lineStyle: {
        color: '#CCCCCC'
      }
    },
    axisTick: {
      length: 8,
      lineStyle: {
        color: '#CCCCCC'
      },
    },
    splitLine: {
      show: true
    },
    splitNumber: 4,
    scale: true
  },
};

export const defaultColor: { color: string, badgeBackgroundColor: string } = { color: '#625d5d', badgeBackgroundColor: '#625D5D3A' };

export enum SystemType {
  Compression = 'Compression',
  TurboGenerator = 'Power generation'
}

export const systemColorMap = new Map<string, { color: string, badgeBackgroundColor: string }>([
  [SystemType.Compression, { color: '#00838F', badgeBackgroundColor: '#00838F2B' }],
  [SystemType.TurboGenerator, { color: '#285AFF', badgeBackgroundColor: '#285AFF2B' }]
]);

export const ALERT_HEADERS: TableHeader[] = [
  {
    id: 'nameWithType',
    label: 'Equipment',
    tooltip: "Status",
    dataType: DataType.HTML_TEXT,
    metadata: ALL_ALERT_STATUS_METADATA

  },
  {
    id: 'trendGraph',
    label: 'Trend',
    dataType: DataType.GRAPH
  },
  {
    id: 'hourlyTrendGraph',
    label: 'Hourly Trend',
    dataType: DataType.GRAPH
  },
  {
    id: 'avoidableEmissions',
    label: 'Avoidable emissions',
    dataType: DataType.BADGE,
    tooltip: '- First value corresponds to the value of “Avoidable emissions” of Day-1 (yesterday).<br/>' +
             '- Second value corresponds to the year to date accumulated avoidable emissions.',
    suffixText: 'ktCO2/yr',
    subHeaderId: 'ytdAvoidableEmissions',
    defaultValue: 'No data',
  },
  {
    id: 'observation',
    label: 'Description',
    dataType: DataType.TEXT,
  }
];

