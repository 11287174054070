<div class="row">
  <div class="col-12">
    <div class="card">

      <div *ngIf="title" class="card-title">
        Upload file for {{ title }}
      </div>
      <div class="download-bloc">
        <h5>On going config files: </h5>
        <div *ngFor="let fpso of fpsos">
          <a href="javascript:void(0)"(click)="download(fpso)">{{fpso}}</a>
        </div>
      </div>

      <div class="card-body">
        <label class="form-label">
          <span class="text-danger">*</span> Upload new supporting files
        </label>
        <form class="upload-file">
          <input
            (change)="selectFile($event)"
            [id]="'upload-input-' + componentId"
            name="fileUpload"
            type="file"
            accept=".json"
          />
          <label [for]="'upload-input-' + componentId" class="upload-label-bg">
            <span class="material-icons-outlined me-1">insert_drive_file</span>
            <div>
              <span class="d-block lead mb-2">Click here to upload the file <br> or drag’n drop it here.</span>
              <span>Handled formats: .json</span>
            </div>
          </label>
        </form>
        <div *ngIf="file" >
          <h6 class="mt-4 mb-1">File name</h6>
          <ul class="list-unstyled">
            <li class="d-flex justify-content-between align-items-center">
              <span class="d-flex align-content-center">
                <span class="material-icons me-2 text-primary" aria-hidden="true">cloud_upload</span> {{ fileName }}
              </span>
            </li>
          </ul>
        </div>
        <div *ngIf="uploadSuccessful">
          <span class="material-icons text-success ms-3">check</span> File uploaded successfully
        </div>
        <div *ngIf="!uploadSuccessful && message">
          <span class="text-danger d-flex align-content-center">
            <span class="material-icons ms-3 me-1">error_outline</span>
            {{ message }}
          </span>
        </div>
        <div class="d-flex justify-content-end pt-3">
          <button type="button" [id]="'cancel-button-' + componentId" class="btn btn-primary ms-1" [disabled]="!file" (click)="resetFile()">
            <span class="material-icons" aria-hidden="true">cancel</span> Cancel
          </button>
          <button type="button" [id]="'upload-button-' + componentId" class="btn btn-secondary ms-2" [disabled]="!file" (click)="upload()">
            <span class="material-icons" aria-hidden="true">upload</span> Upload
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
