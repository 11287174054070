import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { UiGraphModule } from 'src/modules/app/components/ui-graph/ui-graph.module';
import { OverviewGhgEmissionsComponent } from './overview-ghg-emissions.component';
import { IndicatorCardComponent } from 'src/modules/app/components';
import { SankeyComponent } from '../../../sankey/sankey.component';

@NgModule({
  declarations: [OverviewGhgEmissionsComponent, IndicatorCardComponent,SankeyComponent],
  imports: [
    CommonModule,
    UiGraphModule,
    RouterModule
  ],
  exports: [OverviewGhgEmissionsComponent,IndicatorCardComponent, SankeyComponent]
})
export class OverviewGhgEmissionsModule { }
