import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin, map, Observable, of, switchMap } from 'rxjs';
import { environment } from '@environments/environment';
import {
  AlertRules,
  ALL_SYSTEM_TYPES_GROUPS,
  DatedValue,
  Observations,
  PointCouple,
  toObservations,
  toPointCouple
} from '../../types';
import { PAGE_SIZE_ALERT } from '../../containers/general-dashboard/general-dashboard.container.const';
import { getUserTimezone } from '../../utils';
import { catchError } from "rxjs/operators";
import { FrontConfigsService } from "../front-configs/front-configs.service";
import { FrontConfig } from "../../types/front-config.interface";

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor(private http: HttpClient, private frontConfigsService: FrontConfigsService) {
  }

  getAlerts(affiliate: string, fpsoName: string, alertTypes: string[], index: number): Observable<Observations> {
    const params = new HttpParams().set('index', index)
      .append('size', PAGE_SIZE_ALERT)
      .append('alert_types', alertTypes.join(','));
    let frontConfig : FrontConfig;

    return this.frontConfigsService.getFrontConfig(affiliate, fpsoName).pipe(
      switchMap(config => {
        frontConfig = config;
        return this.http.get<Observations>(
          `${ environment.apiUrl }/${ affiliate }/${ fpsoName }/alerting/latest`,
          { params: params }
        );
      }),
      switchMap(observation => {
        const requests = observation.alerts.map(alert => {
          return forkJoin([
            this.getAlertTrendHourly(affiliate, fpsoName, alert.systemType, alert.equipmentName, alert.alertType, alert.equipmentType),
            this.getAlertTrendDaily(affiliate, fpsoName, alert.systemType, alert.equipmentName, alert.alertType, alert.equipmentType)
          ]).pipe(
            map(([ hourlyData, trendData ]) => {
              alert.hourlyTrendData = hourlyData;
              alert.trendData = trendData;
              alert.systemType = this.getLabelFromFrontConfig(frontConfig, alert.systemType) ?? this.getDefaultSystemType(alert.systemType);
              return alert;
            })
          );
        });

        return forkJoin(requests).pipe(
          map(alerts => {
            observation.alerts = alerts;
            return observation;
          })
        );
      }),
      map(toObservations)
    );
  }

  private getLabelFromFrontConfig(frontConfig: FrontConfig, systemType: string) {
    return frontConfig?.equipments?.filter(eq => eq.systemType === systemType)[0]?.label;
  }

  getDefaultSystemType(systemType: string): string {
    return ALL_SYSTEM_TYPES_GROUPS[systemType] ?? 'N/A';
  }

  getAlertTrendHourly(affiliate: string,
                      fpsoName: string,
                      systemType: string,
                      equipmentName: string,
                      alertType: AlertRules,
                      equipmentType: string): Observable<PointCouple[]> {
    const params = new HttpParams()
      .set('alert_type', alertType)
      .append('system_type', systemType)
      .append('equipment_type', equipmentType)
      .append('equipment_name', equipmentName)
      .append('timezone', getUserTimezone())

    return this.http.get<DatedValue[]>(
      `${ environment.apiUrl }/${ affiliate }/${ fpsoName }/alerting/hourly-trend`,
      { params: params }
    ).pipe(map((datedValues: DatedValue[]) => datedValues.map(toPointCouple)),
      catchError((err, caught) => {
        return of([]);
      }));
  }

  getAlertTrendDaily(affiliate: string,
                     fpsoName: string,
                     systemType: string,
                     equipmentName: string,
                     alertType: AlertRules,
                     equipmentType: string): Observable<PointCouple[]> {
    const params = new HttpParams()
      .set('alert_type', alertType)
      .append('system_type', systemType)
      .append('equipment_type', equipmentType)
      .append('equipment_name', equipmentName);

    return this.http.get<DatedValue[]>(
      `${ environment.apiUrl }/${ affiliate }/${ fpsoName }/alerting/daily-trend`,
      { params: params }
    ).pipe(map((datedValues: DatedValue[]) => datedValues.map(toPointCouple)),
      catchError((err, caught) => {
        return of([]);
      }));
  }
}
