import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, skip, Subscription } from 'rxjs';
import { AppSandbox } from '../../app.sandbox';
import {
  CrossPlot,
  CrossPlotMetadata,
  DataType,
  DatePick,
  DatePicker,
  DEFAULT_SYSTEM_GRAPH_NAME,
  getGraphMetaDataFrom,
  LAST_3_DAYS_GRAPH_TITLE,
  LAST_3_YEARS_GRAPH_TITLE,
  TabItem,
  TableHeader,
  ValueSeries,
} from '../../types';
import { ActivatedRoute, Router } from '@angular/router';
import { AppInsightsService, PendingRequestsService } from '../../services';
import { DETAILS_PUMP_KPI_HEADERS, GLOBAL_PUMP_KPI_HEADERS } from "./pump.const";
import {
  ALERT_DATE_FORMAT,
  DateTimePickerProperties,
  formatDateFromTo, FRENCH_DATE_FORMAT,
  getDatePickerProperties
} from "../../utils/date-time-picker/date-time-picker";
import { CommonGraphsService } from "../../services/common-graphs/common-graphs.service";
import { getStartDateSince3Years } from "../../utils/date-time/date-time";
import { EChartsOption } from "echarts";
import { KpiOverview, KpiOverviewByEquipment } from "../../types/kpi-overview.interface";
import { EquipmentResponse } from "../../types/front-config.interface";
import { adjustMinMaxYAxisGraphsForPerformanceDrift } from "../../utils/graph/graph";

export const SYSTEM_TYPE_PARAM_KEY = 'pump_type';
export const SELECTED_EQUIPMENT_NAME_PARAM_KEY = 'equipment_name';

@Component({
  selector: 'co2-pumps',
  templateUrl: './pump.container.html',
})
export class PumpContainer implements OnInit, OnDestroy {
  readonly OVERVIEW = 'overview';

  pumpType!: string;
  pumpSelectedTab!: string;
  subTabs!: TabItem[];
  allEquipmentNames!: string[];

  selectedEquipment!: EquipmentResponse;

  subscriptions: Subscription[] = [];
  getSelectedEquipmentSubscribe!: Subscription;
  getSubTabsSubscribe!: Subscription;
  activatedRouteSubscription!: Subscription;

  activePumpTab$: Observable<string> = this.sb.activePumpType$;

  crossPlotTitle!: string;
  pumpCrossPlotData$: Observable<CrossPlot[]> = this.sb.pumpCrossPlot$;
  pumpCrossPlotLoader$: Observable<boolean> = this.sb.pumpsCrossPlotLoader$;
  pumpCrossPlotError$: Observable<boolean> = this.sb.pumpsCrossPlotError$;
  crossPlotGraph!: any;
  crossPlotBaselineParams: any[] = [];

  shouldShowCrossPlotComponent: boolean = true;

  datePickerProperties!: DateTimePickerProperties;
  lastDatePick: DatePicker = {};
  actualDate = new Date();
  fullOperatingPoints: any[] = [];
  filteredOperatingPoints: any[] = [];

  performanceDriftData$: Observable<ValueSeries[]> = this.sb.pumpPerformanceDrift$.pipe(skip(1));
  performanceDriftGraph: EChartsOption[] = [];

  hourlyPerformanceDriftData$: Observable<ValueSeries[]> = this.sb.pumpHourlyPerformanceDrift$.pipe(skip(1));

  pumpCombinedPerformanceDriftLoader$: Observable<boolean> = this.sb.combinedPumpsPerformanceDriftLoader$;
  pumpCombinedPerformanceDriftError$: Observable<boolean> = this.sb.combinedPumpsPerformanceDriftError$;

  kpiTitle!: string | null;

  pumpOverviewKpis$: Observable<KpiOverview> = this.sb.pumpOverviewKpis$;
  globalHeaders!: TableHeader[];

  globalDisplayedHeaders!: string[];
  detailsHeaders: TableHeader[] = [ ...DETAILS_PUMP_KPI_HEADERS ];
  detailsDisplayedHeaders: string[] = this.detailsHeaders.map((header) => header.id);
  globalKPIValues!: KpiOverview | undefined;
  detailsKPIValues!: Array<KpiOverviewByEquipment> | undefined;

  lastDate: string | undefined;
  protected readonly getStartDateSince3Years = getStartDateSince3Years;
  crossPlotMetadata !: CrossPlotMetadata;
  fullDriftPoints: any;

  constructor(
    public sb: AppSandbox,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private pendingRequestsService: PendingRequestsService,
    private appInsightsService: AppInsightsService,
    private graphsService: CommonGraphsService
  ) {
  }

  ngOnInit(): void {
    this.datePickerProperties = getDatePickerProperties(this.actualDate);
    this.activatedRouteSubscription = this.activatedRoute.params.subscribe(params => {
      this.cleanGraphs();
      this.pumpType = params[SYSTEM_TYPE_PARAM_KEY];
      this.pumpSelectedTab = params[SELECTED_EQUIPMENT_NAME_PARAM_KEY];
      this.sb.setActiveEquipmentNameTab(this.pumpSelectedTab);

      this.getSelectedEquipmentSubscribe = this.sb.getSelectedEquipment(this.pumpType).subscribe(equipment => this.onSelectEquipment(equipment));
      this.getSubTabsSubscribe = this.sb.getSubTabs(this.pumpType).subscribe(tabs => this.onGetTabs(tabs));
    })
  }


  private onGetTabs(tabs: TabItem[]) {
    this.subTabs = [ {
      id: this.OVERVIEW,
      label: this.selectedEquipment.label
    }, ...tabs ];
    this.allEquipmentNames = this.subTabs.filter(i => i.id !== this.OVERVIEW).map(i => i.id);

    this.appInsightsService.logPageView(this.pumpType);
    this.pendingRequestsService.cancelAllPendingRequests();
    this.loadPageData(this.pumpSelectedTab);
  }

  private onSelectEquipment(equipment: EquipmentResponse) {
    this.selectedEquipment = equipment;
    this.crossPlotTitle = `${ equipment.label } Equipments Baselines`;
    this.kpiTitle = `${ equipment.label } Overview`;
    this.globalHeaders = [ {
      id: 'runningEquipmentsCount',
      label: `Running ${ equipment.labelAbbreviation }`,
      dataType: DataType.TEXT,
    }, ...GLOBAL_PUMP_KPI_HEADERS ];
    this.globalDisplayedHeaders = this.globalHeaders.map((header) => header.id);

    // Call all to fill graphs
    this.subscriptions = [
      this.pumpOverviewKpis$.subscribe(data => {
        if (data?.kpiByEquipments) {
          this.globalKPIValues = data;
          this.detailsKPIValues = data.kpiByEquipments;
          this.lastDate = formatDateFromTo(data.kpiByEquipments[0].date, ALERT_DATE_FORMAT, FRENCH_DATE_FORMAT);
        }
      }),
      this.pumpCrossPlotData$.subscribe(
        (crossPlots: CrossPlot[]) => {
          if (crossPlots?.length > 0) {
            this.crossPlotMetadata = crossPlots[0]?.crossplotMetadata;
            let graphData = this.sb.updateCrossPlotGraphs(crossPlots, {
                type: 'startDate',
                value: this.datePickerProperties.startDatePicker
              },
              this.datePickerProperties.startDatePicker, this.datePickerProperties.endDatePicker, this.filteredOperatingPoints, `Baseline ${this.selectedEquipment.label}`)
            if (graphData) {
              this.fullOperatingPoints = graphData.fullOperatingPoints;
              this.crossPlotGraph = graphData.crossPlotGraph;
              this.crossPlotBaselineParams = graphData.crossPlotBaselineParams;
            }
          }
        }
      ),
      this.hourlyPerformanceDriftData$.subscribe(
        (data: ValueSeries[]) => {
          if (data) {
            this.performanceDriftGraph =
              this.graphsService.updateManyEquipmentsPerformanceDriftGraphs(data, LAST_3_DAYS_GRAPH_TITLE, this.performanceDriftGraph, DEFAULT_SYSTEM_GRAPH_NAME, this.selectedEquipment.graphMetadata);
            this.performanceDriftGraph = adjustMinMaxYAxisGraphsForPerformanceDrift(this.performanceDriftGraph);
          }
        }
      ),
      this.performanceDriftData$.subscribe(
        (data: ValueSeries[]) => {
          if (data) {
            this.performanceDriftGraph =
              this.graphsService.updateManyEquipmentsPerformanceDriftGraphs(data, LAST_3_YEARS_GRAPH_TITLE, this.performanceDriftGraph, DEFAULT_SYSTEM_GRAPH_NAME, this.selectedEquipment.graphMetadata);

            // Now TypeScript knows that this.performanceDriftGraph is an array
            // @ts-ignore
            this.fullDriftPoints = this.performanceDriftGraph[0].series.map((s => s.data));
            this.performanceDriftGraph[0] = this.sb.filterOperatingPointsDrift({},
              this.datePickerProperties.startDatePicker, this.datePickerProperties.endDatePicker, this.fullDriftPoints, this.performanceDriftGraph[0]);
            this.performanceDriftGraph = adjustMinMaxYAxisGraphsForPerformanceDrift(this.performanceDriftGraph);
          }
        }
      )
    ];
  }

  private loadPageData(activeTab: string): void {
    this.sb.loadPumpCrossPlot(this.pumpType, this.allEquipmentNames);
    this.sb.loadPumpPerformanceDrift(this.pumpType, getStartDateSince3Years(), this.allEquipmentNames);
    this.sb.loadPumpHourlyPerformanceDrift(this.pumpType, this.allEquipmentNames);
    if (activeTab === this.OVERVIEW) {
      this.sb.loadPumpsOverviewKpis(this.pumpType, this.allEquipmentNames);
    }
  }


  onSelectTab(newEquipmentType: string): void {
    this.shouldShowCrossPlotComponent = false;
    this.cleanGraphs();
    // Use a timeout to allow Angular to perform the change detection and destroy the component
    setTimeout(() => {
      this.shouldShowCrossPlotComponent = true;
      const fpso = this.sb.getActiveFpso();
      const affiliate = this.sb.getActiveAffiliate();
      this.router.navigate([ `/${ affiliate }/${ fpso }/pumps/${ this.pumpType }/${ newEquipmentType }` ]);
    });
  }

  private cleanGraphs() {
    this.globalKPIValues = undefined;
    this.detailsKPIValues = undefined;
    this.crossPlotGraph = [];
    this.performanceDriftGraph = [];
    this.crossPlotBaselineParams = [];
    this.fullOperatingPoints = [];

    this.sb.resetAllGraphsStates();
    this.cleanSubscriptions();
  }

  filterDatePick(datePick: DatePick): void {
    this.lastDatePick = datePick;
    this.crossPlotGraph = this.sb.filterOperatingPoints(datePick,
      this.datePickerProperties.startDatePicker, this.datePickerProperties.endDatePicker, this.fullOperatingPoints, this.crossPlotGraph,
      this.filteredOperatingPoints);
  }

  onChartClick(event: any): void {
    this.crossPlotGraph = this.sb.onChartClick(event, this.crossPlotBaselineParams, this.crossPlotGraph, this.crossPlotMetadata) ||
      this.crossPlotGraph;
  }

  filterDatePickDrift(datePick: DatePick): void {

    this.performanceDriftGraph[0] = this.sb.filterOperatingPointsDrift(datePick,
      this.datePickerProperties.startDatePicker, this.datePickerProperties.endDatePicker, this.fullDriftPoints, this.performanceDriftGraph[0]);
    this.performanceDriftGraph = adjustMinMaxYAxisGraphsForPerformanceDrift(this.performanceDriftGraph);
  }

  private cleanSubscriptions() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
    if (this.getSelectedEquipmentSubscribe) {
      this.getSelectedEquipmentSubscribe.unsubscribe();
    }
    if (this.getSubTabsSubscribe) {
      this.getSubTabsSubscribe.unsubscribe();
    }
  }

  ngOnDestroy(): void {
    this.activatedRouteSubscription.unsubscribe();
    this.cleanSubscriptions();
  }

  showOverviewCrossplot() {
    return this.crossPlotGraph && this.selectedEquipment && !getGraphMetaDataFrom(this.selectedEquipment.graphMetadata, 'HIDE_OVERVIEW_CROSSPLOT');
  }

  showAllDataForCrossplot(show: boolean) {
    const largePeriod = {startDate: new Date(1999, 1, 1), endDate: new Date() };
    this.crossPlotGraph = this.sb.filterOperatingPoints(show ? largePeriod : this.lastDatePick,
      this.datePickerProperties.startDatePicker, this.datePickerProperties.endDatePicker, this.fullOperatingPoints, this.crossPlotGraph,
      this.filteredOperatingPoints);
  }
}
