import { Action, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { GetActiveTurbineTab } from './actions';

@State<string>({
  name: 'activeTurbineTab',
  defaults: 'overview',
})
@Injectable()
export class ActiveTurbineTabState {
  @Action(GetActiveTurbineTab)
  getActiveTurbineTab(context: StateContext<string>, { payload }: GetActiveTurbineTab): void {
    context.setState(payload);
  }
}
