import { Action, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { ValueSeries } from '../../app/types';
import { GetTurbogenHourlyPerformanceDrift } from './actions';

@State<ValueSeries[]>({
  name: 'turbogenHourlyPerformanceDrift',
})
@Injectable()
export class TurbogenHourlyPerformanceDriftState {
  @Action(GetTurbogenHourlyPerformanceDrift)
  getTurbogenHourlyPerformanceDrift(context: StateContext<ValueSeries[] | undefined>, { payload }: GetTurbogenHourlyPerformanceDrift): void {
    context.setState(payload);
  }
}
