import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { environment } from "@environments/environment";
import { BaselineConfigResponse, ResponseMessage } from "./model/baseline.model";

@Injectable({
  providedIn: 'root'
})
export class BaselineService {

  constructor(public http: HttpClient) {}

  getBaseline(affiliate: string, fpso: string, equipmentName: string): Observable<BaselineConfigResponse[]> {
    let params = new HttpParams().set('affiliate', affiliate).append('fpso', fpso).append("equipment_name", equipmentName);
    return this.http.get<BaselineConfigResponse[]>(`${environment.apiUrl}/baseline-configs`, { params });
  }

  updateBaseline(affiliate: string, fpso: string, equipmentName: string, xMin: number, xMax: number, yMin: number, yMax: number): Observable<ResponseMessage> {
    const body = {
      affiliate: affiliate,
      fpso: fpso,
      equipmentName: equipmentName,
      xMax: xMax,
      xMin: xMin,
      yMax: yMax,
      yMin: yMin
    }
    return this.http.post<ResponseMessage>(`${environment.apiUrl}/baseline-configs/save`, body)
  }

}
