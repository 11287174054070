import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { Observable } from 'rxjs';
import { AppSandbox } from 'src/modules/app/app.sandbox';
import { DiscussionService } from 'src/modules/app/services/discussions/discussion.service';
import { Alert, AlertStatus } from "../../../types";
import { Store } from '@ngxs/store';
import { ActiveAffiliateState, ActiveFpsoState } from 'src/modules/statemanagement';

@Component({
  selector: 'co2-modal',
  templateUrl: './modal.component.html',
  styleUrls: [ './modal.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalComponent implements OnInit {

  @Input() title: string = '';
  @Input() principalContent: string = '';
  @Input() content: string = '';
  @Input() iconContent: string = '';
  @Input() button: string = ''
  @Input() iconTitle: string = '';
  @Input() isOpen: boolean = false;
  @Input() newDiscussion = false;
  @Input() selectedAlert!: Alert;
  @Input() equipmentName: string = '';
  @Input() selectedDiscussion: any;
  @Input() messageData: any = { content: ' ', id: '' };
  @Output() closeModalEvent = new EventEmitter<void>();
  @Output() reloadDiscussions = new EventEmitter<void>();
  @Output() updateStatus = new EventEmitter<AlertStatus>();
  @Output() modalAction: EventEmitter<string> = new EventEmitter();
  user: any;
  userName$: Observable<string> = this.sb.userName$;
  currentUser: string = '';
  newMessageContent: string = '';
  fileToUpload:any;
  fileName: string | null = null;



  constructor(private sb: AppSandbox, private discussionService: DiscussionService, private cdr: ChangeDetectorRef, private store: Store) {
  }

  ngOnInit() {
    this.user = this.sb.loadUserName();
    if (!this.messageData.content) {
      this.messageData.content = ''; // Initialize content property if it's not defined
    }
    this.user = this.userName$.subscribe((data) => {
      this.currentUser = data;
    })
  }

  open() {
    this.isOpen = true;
  }

  makeid(length: number) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  }

  addMessage(currentUser: string) {
    const newMessage = {
      id: this.makeid(6),
      content: this.newMessageContent,
      name: currentUser,
      mail: "test@test.fr",
      updatedAt: new Date()
    };
    this.selectedDiscussion.messages.unshift(newMessage);

    this.discussionService.upsertDiscussion(this.getAffiliate(), this.getFpso(), this.selectedAlert.id, this.selectedAlert.date, this.selectedDiscussion)
      .subscribe({
        next: (discussion) => {

          this.updateStatus.emit(discussion.status);
          if(this.fileToUpload) {
            this.uploadFile(this.selectedAlert.id, discussion.id, newMessage.id, this.fileToUpload)
          }
          else {
            this.reloadDiscussions.emit();
          }
          this.newMessageContent = '';
          this.closeModalEvent.emit();
        },
        error: (error) => {
          console.error('Error occurred while adding message to discussion:', error);
          // Handle the error gracefully, e.g., show a message to the user
          // You can also roll back the message addition or handle it based on your application's requirements
          this.selectedDiscussion.messages.pop(); // Roll back the message addition
        }
      });
  }

  editMessage(newContent: string, isEdit: boolean) {
    const messages = this.selectedDiscussion.messages;
    let messageId = this.messageData.id;
    const messageIndex = messages.findIndex((message: any) => message.id === messageId);
    if (messageIndex !== -1) {
      if (!isEdit) {
        messages.splice(messageIndex, 1);
        this.closeModalEvent.emit();
      } else {
        messages[messageIndex].content = newContent;
        this.closeModalEvent.emit();
      }
      messages.sort((a: any, b: any) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime());
      this.discussionService.upsertDiscussion(this.getAffiliate(), this.getFpso(), this.selectedAlert.id, this.selectedAlert.date, this.selectedDiscussion).subscribe((discussion) => {
        this.updateStatus.emit((discussion.messages && discussion.messages.length > 0) ? discussion.status : AlertStatus.NEW);
        this.updateStatus.emit(discussion.status);
        if(this.fileToUpload) {
          this.uploadFile(this.selectedAlert.id, discussion.id, messageId, this.fileToUpload)
        }
        else {
          this.reloadDiscussions.emit();
        }
        this.newMessageContent = ''; // Assuming you reset the value after adding discussion
        this.cdr.detectChanges();
        this.closeModalEvent.emit();
      });
    } else {
      console.log('Message not found');
    }
  }

  addNewDiscussion(currentUser: string) {
    const newMessage = {
      id: this.makeid(6),
      content: this.newMessageContent,
      name: currentUser,
      mail: "test@test.fr",
      updatedAt: new Date()
    }
    const discussion =
      {
        affiliate: "ANGOLA",
        alertId: this.selectedAlert.id,
        fpso: 'DALIA',
        messages: [ newMessage ],
        status: "NEW"
      }

    this.discussionService.upsertDiscussion(this.getAffiliate(), this.getFpso(), this.selectedAlert.id, this.selectedAlert.date, discussion).subscribe((discussion) => {
      this.updateStatus.emit(discussion.status);
      this.selectedDiscussion = discussion;
      if(this.fileToUpload) {
        this.uploadFile(this.selectedAlert.id, discussion.id, newMessage.id, this.fileToUpload)
      }
      else {
        this.reloadDiscussions.emit();
      }
      this.newMessageContent = '';
      // Assuming you reset the value after adding discussion
      this.cdr.detectChanges();
      this.closeModalEvent.emit();
    })
  }

  getAffiliate() {
    return this.store.selectSnapshot(ActiveAffiliateState);
  }

  getFpso() {
    return this.store.selectSnapshot(ActiveFpsoState);
  }

  onKeyDown(event: KeyboardEvent): void {
    if (event.key === 'Enter' || event.key === ' ') {
      // Prevent the default action (e.g., scrolling for space key)
      event.preventDefault();
      this.deleteFile();
    }
  }

  closeModal(event: MouseEvent|KeyboardEvent) {
    const isEscapeKeyPress = event instanceof KeyboardEvent && event.key === 'Escape';
    if (event.target === event.currentTarget || isEscapeKeyPress) {
      this.newMessageContent = '';
      this.closeModalEvent.emit();
    }
  }

  stopPropagation(event: MouseEvent|KeyboardEvent) {
    event.stopPropagation();
  }

  handleButtonClick(action: string) {
    this.modalAction.emit(action);
  }

  uploadFile( alertId:string, discussionId:string, messageId: string, file: File) {
    this.discussionService.uploadFile(this.getAffiliate(), this.getFpso(), alertId, discussionId, messageId, file).subscribe((mes:any) => {
      this.reloadDiscussions.emit();
      this.fileToUpload = null;
    });
  }

  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      this.fileToUpload = input.files[0];
      this.fileName = this.fileToUpload.name;
    }
  }

  deleteFile() {
    this.fileToUpload = null;
  }

  closeDiscussion() {
    const newStatus = AlertStatus.CLOSED;
    this.discussionService.updateAlertStatus(this.getAffiliate(), this.getFpso(), this.selectedAlert.id, this.selectedAlert.date, newStatus).subscribe((discussion) => {
      this.reloadDiscussions.emit();
      this.cdr.detectChanges();
      this.closeModalEvent.emit();
      this.updateStatus.emit(discussion.alertStatus);
    });
  }
}
