import { Directive, Input, OnChanges, ElementRef, Renderer2 } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[co2StartsWithRouterLink]'
})
export class StartsWithRouterLinkDirective implements OnChanges {
  @Input('co2StartsWithRouterLink') link: string | undefined;
  private subscription: Subscription;

  constructor(private el: ElementRef, private renderer: Renderer2, private router: Router) {
    // Subscribe to router events to detect navigation changes
    this.subscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.checkIsActive();
      }
    });
  }

  ngOnChanges() {
    this.checkIsActive();
  }

  private checkIsActive() {
    const currentUrl = this.router.url;
    const linkPrefix = this.extractPathPrefix(this.link);
    const currentUrlPrefix = this.extractPathPrefix(currentUrl);

    if ((currentUrlPrefix === 'pumps' && this.isPumpValidation(currentUrl)) || (currentUrlPrefix !== 'pumps' && linkPrefix && currentUrlPrefix && linkPrefix === currentUrlPrefix)) {
      this.renderer.addClass(this.el.nativeElement, 'active');
    } else {
      this.renderer.removeClass(this.el.nativeElement, 'active');
    }
  }

  private isPumpValidation(currentUrl: string) {
    const pumpsLink = this.link ? this.link.split('/').indexOf('pumps') > -1 : false;
    return pumpsLink && currentUrl.indexOf('/pumps') > -1;
  }

  private extractPathPrefix(path: string | undefined): string | undefined {
    // Use a regular expression to match the part before the first '/' and ignore query parameters
    const match = path?.match(/^\/([^/?#]+\/[^/?#]+\/[^/?#]+)/);
    return match ? match[1].split('/')[2] : undefined;
  }
}
