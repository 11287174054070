import { Action, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { CrossPlot } from '../../app/types';
import { GetWaterInjectionCrossPlot } from "./actions";

@State<CrossPlot[]>({
  name: 'waterInjectionCrossPlot',
})
@Injectable()
export class WaterInjectionCrossPlotState {
  @Action(GetWaterInjectionCrossPlot)
  getWaterInjectionCrossPlot(context: StateContext<CrossPlot[] | undefined>, { payload }: GetWaterInjectionCrossPlot): void {
    context.setState(payload);
  }
}
